import { Stack, StackOwnProps } from "@mui/material";
import React from "react";
import COLORS from "../../constants/colors";

interface Props extends StackOwnProps {
  children: React.ReactNode;
}
const ButtonToggleGroupWrapper = (props: Props) => {
  return (
    <Stack
      {...props}
      bgcolor={{ md: COLORS.BRIGHT_BLUE[20] }}
      borderRadius={{ md: "12px" }}
      p={{ md: "24px" }}
    >
      {props.children}
    </Stack>
  );
};

export default ButtonToggleGroupWrapper;
