import { Button, Grid2, Link, Stack, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../../../../../constants/colors";
import QualityTagOverlay from "./QualityTagOverlay";
import {
  formatCurrency,
  formatYearsAndMonths,
} from "../../../../../../lib/helpers";
import {
  IApplicationComboOfferClientView,
  IApplicationOfferClientView,
} from "../../../../../../services/nswag/clients/loanApplicationsClient";

export default function OfferCard({
  offer,
  comboOffer,
  highlighted,
  onCancelAction,
  onAcceptOffer,
  isDualOffer,
}: IProps) {
  if (!offer) return false;

  const onAcceptOfferClick = () => {
    onAcceptOffer();
  };

  const onCancelClick = () => {
    onCancelAction();
  };

  const loanOfferPoints = [
    {
      label: "Lånesum",
      value: `${formatCurrency(offer?.amountOffered)} kr`,
      comboValue:
        comboOffer && `${formatCurrency(comboOffer?.amountOffered)} kr`,
    },
    {
      label: "Nominell rente",
      value: `${formatCurrency(offer?.nominalInterest)}%`,
      comboValue:
        comboOffer && `${formatCurrency(comboOffer?.nominalInterest)}%`,
    },
    {
      label: "Nedbetalingstid",
      value: formatYearsAndMonths(offer?.repaymentPeriodsInMonths),
      comboValue:
        comboOffer &&
        formatYearsAndMonths(comboOffer?.repaymentPeriodsInMonths),
    },
    {
      label: "Å betale i md.",
      value: `${formatCurrency(offer?.costPerPeriod, 0)} kr`,
      comboValue:
        comboOffer && `${formatCurrency(comboOffer?.costPerPeriod, 0)} kr`,
    },
    {
      label: "Effektiv rente",
      value: `${formatCurrency(offer?.effectiveInterest)}%`,
      comboValue:
        comboOffer && `${formatCurrency(comboOffer?.effectiveInterest)}%`,
    },
    {
      label: "Etableringsgebyr",
      value: `${formatCurrency(offer.setupFee)} kr`,
      comboValue: `${formatCurrency(0)} kr`,
    },
  ];

  return (
    <Stack gap="40px">
      <Stack gap="16px">
        <Stack
          border="1px solid #63617C"
          bgcolor="#FFF"
          borderRadius="12px"
          py={{ xs: "24px", md: "48px" }}
          alignItems="center"
          gap={{ xs: "16px", md: "24px" }}
          position="relative"
        >
          <QualityTagOverlay quality={offer.quality} />
          <Stack
            width="100%"
            alignItems="center"
            gap={{ xs: "16px", md: "24px" }}
          >
            <Stack gap={{ xs: "8px", md: "16px" }}>
              <Stack alignItems="center">
                <Stack
                  textAlign="center"
                  alignItems="center"
                  gap="10px"
                  maxWidth={{ xs: "220px", md: "400px" }}
                >
                  {isDualOffer && (
                    <Typography variant="body" color={COLORS.BLACK[100]}>
                      Dette tilbudet inkluderer all din usikrede gjeld i
                      refinansieringen
                    </Typography>
                  )}
                  <Typography variant="body" color={COLORS.BLACK[100]}>
                    Lånesum
                  </Typography>
                </Stack>

                <Typography
                  variant="loanOfferCardAmount"
                  color={COLORS.BLUE[100]}
                >
                  {`${formatCurrency(
                    comboOffer
                      ? comboOffer.amountOffered + offer.amountOffered
                      : offer.amountOffered
                  )} `}
                  kr
                </Typography>
              </Stack>
              <Stack direction="column" alignItems="center">
                <Typography
                  variant="body"
                  color={COLORS.BLACK[100]}
                  fontSize={{ xs: "14px", md: "18px" }}
                >
                  Totale rentekostnader:{" "}
                  {formatCurrency(
                    comboOffer
                      ? comboOffer.totalInterest + offer.totalInterest
                      : offer.totalInterest,
                    0
                  )}{" "}
                  kr
                </Typography>
                <Typography
                  variant="body"
                  color={COLORS.BLACK[100]}
                  fontSize={{ xs: "14px", md: "18px" }}
                >
                  Totalt til betaling:{" "}
                  {formatCurrency(
                    comboOffer
                      ? comboOffer.totalCost + offer.totalCost
                      : offer.totalCost,
                    0
                  )}{" "}
                  kr
                </Typography>
              </Stack>
            </Stack>
            <Stack
              px={{ xs: "20px", md: "20%" }}
              gap={{ xs: "6px", md: "16px" }}
            >
              <Typography
                variant="bodyBold"
                color={COLORS.BLACK[100]}
                fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
              >
                Refinansiering
              </Typography>
              <Stack gap="24px">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="normal"
                  gap="16px"
                >
                  <Grid2 container>
                    {loanOfferPoints.map((point, index) => (
                      <Grid2 size={{ xs: 4, md: 6 }} key={index}>
                        <Stack>
                          <Typography
                            variant="body"
                            color={COLORS.BLACK[100]}
                            fontSize={{
                              xs: "11px",
                              sm: "14px",
                              md: "18px",
                            }}
                          >
                            {point.label}
                          </Typography>
                          <Typography
                            variant="bodyBold"
                            color={COLORS.BLACK[100]}
                            fontSize={{
                              xs: "12px",
                              sm: "14px",
                              md: "18px",
                            }}
                          >
                            {point.value}
                          </Typography>
                        </Stack>
                      </Grid2>
                    ))}
                  </Grid2>
                </Stack>
              </Stack>
            </Stack>
            {comboOffer && (
              <Stack
                px={{ xs: "20px", md: "20%" }}
                gap={{ xs: "6px", md: "16px" }}
              >
                <Typography
                  variant="bodyBold"
                  color={COLORS.BLACK[100]}
                  fontSize={{ xs: "14px", sm: "16px", md: "18px" }}
                >
                  Topplån
                </Typography>
                <Stack gap="24px">
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="normal"
                    gap="16px"
                  >
                    <Grid2 container>
                      {loanOfferPoints.map((point, index) => (
                        <Grid2 size={{ xs: 4, md: 6 }} key={index}>
                          <Stack>
                            <Typography
                              variant="body"
                              color={COLORS.BLACK[100]}
                              fontSize={{
                                xs: "11px",
                                sm: "14px",
                                md: "18px",
                              }}
                            >
                              {point.label}
                            </Typography>
                            <Typography
                              variant="bodyBold"
                              color={COLORS.BLACK[100]}
                              fontSize={{
                                xs: "12px",
                                sm: "14px",
                                md: "18px",
                              }}
                            >
                              {point.comboValue}
                            </Typography>
                          </Stack>
                        </Grid2>
                      ))}
                    </Grid2>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack gap="16px" direction="row-reverse">
            <Button
              variant="contained"
              color="dark"
              onClick={onAcceptOfferClick}
            >
              {highlighted ? "Jeg godtar" : "Godta tilbud"}
            </Button>
            {highlighted && (
              <Button variant="outlined" color="dark" onClick={onCancelClick}>
                Avbryt
              </Button>
            )}
          </Stack>
          <Stack alignItems="center" p="5px">
            <Typography
              variant="loanApplicationSummaryInfoText"
              color={COLORS.NORMAL[100]}
              textAlign="center"
            >
              Vil du endre nedbetalingstid?
            </Typography>
            <Typography
              variant="loanApplicationSummaryInfoText"
              color={COLORS.NORMAL[100]}
              textAlign="center"
            >
              Send oss en e-post på
              <Link
                style={{ fontSize: 14 }}
                href={`mailto:${process.env.CONTACT_EMAIL}`}
                target="_blank"
                rel="noreferrer"
              >
                {process.env.CONTACT_EMAIL}
              </Link>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export interface IProps {
  offer: IApplicationOfferClientView | undefined;
  comboOffer?: IApplicationComboOfferClientView | undefined;
  highlighted: boolean;
  onCancelAction: () => void;
  onAcceptOffer: () => void;
  isDualOffer?: boolean;
}
