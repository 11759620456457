import React, { useEffect, useRef } from "react";

interface InactivityTimerProps {
  onInactive: () => void;
}

const InactivityTimer: React.FC<InactivityTimerProps> = ({ onInactive }) => {
  const timeoutRef = useRef<number | null>(null);

  // Set timeout duration in milliseconds (5 minutes)
  const TIMEOUT_DURATION = 1000;

  const resetTimer = () => {
    // Clear any existing timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    // Set a new timeout
    timeoutRef.current = window.setTimeout(() => {
      onInactive();
    }, TIMEOUT_DURATION);
  };

  const handleUserActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    // Attach event listeners to detect user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);

    // Start the initial timer
    resetTimer();

    return () => {
      // Clean up event listeners and timer on component unmount
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return null; // This component does not render anything
};

export default InactivityTimer;
