import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { navigate } from "gatsby";
import { logout } from "../store";
import { NavigationNames } from "../../constants/navigationNames";
import { getLoanType } from "../../utils/loanTypeHandler";
import {
  goBackUntilInStepper,
  goForwardUntilInStepper,
  LoanTypeToStepperName,
} from "../../utils/navigationLoanTypeUtils";
import { changeProduct } from "./loanApplicationReducer";
import { PartnerProductType } from "../../services/nswag/clients/loanApplicationsClient";

export interface Step {
  label: string;
  url: string;
  completed: boolean;
  notInStepper?: boolean;
}

export interface StepState {
  steps: Step[];
  restartLoanSteps: Step[];
}

export interface setCompletedSteps {
  currentPath: string;
  includedCoApplicant: boolean;
  includedGuarantor: boolean;
}

const getCurrentStepper = (state: StepState) => {
  const { loanType } = getLoanType();
  const stepName = LoanTypeToStepperName(loanType);
  if (stepName === "steps") {
    return state.steps;
  }
  return state.restartLoanSteps;
};

export const initialState: StepState = {
  steps: [
    {
      label: "Lånebeløp",
      url: NavigationNames.SOKNAD.BELOP,
      completed: false,
    },
    {
      label: "Bolig",
      url: NavigationNames.SOKNAD.BOLIG,
      completed: false,
    },
    {
      label: "Gjeld",
      url: NavigationNames.SOKNAD.DEBT_AND_HOUSING,
      completed: false,
    },
    {
      label: "Inntekt",
      url: NavigationNames.SOKNAD.INCOME,
      completed: false,
    },
    {
      label: "Sivilstatus",
      url: NavigationNames.SOKNAD.CIVIL_STATUS,
      completed: false,
    },
    {
      label: "Medlåntaker",
      url: NavigationNames.SOKNAD.CO_APPLICANT,
      completed: false,
      notInStepper: true,
    },
    {
      label: "Oppsummering",
      url: NavigationNames.SOKNAD.SUMMARY,
      completed: false,
    },
  ] as Step[],
  restartLoanSteps: [
    {
      label: "Lånebeløp",
      url: NavigationNames.OMSTARTSLAN.BASE,
      completed: false,
    },
    {
      label: "Bolig",
      url: NavigationNames.OMSTARTSLAN.BOLIG,
      completed: false,
    },
    {
      label: "Kausjonist",
      url: NavigationNames.OMSTARTSLAN.KAUSJONIST,
      completed: false,
      notInStepper: true,
    },
    {
      label: "Gjeld",
      url: NavigationNames.OMSTARTSLAN.GJELD,
      completed: false,
    },
    {
      label: "Inntekt",
      url: NavigationNames.OMSTARTSLAN.INNTEKT,
      completed: false,
    },
    {
      label: "Sivilstatus",
      url: NavigationNames.OMSTARTSLAN.SIVILSTATUS,
      completed: false,
    },
    {
      label: "Medlåntaker",
      url: NavigationNames.OMSTARTSLAN.MEDLANTAKER,
      completed: false,
      notInStepper: true,
    },
    {
      label: "Oppsummering",
      url: NavigationNames.OMSTARTSLAN.OPPSUMMERING,
      completed: false,
    },
  ] as Step[],
};

const stepsSlice = createSlice({
  name: "steps",
  initialState,
  reducers: {
    navigateToStep: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      const { loanType } = getLoanType();

      const step = state[LoanTypeToStepperName(loanType)][index];
      navigate(step.url);
    },
    setAllPreviewsCompletedStepByUrl(
      state,
      action: PayloadAction<setCompletedSteps>
    ) {
      const { payload } = action;
      const { loanType } = getLoanType();
      const index = state[LoanTypeToStepperName(loanType)].findIndex(
        (step) => step.url === payload.currentPath
      );
      state[LoanTypeToStepperName(loanType)].forEach((step, i) => {
        if (
          (!payload.includedCoApplicant &&
            step.url === NavigationNames.SOKNAD.CO_APPLICANT) ||
          (!payload.includedCoApplicant &&
            step.url === NavigationNames.OMSTARTSLAN.MEDLANTAKER)
        ) {
          return;
        }

        if (
          !payload.includedGuarantor &&
          step.url === NavigationNames.OMSTARTSLAN.KAUSJONIST
        ) {
          return;
        }

        if (i < index) {
          step.completed = true;
        }
      });
    },
    goBackOneStep: (state) => {
      let currentUrl = "";
      if (typeof window !== "undefined") {
        currentUrl = window.location.pathname;
      }
      const currentStepper = getCurrentStepper(state);

      const index = currentStepper.findIndex((step) => step.url === currentUrl);

      if (index > 0) {
        navigate(
          goBackUntilInStepper(currentStepper, index) ??
            NavigationNames.SOKNAD.FETCHING_LOADING
        );
      }
    },
    goOneStepForward: (state, action: PayloadAction<boolean | undefined>) => {
      let currentUrl = "";
      if (typeof window !== "undefined") {
        currentUrl = window.location.pathname;
      }
      const currentStepper = getCurrentStepper(state);

      const index = currentStepper.findIndex((step) => step.url === currentUrl);
      if (index < 0) {
        const i = currentStepper.findIndex((step) => !step.completed);
        navigate(currentStepper[i + 1].url, {
          replace: action.payload,
        });
        return;
      }

      if (index < currentStepper.length - 1) {
        currentStepper[index].completed = true;
        navigate(goForwardUntilInStepper(currentStepper, index) ?? "", {
          replace: action.payload,
        });
      }
    },

    addGuarantor: (state) => {
      const { loanType } = getLoanType();
      const stepName = LoanTypeToStepperName(loanType);

      state[stepName] = state[stepName].map((step) => {
        if (step.url === NavigationNames.OMSTARTSLAN.KAUSJONIST) {
          return {
            ...step,
            notInStepper: false,
          };
        }
        return step;
      });
    },
    removeGuarantor: (state) => {
      const { loanType } = getLoanType();
      const stepName = LoanTypeToStepperName(loanType);
      state[stepName] = state[stepName].map((step) => {
        if (step.url === NavigationNames.OMSTARTSLAN.KAUSJONIST) {
          return {
            ...step,
            notInStepper: true,
          };
        }
        return step;
      });
    },

    addCoApplicant: (state) => {
      const { loanType } = getLoanType();
      const stepName = LoanTypeToStepperName(loanType);
      state[stepName] = state[stepName].map((step) => {
        if (
          step.url === NavigationNames.SOKNAD.CO_APPLICANT ||
          step.url === NavigationNames.OMSTARTSLAN.MEDLANTAKER
        ) {
          return {
            ...step,
            notInStepper: false,
          };
        }
        return step;
      });
    },
    removeCoApplicant: (state) => {
      const { loanType } = getLoanType();
      const stepName = LoanTypeToStepperName(loanType);
      state[stepName] = state[stepName].map((step) => {
        if (
          step.url === NavigationNames.SOKNAD.CO_APPLICANT ||
          step.url === NavigationNames.OMSTARTSLAN.MEDLANTAKER
        ) {
          return {
            ...step,
            notInStepper: true,
          };
        }
        return step;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState; // Reset to initial state
    });
    builder.addCase(changeProduct, (state, action) => {
      if (PartnerProductType.ConsumerLoan === action.payload) {
        state.restartLoanSteps = initialState.restartLoanSteps;
      } else {
        state.steps = initialState.steps;
      }
    });
  },
});

export const {
  navigateToStep,
  setAllPreviewsCompletedStepByUrl,
  goBackOneStep,
  addGuarantor,
  removeGuarantor,
  addCoApplicant,
  removeCoApplicant,
  goOneStepForward,
} = stepsSlice.actions;

export const getStepper = (state: any) => {
  const loanType = getLoanType();
  const stepName = LoanTypeToStepperName(loanType?.loanType ?? loanType);
  return state.stepsReducer[stepName] as Step[];
};

export default stepsSlice.reducer;
