import { Stack } from "@mui/material";
import React from "react";
import BankLogo from "./BankLogo";

export default function PartnerLogoCircle({
  partnerName,
}: {
  partnerName: string;
}) {
  return (
    <Stack
      bgcolor="#FFF"
      height={{ xs: "48px", md: "80px" }}
      width={{ xs: "48px", md: "80px" }}
      minWidth={{ xs: "48px", md: "80px" }}
      borderRadius="50%"
      justifyContent="center"
      alignItems="center"
      p="10px"
    >
      <BankLogo isSmall bankName={partnerName} />
    </Stack>
  );
}
