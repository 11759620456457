export const HordeURL = "https://horde.no";

const getBaseUrl = () => {
  if (typeof window === "undefined") {
    return HordeURL;
  }

  return window.location.origin;
};

export const BaseUrl = getBaseUrl();

export const BaseUrlWithoutSlash = () => {
  if (typeof window === "undefined") {
    return HordeURL;
  }

  let baseUrl: string = window.location.origin;

  if (baseUrl.endsWith("/")) {
    baseUrl = baseUrl.slice(0, -1);
  }

  return baseUrl;
};

export const getHrefUrl = () => {
  if (typeof window === "undefined") {
    return HordeURL;
  }

  return window.location.href;
};
