import React from "react";
import { Stack } from "@mui/material";
import COLORS from "../../../constants/colors";
import CustomStep from "./CustomStep";
import { NavigationNames } from "../../../constants/navigationNames";

interface OfferStepperProps {
  activeStep: number;
}

const OfferStepper = ({ activeStep = 1 }: OfferStepperProps) => {
  const progressStep = 0;

  const steps = [
    {
      id: 1,
      label: "Søknad sendt",
    },
    {
      id: 2,
      label: "Lånetilbud",
    },
    {
      id: 3,
      label: "Signering",
    },
    {
      id: 4,
      label: "Fullført",
    },
  ];

  return (
    <Stack direction="row" borderRadius="12px" mb={{ xs: "15px" }}>
      {steps.map((step, index) => {
        return (
          <Stack
            key={index}
            alignItems={{ xs: "flex-start", md: "center" }}
            flex={{ xs: index === steps.length - 1 ? 0 : 1, md: "1" }}
            width={{
              xs: index === steps.length - 1 ? "auto" : "10px",
              md: "auto",
            }}
            gap="12px"
            position="relative"
          >
            <Stack alignItems="center">
              <CustomStep
                label={step.label}
                isActive={activeStep === step.id}
                onClick={() => {}}
                completed={activeStep >= step.id}
                isCurrentStep={activeStep === step.id}
                disableClick
              />

              {index !== steps.length - 1 && (
                <Stack
                  position="absolute"
                  left={{ xs: 0, md: "50%" }}
                  top={{ xs: "40%", md: "73%" }}
                  width="100%"
                  height={{ xs: "2px", md: "4px" }}
                  bgcolor={
                    progressStep > index ? COLORS.BRIGHT_BLUE[100] : "#AEB4D7"
                  }
                  zIndex={0}
                />
              )}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
};

export default OfferStepper;
