import { updateWarningHandling } from "../redux/reducers/loanApplicationReducer";
import { AppThunk } from "../redux/store";

export const inputRangeWarningValidation =
  (max: number, min = 0) =>
  (input: number) =>
    input >= min && input <= max;

export const inputFactorRangeWarningValidation =
  (min: number, max: number, inputFactor = 1) =>
  (input: number) =>
    input * inputFactor >= min && input * inputFactor <= max;

export const handleNumberInputFactorValidation =
  (
    min: number,
    max: number,
    value: number,
    name: string,
    inputFactor: number = 1
  ): AppThunk | any =>
  async (dispatch: any) => {
    const validation = inputFactorRangeWarningValidation(
      min,
      max,
      inputFactor
    )(value);
    if (!validation) {
      dispatch(
        updateWarningHandling([
          {
            name,
            value: true,
          },
        ])
      );
    }
  };

export const handleNumberRangeInputValidation =
  (min: number, max: number, value: number, name: string): AppThunk | any =>
  async (dispatch: any) => {
    const validation = inputRangeWarningValidation(max, min)(value);
    if (!validation) {
      dispatch(
        updateWarningHandling([
          {
            name,
            value: true,
          },
        ])
      );
    }
  };

export const emailValidation = (input: string) =>
  /^[A-Za-z0-9_\-\.]+(\+[A-Za-z0-9_\-\.]+)?\@([A-Za-z0-9_\-\.]+)\.([A-Za-z]{2,4})$/.test(
    input
  );
