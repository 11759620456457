import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import { ArrowForward } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import COLORS from "../../../constants/colors";
import ClickableStack from "../../ClickableStack";
import CharacterOnGlobe from "../../../assets/svgs/characterOnGlobe.svg";
import useIsMobile from "../../../hooks/useIsMobile";

export default function PrefillApplicationPromptCard({
  onConfirm,
  onDeny,
  onClose,
}: IProps) {
  const isMobile = useIsMobile();

  const onConfirmButtonClick = () => {
    onConfirm();
  };
  const onDenyButtonClick = () => {
    onDeny();
  };
  const onCloseButtonClick = () => {
    onClose();
  };

  return (
    <Box
      bgcolor={{ xs: COLORS.BRIGHT_BLUE[10], md: "#FFF" }}
      p={{ xs: "24px", md: "48px" }}
      borderRadius="12px"
    >
      <Box bgcolor={COLORS.BRIGHT_BLUE[10]} borderRadius="12px">
        <ClickableStack
          onClick={onCloseButtonClick}
          alignItems="flex-end"
          padding="10px"
        >
          <CancelIcon
            color="primary"
            style={{
              height: isMobile ? "34px" : "64px",
              width: isMobile ? "34px" : "64px",
            }}
          />
        </ClickableStack>
        <Box
          px={{ xs: "0px", md: "24px" }}
          py={{ xs: "24px", md: "0px" }}
          pb={{ xs: "24px", md: "48px" }}
        >
          <Stack gap="32px" alignItems="center">
            <Stack display={{ xs: "none", md: "block" }}>
              <CharacterOnGlobe width="150px" />
            </Stack>
            <Typography
              variant="h3"
              color={COLORS.NORMAL[100]}
              textAlign="center"
            >
              Horde gjør det enkelt
            </Typography>
            <Typography
              color={COLORS.NORMAL[100]}
              variant="body1"
              textAlign="center"
            >
              Vil du bruke innholdet i din forrige søknad som utgangspunkt? Da
              slipper du å fylle ut alt på nytt.
            </Typography>
            <Button
              variant="containedCircle"
              color="dark"
              onClick={onConfirmButtonClick}
              endIcon={<ArrowForward />}
            >
              Ja, bruk eksisterende
            </Button>
            <Button
              variant="containedCircle"
              color="light"
              onClick={onDenyButtonClick}
            >
              Start helt ny søknad
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
}

export interface IProps {
  onConfirm: () => void;
  onDeny: () => void;
  onClose: () => void;
}
