/**
 * Returns the base URL without a trailing slash.
 * If the code is running in a browser environment, it uses the current window's location origin as the base URL.
 * If the code is running in a non-browser environment, it returns a default base URL.
 * @returns The base URL without a trailing slash.
 */
export const baseUrlWithoutSlash = () => {
  if (typeof window === "undefined") {
    return "https://horde.no";
  }

  const baseUrl: string = window.location.origin;

  return baseUrl;
};

/**
 * Returns the path without a trailing slash.
 * @returns The path without a trailing slash.
 */
export const pathWithoutSlash = () => {
  if (typeof window === "undefined") {
    return "https://horde.no";
  }

  let path: string = window.location.pathname;

  if (path.endsWith("/") && path.length > 1) {
    path = path.slice(0, -1);
  }

  return path;
};

/**
 * Returns the first segment of the current path.
 * @returns The first segment of the current path.
 */
export const getFirstMenuSegment = () => {
  const pathname = pathWithoutSlash();
  const segments = pathname.split("/").filter(Boolean); // Split by '/' and remove empty segments

  return segments.length > 0 ? `/${segments[0]}` : "/";
};
