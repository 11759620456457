import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import { Stack } from "@mui/system";
import COLORS from "../../../constants/colors";

interface LogoutInfoModalProps {
  show: boolean;
  handleClose: (v: boolean) => void;
}

const InfoModalStepper = ({ show, handleClose }: LogoutInfoModalProps) => {
  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialogContent-root": {
          padding: "0",
        },
        "& .MuiDialog-paper": {
          borderRadius: "32px",
        },
      }}
    >
      <DialogContent>
        <Stack
          padding={{ xs: "24px", md: "48px" }}
          bgcolor={COLORS.BRIGHT_BLUE[10]}
        >
          <Box
            bgcolor={COLORS.BRIGHT_BLUE[10]}
            borderRadius="12px"
            padding={{ xs: "24px", md: "48px" }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap="32px"
          >
            <Stack>
              <Typography
                variant="h2"
                textAlign="center"
                color={COLORS.NORMAL[100]}
              >
                Mangel på utfylling
              </Typography>
            </Stack>
            <Stack>
              <Typography
                variant="lead"
                textAlign="center"
                color={COLORS.NORMAL[100]}
              >
                Du kan dessverre ikke navigere videre fordi ikke alle feltene på
                siden er fylt ut. Vennligst fyll ut feltene før du fortsetter.
              </Typography>
            </Stack>
            <Box display="flex" flexDirection="column" gap="24px">
              <Button
                variant="contained"
                color="dark"
                onClick={() => {
                  handleClose(false);
                }}
              >
                Lukk
              </Button>
            </Box>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default InfoModalStepper;
