import { InputAdornment } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../TextInput";
import { InputFieldsCoApplicant } from "../../../constants/inputFieldNamesSoknad";
import {
  handleNumberInputFactorValidation,
  handleNumberRangeInputValidation,
} from "../../../utils/warningValidationUtils";
import {
  getLoanApplicationForm,
  updateLoanApplicationCoApplicant,
} from "../../../redux/reducers/loanApplicationReducer";
import { errorPreFixNamingCoApplicant } from "../../../pages/soknad/forbrukslan/medlantaker";
import CustomNumericFormat from "../../CustomNumericFormat";
import { MaritalStatusType } from "../../../services/nswag/clients/loanApplicationsClient";
import useCoApplicant from "../../../hooks/useCoApplicant";

const CoApplicantIncome = () => {
  const dispatch = useDispatch();
  const { applicant } = useSelector(getLoanApplicationForm);
  const { coApplicant } = useCoApplicant();

  const isMartialStatusLiveWithMainApplicant =
    applicant.maritalStatus === MaritalStatusType.Married ||
    applicant.maritalStatus === MaritalStatusType.Cohabitant;

  useEffect(() => {
    dispatch(
      updateLoanApplicationCoApplicant({
        name: InputFieldsCoApplicant.livesWithMainApplicant,
        value: isMartialStatusLiveWithMainApplicant,
        index: 0,
      })
    );

    if (!coApplicant?.yearlyGrossIncome) {
      if (isMartialStatusLiveWithMainApplicant) {
        dispatch(
          updateLoanApplicationCoApplicant({
            name: InputFieldsCoApplicant.yearlyGrossIncome,
            value: applicant.spouseGrossYearlyIncome,
            index: 0,
          })
        );
      }
    }
  }, [applicant?.maritalStatus]);

  return (
    <>
      {!isMartialStatusLiveWithMainApplicant && (
        <CustomNumericFormat
          allowNegative={false}
          id="coapplicant-total-income-pre-tax"
          label="Medlåntakers total årsinntekt før skatt"
          thousandSeparator=" "
          customInput={TextInput}
          value={coApplicant?.yearlyGrossIncome}
          onValueChange={(value) => {
            dispatch(
              updateLoanApplicationCoApplicant({
                name: InputFieldsCoApplicant.yearlyGrossIncome,
                value: value.floatValue,
                index: 0,
              })
            );
          }}
          onBlur={() => {
            dispatch(
              handleNumberRangeInputValidation(
                100000,
                2000000,
                coApplicant.yearlyGrossIncome,
                `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.yearlyGrossIncome}`
              )
            );
          }}
          name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.yearlyGrossIncome}`}
          type="text"
          variant="outlined"
          slotProps={{
            htmlInput: {
              maxLength: 9,
              inputMode: "numeric",
            },
            input: {
              sx: {
                maxWidth: "150px",
              },
              endAdornment: <InputAdornment position="end">kr</InputAdornment>,
            },
          }}
        />
      )}
      <CustomNumericFormat
        allowNegative={false}
        id="coapplicant-paid-out-monthly-after-tax"
        label="Medlåntakers utbetalt i måneden etter skatt"
        thousandSeparator=" "
        customInput={TextInput}
        value={coApplicant?.monthlyNetIncome}
        onValueChange={(value) => {
          dispatch(
            updateLoanApplicationCoApplicant({
              name: InputFieldsCoApplicant.monthlyNetIncome,
              value: value.floatValue,
              index: 0,
            })
          );
        }}
        onBlur={() => {
          dispatch(
            handleNumberInputFactorValidation(
              120000,
              coApplicant?.yearlyGrossIncome ?? 0,
              coApplicant?.monthlyNetIncome ?? 0,
              `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.monthlyNetIncome}`,
              12
            )
          );
        }}
        name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.monthlyNetIncome}`}
        type="text"
        variant="outlined"
        slotProps={{
          htmlInput: {
            maxLength: 7,
            inputMode: "numeric",
          },
          input: {
            sx: {
              maxWidth: "150px",
            },
            endAdornment: <InputAdornment position="end">kr</InputAdornment>,
          },
        }}
      />
    </>
  );
};

export default CoApplicantIncome;
