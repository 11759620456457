import { navigate } from "gatsby";
import { PartnerProductType } from "../services/nswag/clients/loanApplicationsClient";
import { NavigationNames } from "../constants/navigationNames";
import { SoknadType } from "../constants/soknadType";
import { getLoanType } from "./loanTypeHandler";
import { Step } from "../redux/reducers/stepsReducer";

/**
 * LoanType is the param used in url to define witch loan type the user is applying for.
 * @constructor
 */
export const NavigateBySoknadType = (type?: any) => {
  const loanType = type ?? getLoanType();

  if (!loanType?.loanType) {
    navigate(NavigationNames.SOKNAD.BELOP, { replace: true });
    return;
  }

  if (
    loanType.loanType === SoknadType.ConsumerLoan ||
    loanType.loanType === SoknadType.refinance
  ) {
    navigate(NavigationNames.SOKNAD.BELOP, { replace: true });
    return;
  }
  if (loanType.loanType === SoknadType.Restartloan) {
    navigate(NavigationNames.OMSTARTSLAN.BASE, { replace: true });
    return;
  }
  if (loanType.loanType === SoknadType.CreditCard) {
    navigate(NavigationNames.KredittKort, { replace: true });
    return;
  }
  navigate(NavigationNames.SOKNAD.BELOP, { replace: true });
};

export const NavigateByLoanType = (loanType: PartnerProductType) => {
  if (loanType === PartnerProductType.ConsumerLoan) {
    navigate(NavigationNames.SOKNAD.BELOP);
  }
  if (loanType === PartnerProductType.RestartLoan) {
    navigate(NavigationNames.OMSTARTSLAN.BASE);
  }
  if (loanType === PartnerProductType.CreditCard) {
    navigate(NavigationNames.KredittKort);
  }
};

export const SoknadTypeToLoanType = (soknadType: string) => {
  if (
    soknadType === SoknadType.ConsumerLoan ||
    soknadType === SoknadType.refinance
  ) {
    return PartnerProductType.ConsumerLoan;
  }
  if (soknadType === SoknadType.Restartloan) {
    return PartnerProductType.RestartLoan;
  }
  if (soknadType === SoknadType.CreditCard) {
    return PartnerProductType.CreditCard;
  }
  return PartnerProductType.ConsumerLoan;
};

export const LoanTypeToSoknadType = (loanType: PartnerProductType) => {
  if (loanType === PartnerProductType.ConsumerLoan) {
    return SoknadType.ConsumerLoan;
  }
  if (loanType === PartnerProductType.RestartLoan) {
    return SoknadType.Restartloan;
  }
  if (loanType === PartnerProductType.CreditCard) {
    return SoknadType.CreditCard;
  }
  return SoknadType.ConsumerLoan;
};

export const LoanTypeToStepperName = (loanType: string) => {
  if (
    loanType === SoknadType.ConsumerLoan ||
    loanType === SoknadType.refinance
  ) {
    return "steps";
  }
  if (loanType === SoknadType.Restartloan) {
    return "restartLoanSteps";
  }
  if (loanType === SoknadType.CreditCard) {
    return "creditCardSteps";
  }
  return "steps";
};

export function addOrUpdateQueryParam(param: string, value: string) {
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href);
    url.searchParams.set(param, value);

    // Navigate to the updated URL without reloading
    navigate(`${url.pathname}?${url.searchParams.toString()}`, {
      replace: true,
    });
  }
}

export function goBackUntilInStepper(
  steps: Step[],
  index: number
): string | undefined {
  for (let i = index - 1; i >= 0; i--) {
    const step = steps[i];
    if (step.notInStepper === undefined || !step.notInStepper) {
      return step.url;
    }
  }
  return undefined;
}

export function goForwardUntilInStepper(
  steps: Step[],
  index: number
): string | undefined {
  for (let i = index + 1; i <= steps.length - 1; i++) {
    const step = steps[i];
    if (step.notInStepper === undefined || !step.notInStepper) {
      return step.url;
    }
  }
  return undefined;
}
