import React from "react";
import { useDispatch } from "react-redux";
import { InputFieldsCoApplicant } from "../../../constants/inputFieldNamesSoknad";
import ButtonToggleGroup from "../../ButtonToggle";
import { SelfReportedPaymentRemarks } from "../../../services/nswag/clients/loanApplicationsClient";
import {
  refreshErrorHandlingStatus,
  updateLoanApplicationCoApplicant,
} from "../../../redux/reducers/loanApplicationReducer";
import { errorPreFixNamingCoApplicant } from "../../../pages/soknad/forbrukslan/medlantaker";
import useCoApplicant from "../../../hooks/useCoApplicant";

const CoApplicantPaymentRemark = () => {
  const dispatch = useDispatch();
  const { coApplicant } = useCoApplicant();
  const validateNames = Object.keys(InputFieldsCoApplicant).map(
    (item) => `${errorPreFixNamingCoApplicant}${item}`
  );

  const buttonOptions = [
    { label: "Ja", value: SelfReportedPaymentRemarks.Yes },
    { label: "Nei", value: SelfReportedPaymentRemarks.No },
    { label: "Vet ikke", value: SelfReportedPaymentRemarks.DontKnow },
  ];

  return (
    <ButtonToggleGroup
      label="Har medlåntaker betalingsanmerkninger?"
      description="Konkurs, ubetalte inkassosaker, utlegg, lønnstrekk. 
      Et inkassovarsel er ikke det samme som betalingsanmerkning."
      buttons={buttonOptions}
      onSelectedChange={(value) => {
        dispatch(
          updateLoanApplicationCoApplicant({
            name: InputFieldsCoApplicant.hasPaymentRemarks,
            value,
            index: 0,
          })
        );
        if (value === SelfReportedPaymentRemarks.Yes) {
          dispatch(refreshErrorHandlingStatus(validateNames));
        }
      }}
      name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.hasPaymentRemarks}`}
      value={coApplicant?.hasPaymentRemarks}
    />
  );
};

export default CoApplicantPaymentRemark;
