const path = "./no/";

const banks = [
  { ref: "zolva as", path: require(`${path}zolva-as.png`).default },
  { ref: "wise", path: require(`${path}wise.png`).default },
  {
    ref: "volkswagen møller bilfinans as",
    path: require(`${path}volkswagen-moller-bilfinans-as.png`).default,
  },
  { ref: "strawberry", path: require(`${path}strawberry.png`).default },
  {
    ref: "skien kommunale pensjonskasse",
    path: require(`${path}skien-kommunale-pensjonskasse.png`).default,
  },
  {
    ref: "romerike sparebank 1",
    path: require(`${path}romerike-sparebank-1.png`).default,
  },
  {
    ref: "romerike sparebank (Lillestrømbanken)",
    path: require(`${path}romerike-sparebank-1.png`).default,
  },
  {
    ref: "riverty norway as",
    path: require(`${path}riverty-norway-as.png`).default,
  },
  { ref: "qliro", path: require(`${path}qliro.png`).default },
  {
    ref: "norgesgruppen-finans",
    path: require(`${path}norgesgruppen-finans.png`).default,
  },
  {
    ref: "norgesgruppen finans as",
    path: require(`${path}norgesgruppen-finans.png`).default,
  },
  {
    ref: "norgesgruppen-finans-1",
    path: require(`${path}norgesgruppen-finans-1.png`).default,
  },
  {
    ref: "multitude bank plc",
    path: require(`${path}multitude-bank-plc.png`).default,
  },
  { ref: "marginalen", path: require(`${path}marginalen.png`).default },
  { ref: "jbf", path: require(`${path}jbf.png`).default },
  {
    ref: "intrum capital as",
    path: require(`${path}intrum-capital-as.png`).default,
  },
  { ref: "hjartdalbanken", path: require(`${path}hjartdalbanken.png`).default },
  {
    ref: "erik penser bank ab",
    path: require(`${path}erik-penser-bank-ab.png`).default,
  },
  {
    ref: "boligbyggelagenes kredittkort for medlemmer",
    path: require(`${path}boligbyggelagenes-kredittkort-for-medlemmer.png`)
      .default,
  },
  { ref: "aprila bank asa", path: require(`${path}aprilia.png`).default },
  {
    ref: "american express",
    path: require(`${path}american-express.png`).default,
  },
  { ref: "Sleek Bank", path: require(`${path}aafjord-sparebank.png`).default },
  {
    ref: "Deutsche Bank Testbank",
    path: require(`${path}aafjord-sparebank.png`).default,
  },
  {
    ref: "åfjord sparebank",
    path: require(`${path}aafjord-sparebank.png`).default,
  },
  {
    ref: "aasen sparebank",
    path: require(`${path}aasen-sparebank.png`).default,
  },
  { ref: "ak nordic", path: require(`${path}aasen-sparebank.png`).default },
  { ref: "alektum", path: require(`${path}alektum-finans.png`).default },
  { ref: "andebu", path: require(`${path}andebu-sparebank.png`).default },
  {
    ref: "arendal og omegn",
    path: require(`${path}arendal-og-omegns-sparekasse.png`).default,
  },
  { ref: "arvato finance", path: require(`${path}arvato-finance.png`).default },
  {
    ref: "as financiering",
    path: require(`${path}as-financiering.png`).default,
  },
  {
    ref: "askim og spydeberg",
    path: require(`${path}askim-og-spydeberg-sparebank.png`).default,
  },
  {
    ref: "askim & spydeberg sparebank",
    path: require(`${path}askim-&-spydeberge-sparebank.png`).default,
  },
  {
    ref: "aurland sparebank",
    path: require(`${path}aurland-sparebank.png`).default,
  },
  {
    ref: "aurskog sparebank",
    path: require(`${path}aurskog-sparebank.png`).default,
  },
  {
    ref: "austre agder sparebank",
    path: require(`${path}agder-sparebank.png`).default,
  },
  {
    ref: "agder sparebank",
    path: require(`${path}agder-sparebank.png`).default,
  },
  {
    ref: "avida",
    path: require(`${path}avida.png`).default,
    largeImagePath: require(`${path}avida.png`).default,
  },
  {
    ref: "coop ditt fordelslån",
    path: require(`${path}coop-ditt-fordelslaan.png`).default,
  },
  {
    ref: "coop mastercard",
    path: require(`${path}coop-mastercard.png`).default,
  },
  {
    ref: "coop medlems & betalingskort",
    path: require(`${path}coop-medles-&-betalingskort.png`).default,
  },
  {
    ref: "cultura bank",
    path: require(`${path}cultura-bank.png`).default,
  },
  {
    ref: "esso mastercard",
    path: require(`${path}esso-mastercard.png`).default,
  },
  {
    ref: "eurocard",
    path: require(`${path}eurocard.png`).default,
  },
  {
    ref: "finnair mastercard",
    path: require(`${path}finnair-mastercard.png`).default,
  },
  {
    ref: "helgeland gull",
    path: require(`${path}helgeland-gull.png`).default,
  },
  {
    ref: "lillestrømbanken",
    path: require(`${path}lillestroembanken.png`).default,
  },
  {
    ref: "lunar",
    path: require(`${path}lunar.png`).default,
  },
  {
    ref: "mastercard gold",
    path: require(`${path}mastercard-gold.png`).default,
  },
  {
    ref: "melhusbanken",
    path: require(`${path}melhusbanken.png`).default,
  },
  {
    ref: "n26",
    path: require(`${path}n26.png`).default,
  },
  {
    ref: "ørland sparebank",
    path: require(`${path}oerland-sparebank.png`).default,
  },
  {
    ref: "ørskog sparebank",
    path: require(`${path}oerskog-sparebank.png`).default,
  },
  {
    ref: "østre agder sparebank",
    path: require(`${path}oestre-agder-sparebank.png`).default,
  },
  {
    ref: "oppdalsbank",
    path: require(`${path}oppdalsbank.png`).default,
  },
  {
    ref: "paypal",
    path: require(`${path}paypal.png`).default,
  },
  {
    ref: "re:member forbrukslån",
    path: require(`${path}re-member-forbrukslaan.png`).default,
  },
  {
    ref: "re:member kredittkort",
    path: require(`${path}re-member-kredittkort.png`).default,
  },
  {
    ref: "revolut",
    path: require(`${path}revolut.png`).default,
  },
  {
    ref: "sas mastercard",
    path: require(`${path}sas-mastercard.png`).default,
  },
  {
    ref: "seb selected",
    path: require(`${path}seb-selected.png`).default,
  },
  {
    ref: "sparebank 68° nord",
    path: require(`${path}sparebank-68-nord.png`).default,
  },
  {
    ref: "sparesmart",
    path: require(`${path}sparesmart.png`).default,
  },
  {
    ref: "strømmensparebank",
    path: require(`${path}stroemmensparebank.png`).default,
  },
  {
    ref: "swedbank",
    path: require(`${path}swedbank.png`).default,
  },
  {
    ref: "vekselbanken",
    path: require(`${path}vekselbanken.png`).default,
  },
  {
    ref: "volvo mastercard",
    path: require(`${path}volvo-mastercard.png`).default,
  },
  {
    ref: "ya bank",
    path: require(`${path}ya-bank.png`).default,
  },
  {
    ref: "circle k mastercard",
    path: require(`${path}circle-k-mastercard.png`).default,
  },
  { ref: "axactor", path: require(`${path}axactor-capital.png`).default },
  { ref: "bank norwegian", path: require(`${path}bank-norwegian.png`).default },
  { ref: "bank2", path: require(`${path}bank2.png`).default },
  { ref: "bb bank", path: require(`${path}bb-bank.png`).default },
  { ref: "berg sparebank", path: require(`${path}berg-sparebank.png`).default },
  { ref: "bien sparebank", path: require(`${path}bien-sparebank.png`).default },
  {
    ref: "birkenes sparebank",
    path: require(`${path}birkenes-sparebank.png`).default,
  },
  {
    ref: "bjugn sparebank",
    path: require(`${path}bjugn-sparebank.png`).default,
  },
  {
    ref: "blaker sparebank",
    path: require(`${path}blaker-sparebank.png`).default,
  },
  { ref: "bluestep", path: require(`${path}bluestep.png`).default },
  { ref: "bn bank", path: require(`${path}bn-bank.png`).default },
  { ref: "brabank", path: require(`${path}brabank.png`).default },
  { ref: "brage finans", path: require(`${path}brage-finans.png`).default },
  { ref: "collector bank", path: require(`${path}collector-bank.png`).default },
  {
    ref: "cultura sparebank",
    path: require(`${path}cultura-sparebank.png`).default,
  },
  { ref: "danske bank", path: require(`${path}danske-bank.png`).default },
  { ref: "dnb norge", path: require(`${path}dnb-bank.png`).default },
  { ref: "dnb", path: require(`${path}dnb-bank.png`).default },
  { ref: "dnb bank", path: require(`${path}dnb-bank.png`).default },
  {
    ref: "drangedal sparebank",
    path: require(`${path}drangedal-sparebank.png`).default,
  },
  { ref: "easybank", path: require(`${path}easybank.png`).default },
  {
    ref: "eidsberg sparebank",
    path: require(`${path}eidsberg-sparebank.png`).default,
  },
  {
    ref: "eika kredittbank",
    path: require(`${path}eika-kredittbank.png`).default,
  },
  { ref: "ekspress bank", path: require(`${path}ekspress-bank.png`).default },
  { ref: "entercard", path: require(`${path}entercard.png`).default },
  { ref: "etne sparebank", path: require(`${path}etne-sparebank.png`).default },
  {
    ref: "etnedal sparebank",
    path: require(`${path}etnedal-sparebank.png`).default,
  },
  {
    ref: "evje og hornnes",
    path: require(`${path}evje-og-hornnes-sparebank.png`).default,
  },
  { ref: "fana sparebank", path: require(`${path}fana-sparebank.png`).default },
  { ref: "ferratum bank", path: require(`${path}ferratum-bank.png`).default },
  {
    ref: "flekkefjord sparebank",
    path: require(`${path}flekkefjord-sparebank.png`).default,
  },
  { ref: "folkefinans", path: require(`${path}folkefinans.png`).default },
  {
    ref: "fornebu sparebank",
    path: require(`${path}fornebu-sparebank.png`).default,
  },
  { ref: "fundingpartner", path: require(`${path}fundingpartner.png`).default },
  {
    ref: "gildeskål sparebank",
    path: require(`${path}gildeskaal-sparebank.png`).default,
  },
  { ref: "gjensidige", path: require(`${path}gjensidige-bank.png`).default },
  {
    ref: "grong sparebank",
    path: require(`${path}grong-sparebank.png`).default,
  },
  { ref: "grue sparebank", path: require(`${path}grue-sparebank.png`).default },
  {
    ref: "haltdalen sparebank",
    path: require(`${path}haltdalen-sparebank.png`).default,
  },
  { ref: "handelsbanken", path: require(`${path}handelsbanken.png`).default },
  {
    ref: "haugesund sparebank",
    path: require(`${path}haugesund-sparebank.png`).default,
  },
  {
    ref: "hegra sparebank",
    path: require(`${path}hegra-sparebank.png`).default,
  },
  {
    ref: "helgeland sparebank",
    path: require(`${path}helgeland-sparebank.png`).default,
  },
  {
    ref: "hemne sparebank",
    path: require(`${path}hemne-sparebank.png`).default,
  },
  {
    ref: "hjartdal og gransherad",
    path: require(`${path}hjartdal-og-gransherad-sparebank.png`).default,
  },
  {
    ref: "hjelmeland sparebank",
    path: require(`${path}hjelmeland-sparebank.png`).default,
  },
  {
    ref: "høland og setskog",
    path: require(`${path}hoeland-og-setskog-sparebank.png`).default,
  },
  {
    ref: "hønefoss sparebank",
    path: require(`${path}hoenefoss-sparebank.png`).default,
  },
  { ref: "ikano", path: require(`${path}ikano-bank.png`).default },
  {
    ref: "instabank",
    path: require(`${path}instabank.png`).default,
    largeImagePath: require(`${path}instabank.png`).default,
  },
  {
    ref: "jæren sparebank",
    path: require(`${path}jaeren-sparebank.png`).default,
  },
  {
    ref: "jernbane personalets sparebank",
    path: require(`${path}jernbanepersonalets-sparebank.png`).default,
  },
  { ref: "klarna", path: require(`${path}klarna-bank.png`).default },
  { ref: "klp", path: require(`${path}klp-banken.png`).default },
  { ref: "komplettbank", path: require(`${path}komplett-bank.png`).default },
  { ref: "komplett bank", path: require(`${path}komplett-bank.png`).default },
  { ref: "kraft bank", path: require(`${path}kraft-bank.png`).default },
  { ref: "kraftbank", path: require(`${path}kraft-bank.png`).default },
  { ref: "kredd", path: require(`${path}kredd.png`).default },
  {
    ref: "kredinor finans",
    path: require(`${path}kredinor-finans.png`).default,
  },
  {
    ref: "kvinesdal sparebank",
    path: require(`${path}kvinesdal-sparebank.png`).default,
  },
  {
    ref: "landkreditt bank",
    path: require(`${path}landkreditt-bank.png`).default,
  },
  { ref: "larvikbanken", path: require(`${path}larvikbanken.png`).default },
  {
    ref: "lillesands sparebank",
    path: require(`${path}lillesands-sparebank.png`).default,
  },
  {
    ref: "lillestrøm sparebank",
    path: require(`${path}lillestroem-sparebank.png`).default,
  },
  { ref: "lowell finans", path: require(`${path}lowell-finans.png`).default },
  {
    ref: "luster sparebank",
    path: require(`${path}luster-sparebank.png`).default,
  },
  {
    ref: "marker sparebank",
    path: require(`${path}marker-sparebank.png`).default,
  },
  {
    ref: "melhus sparebank",
    path: require(`${path}melhus-sparebank.png`).default,
  },
  { ref: "monobank", path: require(`${path}monobank.png`).default },
  {
    ref: "morrow",
    path: require(`${path}morrow-bank-small.png`).default,
    largeImagePath: require(`${path}morrow-bank.png`).default,
  },
  {
    ref: "morrow bank",
    path: require(`${path}morrow-bank-small.png`).default,
    largeImagePath: require(`${path}morrow-bank.png`).default,
  },
  {
    ref: "morrow bank asa",
    path: require(`${path}morrow-bank-small.png`).default,
    largeImagePath: require(`${path}morrow-bank.png`).default,
  },
  { ref: "mybank", path: require(`${path}mybank.png`).default },
  { ref: "næringsbanken", path: require(`${path}naeringsbanken.png`).default },
  {
    ref: "nidaros sparebank",
    path: require(`${path}nidaros-sparebank.png`).default,
  },
  {
    ref: "Nordax, En Del Av Noba Bank Group Ab (Publ)",
    path: require(`${path}nordax-bank_old.png`).default,
    largeImagePath: require(`${path}nordax-bank.png`).default,
  },
  {
    ref: "nordax bank",
    path: require(`${path}nordax-bank-new.png`).default,
    largeImagePath: require(`${path}nordax-bank.png`).default,
  },
  {
    ref: "noba bank group ab (publ)",
    path: require(`${path}nordax-bank-new.png`).default,
    largeImagePath: require(`${path}nordax-bank.png`).default,
  },
  { ref: "nordax", path: require(`${path}nordax.svg`).default },
  { ref: "nordea", path: require(`${path}nordea-bank.png`).default },
  { ref: "obos", path: require(`${path}obos-banken.png`).default },
  { ref: "odal sparebank", path: require(`${path}odal-sparebank.png`).default },
  {
    ref: "ofoten sparebank",
    path: require(`${path}ofoten-sparebank.png`).default,
  },
  {
    ref: "oppdals sparebank",
    path: require(`${path}oppdals-sparebank.png`).default,
  },
  { ref: "optin bank", path: require(`${path}optin-bank.png`).default },
  {
    ref: "orkla sparebank",
    path: require(`${path}orkla-sparebank.png`).default,
  },
  { ref: "pareto bank", path: require(`${path}pareto-bank.png`).default },
  { ref: "payex", path: require(`${path}payex-sverige.png`).default },
  {
    ref: "resurs bank",
    path: require(`${path}resurs-bank-icon.png`).default,
    largeImagePath: require(`${path}resurs-bank.png`).default,
  },
  {
    ref: "rindal sparebank",
    path: require(`${path}rindal-sparebank.png`).default,
  },
  {
    ref: "romsdal sparebank",
    path: require(`${path}romsdal-sparebank.png`).default,
  },
  {
    ref: "rørosbanken",
    path: require(`${path}roerosbanken-roeros-sparebank.png`).default,
  },
  {
    ref: "sandnes sparebank",
    path: require(`${path}sandnes-sparebank.png`).default,
  },
  {
    ref: "santander",
    path: require(`${path}santander-consumer-bank.png`).default,
    largeImagePath: require(`${path}santander-banner-1024.png`).default,
  },
  { ref: "sbanken", path: require(`${path}sbanken.png`).default },
  { ref: "seb kort bank", path: require(`${path}seb-kort-bank.png`).default },
  {
    ref: "selbu sparebank",
    path: require(`${path}selbu-sparebank.png`).default,
  },
  { ref: "sergel finans", path: require(`${path}sergel-finans.png`).default },
  {
    ref: "skagerrak sparebank",
    path: require(`${path}skagerrak-sparebank.png`).default,
  },
  {
    ref: "skandinaviska enskilda",
    path: require(`${path}skandinaviska-enskilda-bank.png`).default,
  },
  {
    ref: "skudenes & aakra",
    path: require(`${path}skudenes-og-aakra-sparebank.png`).default,
  },
  { ref: "skue sparebank", path: require(`${path}skue-sparebank.png`).default },
  { ref: "sogn sparebank", path: require(`${path}sogn-sparebank.png`).default },
  {
    ref: "søgne og greipstad",
    path: require(`${path}soegne-og-greipstad-sparebank.png`).default,
  },
  {
    ref: "soknedal sparebank",
    path: require(`${path}soknedal-sparebank.png`).default,
  },
  { ref: "sparebank 1", path: require(`${path}sparebank-1.png`).default },
  { ref: "sparebank1", path: require(`${path}sparebank-1.png`).default },
  {
    ref: "sparebank 68 grader nord",
    path: require(`${path}sparebank-68-grader-nord.png`).default,
  },
  {
    ref: "sparebanken din",
    path: require(`${path}sparebanken-din.png`).default,
  },
  {
    ref: "sparebanken møre",
    path: require(`${path}sparebanken-moere.png`).default,
  },
  {
    ref: "sparebanken narvik",
    path: require(`${path}sparebanken-narvik.png`).default,
  },
  {
    ref: "sparebanken øst",
    path: require(`${path}sparebanken-oest.png`).default,
  },
  {
    ref: "sparebanken sogn og fjordane",
    path: require(`${path}sparebanken-sogn-og-fjordane.png`).default,
  },
  {
    ref: "sparebanken sør",
    path: require(`${path}sparebanken-soer.png`).default,
  },
  {
    ref: "sparebanken telemark",
    path: require(`${path}sparebanken-telemark.png`).default,
  },
  {
    ref: "sparebanken vest",
    path: require(`${path}sparebanken-vest.png`).default,
  },
  {
    ref: "spareskillingsbanken",
    path: require(`${path}spareskillingsbanken.png`).default,
  },
  {
    ref: "ssb boligkreditt",
    path: require(`${path}ssb-boligkreditt.png`).default,
  },
  { ref: "st1", path: require(`${path}st1-finance.png`).default },
  {
    ref: "stadsbygd sparebank",
    path: require(`${path}stadsbygd-sparebank.png`).default,
  },
  { ref: "storebrand", path: require(`${path}storebrand-bank.png`).default },
  {
    ref: "strømmen sparebank",
    path: require(`${path}stroemmen-sparebank.png`).default,
  },
  {
    ref: "sunndal sparebank",
    path: require(`${path}sunndal-sparebank.png`).default,
  },
  {
    ref: "surnadal sparebank",
    path: require(`${path}surnadal-sparebank.png`).default,
  },
  {
    ref: "svea finans",
    path: require(`${path}svea-finans.png`).default,
    largeImagePath: require(`${path}svea-finans.png`).default,
  },
  {
    ref: "svea",
    path: require(`${path}svea-finans.png`).default,
    largeImagePath: require(`${path}svea-finans.png`).default,
  },
  {
    ref: "lea bank",
    path: require(`${path}lea-bank.png`).default,
    largeImagePath: require(`${path}lea-bank.png`).default,
  },
  {
    ref: "facit bank",
    path: require(`${path}facit-bank.png`).default,
    largeImagePath: require(`${path}facit-bank-large.png`).default,
  },
  { ref: "telia", path: require(`${path}telia-finance-norge.png`).default },
  {
    ref: "tf bank",
    path: require(`${path}tf-bank-white.png`).default
  },
  { ref: "thorn", path: require(`${path}thorn-norge-finans.png`).default },
  { ref: "tinn sparebank", path: require(`${path}tinn-sparebank.png`).default },
  { ref: "tolga os", path: require(`${path}tolga-os-sparebank.png`).default },
  {
    ref: "tolga-os sparebank",
    path: require(`${path}tolga-os-sparebank.png`).default,
  },
  {
    ref: "totens sparebank",
    path: require(`${path}totens-sparebank.png`).default,
  },
  {
    ref: "toyota",
    path: require(`${path}toyota-kredittbank-gmbh.png`).default,
  },
  {
    ref: "trøgstad sparebank",
    path: require(`${path}troegstad-sparebank.png`).default,
  },
  {
    ref: "tysnes sparebank",
    path: require(`${path}tysnes-sparebank.png`).default,
  },
  {
    ref: "valdres sparebank",
    path: require(`${path}valdres-sparebank.png`).default,
  },
  {
    ref: "valle sparebank",
    path: require(`${path}valle-sparebank.png`).default,
  },
  { ref: "voss sparebank", path: require(`${path}voss-sparebank.png`).default },
  {
    ref: "voss veksel",
    path: require(`${path}voss-veksel-og-landmandsbank.png`).default,
  },
];
export default banks;
