import {
  Container,
  ContainerProps,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import COLORS from "../../constants/colors";

import HordeLogo from "../../assets/svgs/horde-h.svg";
import CustomStepper from "../soknad/stepper/CustomStepper";
import OfferStepper from "../soknad/stepper/OfferStepper";
import {
  getLoanApplicationForm,
  getLoanApplicationFormApplicant,
} from "../../redux/reducers/loanApplicationReducer";
import { getStepper } from "../../redux/reducers/stepsReducer";
import useIsMobile from "../../hooks/useIsMobile";
import CancelApplicationButton from "../soknad/CancelApplicationButton";
import CustomNumericFormat from "../CustomNumericFormat";
import { logout } from "../../redux/store";
import { PartnerProductTypeTranslation } from "../../utils/emunTranslation";

interface Props extends ContainerProps {
  children: React.ReactNode | React.ReactNode[];
  showStepper?: boolean;
  showAmount?: boolean;
  title?: string;
  offerStepper?: boolean;
  hideLogoutButton?: boolean;
  activeOfferStep?: number;
  bgcolor?: any;
  clickableLogo?: boolean;
  showCancelButton?: boolean;
  validateBeforeNext?: () => boolean;
  gap?: string;
}

/**
 * Wrapper component for loan flow.
 */
const LoanFlowWrapper = ({
  children,
  showStepper = true,
  showAmount = true,
  title = "Lånesøknad",
  offerStepper = false,
  hideLogoutButton = false,
  activeOfferStep,
  clickableLogo,
  bgcolor = { xs: COLORS.BRIGHT_BLUE[20], md: COLORS.BRIGHT_BLUE[20] },
  validateBeforeNext,
  showCancelButton,
  gap = "24px",
  ...rest
}: Props) => {
  const dispatch = useDispatch();
  const { totalRefinancing, newLoanAmount, product } = useSelector(
    getLoanApplicationForm
  );
  const { firstName, middleName, lastName } = useSelector(
    getLoanApplicationFormApplicant
  );

  const name = `${firstName} ${middleName ?? ""} ${lastName}`;

  const steps = useSelector(getStepper);
  const isMobile = useIsMobile();

  const getTitle = () => {
    if (isMobile) {
      if (typeof window !== "undefined") {
        return steps.find((l) => l.url === window.location.pathname)?.label;
      }
    }
    return title;
  };

  return (
    <Stack
      component="main"
      bgcolor={bgcolor}
      flexGrow={1}
      minHeight="100vh"
      py="32px"
      gap={gap}
    >
      <Container maxWidth="xl" disableGutters>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          px="24px"
        >
          {clickableLogo ? (
            <Stack
              aria-label="Hjem"
              aria-hidden="true"
              tabIndex={0}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  navigate("/");
                }
              }}
              onClick={() => {
                navigate("/");
              }}
              display={{ xs: "none", lg: "flex" }}
              sx={{ cursor: "pointer" }}
            >
              <HordeLogo />
            </Stack>
          ) : (
            <Stack display={{ xs: "none", lg: "flex" }} aria-hidden="true">
              <HordeLogo />
            </Stack>
          )}
          <Container maxWidth="md" disableGutters>
            <Stack
              direction="row"
              justifyContent="space-between"
              pb="24px"
              display={{ xs: "flex", lg: "none" }}
            >
              <Stack aria-hidden="true">
                <HordeLogo />
              </Stack>
              {!hideLogoutButton && (
                <Stack
                  display="flex"
                  flexDirection="column"
                  alignItems="self-end"
                >
                  <Link
                    onClick={() => {
                      dispatch(logout());
                    }}
                    color="primary"
                    variant="label"
                  >
                    Logg ut
                  </Link>
                  <Typography variant="label" color={COLORS.DARK_BLUE[100]}>
                    {name}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Stack justifyContent="space-between" mb="6px" direction="row">
              <Typography
                variant="bodySmall"
                display={{ xs: "block", md: "none" }}
                color={COLORS.DARK_BLUE[100]}
              >
                {getTitle()}
              </Typography>
              {showAmount && !offerStepper && (
                <Stack alignItems="flex-end">
                  <Typography
                    variant="labelBoldBig"
                    display={{ xs: "block", md: "none" }}
                    color={COLORS.BLUE[100]}
                  >
                    <CustomNumericFormat
                      allowNegative={false}
                      suffix=" kr"
                      displayType="text"
                      value={totalRefinancing + newLoanAmount}
                      thousandSeparator=" "
                      decimalSeparator=","
                      fixedDecimalScale
                      decimalScale={0}
                    />
                  </Typography>
                </Stack>
              )}
            </Stack>

            {offerStepper ? (
              <OfferStepper activeStep={activeOfferStep} />
            ) : (
              showStepper && (
                <CustomStepper validateBeforeNext={validateBeforeNext} />
              )
            )}
            <Stack
              display="flex"
              flexDirection="column"
              alignItems="self-end"
              paddingX={{ md: "24px", xs: "0px" }}
            >
              {showCancelButton && <CancelApplicationButton />}
            </Stack>
          </Container>
          <Stack display={{ xs: "none", lg: "flex" }}>
            {!hideLogoutButton && (
              <Stack
                display="flex"
                flexDirection="column"
                alignItems="self-end"
              >
                <Link
                  onClick={() => {
                    dispatch(logout());
                  }}
                  color="primary"
                  variant="label"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      dispatch(logout());
                    }
                  }}
                >
                  Logg ut
                </Link>
                <Typography variant="label" color={COLORS.DARK_BLUE[100]}>
                  {name}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Container>
      <Container maxWidth="md" {...rest}>
        <Stack gap="12px">
          <Stack direction="row" justifyContent="space-between">
            {showAmount && !offerStepper && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="h5"
                    display={{ xs: "none", md: "block" }}
                    color={COLORS.BLUE[100]}
                  >
                    Du søker{" "}
                    {String(
                      PartnerProductTypeTranslation(product)
                    ).toLowerCase()}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="h5"
                    display={{ xs: "none", md: "block" }}
                    color={COLORS.BLUE[100]}
                  >
                    <CustomNumericFormat
                      allowNegative={false}
                      suffix=" kr"
                      displayType="text"
                      value={totalRefinancing + newLoanAmount}
                      thousandSeparator=" "
                      decimalSeparator=","
                      fixedDecimalScale
                      decimalScale={0}
                    />
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          {children}
        </Stack>
      </Container>
    </Stack>
  );
};

export default LoanFlowWrapper;
