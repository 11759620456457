import {
  Button,
  Dialog,
  DialogContent,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../../constants/colors";
import CaretDown from "../../../assets/svgs/caretDown.svg";
import TextInput from "../../TextInput";
import SelectInput from "../../SelectInput";
import {
  PropertyReportSource,
  PropertyType,
  ReportedProperty,
} from "../../../services/nswag/clients/loanApplicationsClient";
import { PropertyTypeTranslation } from "../../../utils/emunTranslation";
import {
  getLoanApplication,
  removeError,
  updateErrorHandling,
  updateLoanApplication,
} from "../../../redux/reducers/loanApplicationReducer";
import {
  InputFieldsNameAddProperty,
  InputFieldsNameBolig,
} from "../../../constants/inputFieldNamesSoknad";
import { AddPropertyValidationError } from "../../../utils/validationBeforeNextUtils";
import { apiFetchValidPostalNumber } from "../../../services/api/customer";
import CustomNumericFormat from "../../CustomNumericFormat";

interface AddHouseModalProps {
  show: boolean;
  handleClose: () => void;
}

export const errorPreFixReportedProperty = "reportedProperty";

const AddHouseModal = ({ show, handleClose }: AddHouseModalProps) => {
  const dispatch = useDispatch();
  const { application, errorHandling } = useSelector(getLoanApplication);

  const [property, setProperty] = useState<ReportedProperty>({
    source: PropertyReportSource.SelfReported,
    address: "",
    postalCode: "",
    type: undefined,
    estimatedValue: undefined,
    ownershipPercentage: undefined,
    electronicEvaluationRefNumber: "",
    municipalityName: "",
    floorCode: "",
  });

  const handleUpdate = (event: any) => {
    setProperty({
      ...property,
      [event.target.name.replace(errorPreFixReportedProperty, "")]:
        event.target.value,
    });
    if (errorHandling[event.target.name]) {
      dispatch(
        removeError({
          name: event.target.name,
          value: false,
        })
      );
    }
  };

  const save = () => {
    const errors = AddPropertyValidationError(property);
    if (errors.length > 0) {
      dispatch(updateErrorHandling(errors));
      return false;
    }

    dispatch(
      updateLoanApplication({
        name: "reportedProperties",
        value: [
          ...application.reportedProperties,
          property as ReportedProperty,
        ],
      })
    );
    dispatch(
      removeError({
        name: InputFieldsNameBolig.reportedProperty,
        value: false,
      })
    );
    dispatch(
      removeError({
        name: `${errorPreFixReportedProperty}ownershipPercentage`,
        value: false,
      })
    );
    setProperty({
      source: PropertyReportSource.SelfReported,
      address: "",
      postalCode: "",
      type: undefined,
      estimatedValue: undefined,
      ownershipPercentage: undefined,
      electronicEvaluationRefNumber: "",
    });
    handleClose();
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        "& .MuiDialogContent-root": {
          padding: "0",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent>
        <Stack
          borderRadius="12px"
          p={{ xs: "24px", md: "48px" }}
          bgcolor={COLORS.TRUE_WHITE[100]}
          gap="32px"
        >
          <Typography variant="h2Small" color={COLORS.DARK_BLUE[100]}>
            Legg til bolig
          </Typography>
          <Stack
            bgcolor={COLORS.BRIGHT_BLUE[10]}
            borderRadius="12px"
            p={{ xs: "12px", md: "24px" }}
            gap="16px"
          >
            <TextInput
              id="add-house-address"
              label="Adresse"
              variant="outlined"
              type="text"
              maxWidth="300px"
              value={property?.address}
              onChange={handleUpdate}
              name={`${errorPreFixReportedProperty}${InputFieldsNameAddProperty.address}`}
              slotProps={{
                htmlInput: {
                  maxLength: 300,
                },
              }}
            />
            <TextInput
              id="add-house-postal-code"
              label="Postnummer"
              variant="outlined"
              name={`${errorPreFixReportedProperty}${InputFieldsNameAddProperty.postalCode}`}
              type="text"
              slotProps={{
                htmlInput: {
                  maxLength: 4,
                  inputMode: "numeric",
                },
              }}
              onBlur={() => {
                apiFetchValidPostalNumber(property.postalCode).then((resp) => {
                  if (!resp.data) {
                    dispatch(
                      updateErrorHandling([
                        {
                          name: `${errorPreFixReportedProperty}${InputFieldsNameAddProperty.postalCode}`,
                          value: true,
                        },
                      ])
                    );
                  }
                });
              }}
              helperText={
                errorHandling[
                  `${errorPreFixReportedProperty}${InputFieldsNameAddProperty.postalCode}`
                ] && "Ugyldig postnummer"
              }
              maxWidth="70px"
              value={property?.postalCode}
              onChange={handleUpdate}
            />
            <SelectInput
              label="Type bolig"
              id="add-house-type"
              name={`${errorPreFixReportedProperty}${InputFieldsNameAddProperty.type}`}
              value={property?.type}
              onChange={handleUpdate}
              maxWidth="300px"
              variant="outlined"
              IconComponent={CaretDown}
            >
              {(
                Object.keys(PropertyType) as Array<keyof typeof PropertyType>
              ).map((key) => (
                <MenuItem key={key} value={key}>
                  {PropertyTypeTranslation(PropertyType[key])}
                </MenuItem>
              ))}
            </SelectInput>
            <CustomNumericFormat
              allowNegative={false}
              id="add-house-value"
              label="Estimert boligverdi"
              name={`${errorPreFixReportedProperty}${InputFieldsNameAddProperty.estimatedValue}`}
              thousandSeparator=" "
              customInput={TextInput}
              type="text"
              variant="outlined"
              slotProps={{
                htmlInput: {
                  maxLength: 10,
                  inputMode: "numeric",
                },
                input: {
                  endAdornment: (
                    <InputAdornment position="end">kr</InputAdornment>
                  ),
                },
              }}
              maxWidth="300px"
              value={property.estimatedValue}
              onValueChange={(values) => {
                setProperty({
                  ...property,
                  estimatedValue: values.floatValue,
                });
                dispatch(
                  removeError({
                    name: `${errorPreFixReportedProperty}${InputFieldsNameAddProperty.estimatedValue}`,
                    value: false,
                  })
                );
              }}
            />
            <CustomNumericFormat
              allowNegative={false}
              id="add-house-owner-share"
              label="Din andel"
              name={`${errorPreFixReportedProperty}${InputFieldsNameAddProperty.ownershipPercentage}`}
              thousandSeparator=" "
              customInput={TextInput}
              type="text"
              variant="outlined"
              slotProps={{
                htmlInput: {
                  maxLength: 3,
                  inputMode: "numeric",
                },
                input: {
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                },
              }}
              isAllowed={(values) => {
                const { formattedValue, floatValue } = values;
                return formattedValue === "" || floatValue <= 100;
              }}
              maxWidth="90px"
              value={property.ownershipPercentage}
              onValueChange={(values) => {
                setProperty({
                  ...property,
                  ownershipPercentage: values.floatValue,
                });
                dispatch(
                  removeError({
                    name: `${errorPreFixReportedProperty}${InputFieldsNameAddProperty.ownershipPercentage}`,
                    value: false,
                  })
                );
              }}
            />
            <TextInput
              id="add-house-electronic-evaluation-ref-number"
              label="E-takst (valgfri)"
              name={InputFieldsNameAddProperty.electronicEvaluationRefNumber}
              description="Referansekode fra verdivurdering, hvis du har fått takst på bolig."
              variant="outlined"
              type="text"
              maxWidth="300px"
              value={property.electronicEvaluationRefNumber}
              onChange={handleUpdate}
              slotProps={{
                htmlInput: {
                  maxLength: 50,
                },
              }}
            />
          </Stack>
          <Stack direction="row" gap="16px" justifyContent="flex-end">
            <Button variant="contained" color="light" onClick={handleClose}>
              Avbryt
            </Button>
            <Button variant="contained" color="dark" onClick={save}>
              Legg til bolig
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default AddHouseModal;
