import { validateNorwegianPhoneNumber } from "../stringValidation";

export const isValidPhoneValidation = (phone: string) => {
  const tempPhone = phone.replace(/\s+/g, "");

  if (tempPhone.length !== 8) {
    return false;
  }
  if (!validateNorwegianPhoneNumber(tempPhone)) {
    return false;
  }
  return true;
};
