import React, { useEffect } from "react";
import { Link, Stack, Typography } from "@mui/material";
import LoanFlowWrapper from "../../../components/layout/LoanFlowWrapper";
import PrivateRoute from "../../../components/soknad/PrivateRoute";
import COLORS from "../../../constants/colors";
import StatusViewContainerCardStack from "../../../components/soknad/status/StatusViewContainerCardStack";
import ActiveConsumerLoanView from "../../../components/soknad/status/ActiveConsumerLoanView";
import { useAppDispatch, useAppSelector } from "../../../hooks/reduxHooks";
import SEO from "../../../components/Seo";
import Loading from "../../../components/Loadings/Loading";
import {
  fetchSubmittedApplications,
  selectAnyOffersExist,
  selectCurrentlyViewedApplication,
  setActiveTabState,
} from "../../../redux/reducers/submittedApplications/submittedApplicationsReducer";
import ConsumerLoanRejectedView from "../../../components/soknad/status/ActiveConsumerLoanView/ConsumerLoanRejectedView";
import { PartnerSubmissionState } from "../../../services/nswag/clients/loanApplicationsClient";
import ConsumerLoanPaidOutView from "../../../components/soknad/status/ActiveConsumerLoanView/ConsumerLoanPaidOutView";
import LoanToggleButton from "../../../components/soknad/LoanToggleButton";
import AssistantBird from "../../../assets/svgs/assistantBird.svg";
import { getLoanType } from "../../../utils/loanTypeHandler";
import { SoknadType } from "../../../constants/soknadType";

export enum TabState {
  ConsumerLoan = 0,
  RestartLoan = 1,
}

export default function OfferPage() {
  const fetchState = useAppSelector(
    (state) => state.submittedApplications.fetchState
  );
  const openRestartLoanExist = useAppSelector(
    (state) => state.submittedApplications.openRestartExists
  );
  const application = useAppSelector(selectCurrentlyViewedApplication);
  const activeTabState = useAppSelector(
    (state) => state.submittedApplications.activeTabState
  );
  const anyOffersExist = useAppSelector(selectAnyOffersExist);
  const dispatch = useAppDispatch();
  const paidOut = application?.partners.some(
    (partner) => partner.state === PartnerSubmissionState.PaidOut
  );

  const loanType = getLoanType();
  // If we have not yet done the fetch, we do that at component load
  useEffect(() => {
    if (fetchState === undefined) {
      dispatch(fetchSubmittedApplications());
    }
  }, [fetchState]);

  const renderApplication = () => {
    if (application?.closed) {
      if (paidOut) {
        return <ConsumerLoanPaidOutView />;
      }
      return <ConsumerLoanRejectedView />;
    }

    return <ActiveConsumerLoanView application={application!} />;
  };

  const showRestartLoan = openRestartLoanExist;
  const showConsumerLoan =
    !!application &&
    !application?.partners?.some(
      (l) => l.state === PartnerSubmissionState.SubmissionCancelled
    );
  const hasBothLoans = showRestartLoan && showConsumerLoan;

  useEffect(() => {
    if (hasBothLoans) {
      if (loanType?.loanType === SoknadType.Restartloan) {
        dispatch(setActiveTabState(TabState.RestartLoan));
      } else {
        dispatch(setActiveTabState(TabState.ConsumerLoan));
      }
    } else if (showRestartLoan) {
      dispatch(setActiveTabState(TabState.RestartLoan));
    } else {
      dispatch(setActiveTabState(TabState.ConsumerLoan));
    }
  }, [openRestartLoanExist, application]);

  return (
    <PrivateRoute>
      <LoanFlowWrapper
        offerStepper={activeTabState === TabState.ConsumerLoan}
        showStepper={activeTabState === TabState.ConsumerLoan}
        showAmount={false}
        title="Status"
        activeOfferStep={anyOffersExist ? 2 : 1}
        bgcolor={{ xs: COLORS.TRUE_WHITE[100], md: COLORS.BRIGHT_BLUE[20] }}
        showCancelButton={!paidOut}
        gap="0px"
      >
        <StatusViewContainerCardStack>
          <Stack gap="32px">
            <Typography variant="h2Small" color={COLORS.DARK_BLUE[100]}>
              Dine Lånetilbud
            </Typography>
            <Loading loading={!fetchState || fetchState === "loading"}>
              {hasBothLoans && (
                <LoanToggleButton
                  active={activeTabState}
                  onClick={(v: 0 | 1) => {
                    dispatch(setActiveTabState(v));
                  }}
                />
              )}
              {activeTabState === TabState.ConsumerLoan && renderApplication()}
              {activeTabState === TabState.RestartLoan && (
                <Stack
                  gap="30px"
                  bgcolor={COLORS.BRIGHT_BLUE[20]}
                  borderRadius="12px"
                  alignItems="center"
                  p="40px"
                >
                  <Stack maxWidth="250px">
                    <AssistantBird width="100%" />
                  </Stack>
                  <Typography
                    variant="h3"
                    color={COLORS.DARK_BLUE[100]}
                    textAlign="center"
                  >
                    Vi har mottatt søknaden din
                  </Typography>

                  <Typography
                    variant="lead"
                    color={COLORS.DARK_BLUE[100]}
                    textAlign="center"
                  >
                    Neste steg er at du får en e-post fra Jostein eller Richard
                    i Horde. Lurer du på noe i mellomtiden, kan du ta kontakt
                    med oss på{" "}
                    <Link
                      style={{ fontSize: 22 }}
                      href="mailto:boliglaan@horde.no"
                      target="_blank"
                      rel="noreferrer"
                    >
                      boliglaan@horde.no
                    </Link>
                  </Typography>
                </Stack>
              )}
            </Loading>
          </Stack>
        </StatusViewContainerCardStack>
      </LoanFlowWrapper>
    </PrivateRoute>
  );
}

export function Head() {
  return (
    <SEO
      title="Søknad - Tilbud"
      description="Tilbudside som viser tilbud fra bankene"
    />
  );
}
