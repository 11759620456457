/* eslint-disable @typescript-eslint/ban-types */
import { Middleware, ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { HubConnection } from "@microsoft/signalr";
import createLoanApplicationHub from "./setupSignalR";
import { setLogin } from "../../reducers/customerReducer";
import { RootState, logout } from "../../store";
import { refreshSubmittedApplications } from "../../reducers/submittedApplications/submittedApplicationsReducer";
import { refreshAcceptedOffer } from "../../reducers/acceptedOfferReducer";

const webSocketListener: Middleware<
  {},
  RootState,
  ThunkDispatch<RootState, unknown, UnknownAction>
> = ({ dispatch }) => {
  let connection: HubConnection | undefined;

  const onLogin = () => {
    connection = createLoanApplicationHub();

    connection.on("LoanApplicationOfferUpdate", () => {
      console.info("LoanApplicationOfferUpdate");
      dispatch(refreshSubmittedApplications());
      dispatch(refreshAcceptedOffer());
    });

    connection
      .start()
      .then(() => console.info("SignalR Connected"))
      .catch((err) => console.error("SignalR Connnection Error: ", err));
  };

  const onLogOut = () => {
    connection?.stop();
  };

  return (next) => (action) => {
    if (setLogin.match(action)) onLogin();
    if (logout.match(action)) onLogOut();
    next(action);
  };
};

export default webSocketListener;
