import { Typography, useMediaQuery } from "@mui/material";
import React from "react";
import banks from "../../assets/bank-icons/no_banks";

interface Props {
  isSmall?: boolean;
  bankName: string;
  fontSize?: number;
  height?: string;
  width?: string;
}

const BankLogo = ({
  isSmall,
  height,
  bankName,
  width,
  fontSize,
}: Props): any => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const imageSize = (bank: any) => {
    if (isSmall) {
      return bank.path;
    }
    return bank.largeImagePath ?? bank.path;
  };

  for (const bank of banks) {
    if (bankName?.toUpperCase().startsWith(bank?.ref.toUpperCase())) {
      return (
        <img
          alt="Bank logo"
          style={{
            width: width || "100%",
            height: height || "100%",
            objectFit: "contain", // Use 'cover' if you prefer the image to fill the container
            margin: 0,
            padding: 0,
          }}
          src={imageSize(bank)}
        />
      );
    }
  }

  return (
    <Typography
      sx={{
        backgroundColor: "grey",
        textAlign: "center",
        color: "white",
        borderRadius: "100px",
        height: "28px",
        width: "28px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "600",
      }}
    >
      {bankName ? bankName.charAt(0).toUpperCase() : "B"}
    </Typography>
  );
};

export default BankLogo;
