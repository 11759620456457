import { Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputFieldsCoApplicant } from "../../../../constants/inputFieldNamesSoknad";
import { MaritalStatusType } from "../../../../services/nswag/clients/loanApplicationsClient";
import {
  getLoanApplicationForm,
  updateLoanApplicationCoApplicant,
} from "../../../../redux/reducers/loanApplicationReducer";
import useCoApplicant from "../../../../hooks/useCoApplicant";
import OwnsOrRentsButtons from "./OwnsOrRentsButtons";
import RentExpenseInput from "./RentExpenseInput";
import MortgageSection from "./MortgageSection";
// import RentIncomeSection from "./RentIncomeSection";

const CoApplicantHousing = () => {
  const dispatch = useDispatch();
  const { applicant } = useSelector(getLoanApplicationForm);
  const { coApplicant } = useCoApplicant();

  useEffect(() => {
    dispatch(
      updateLoanApplicationCoApplicant({
        name: InputFieldsCoApplicant.livesWithMainApplicant,
        value:
          applicant.maritalStatus === MaritalStatusType.Married ||
          applicant.maritalStatus === MaritalStatusType.Cohabitant,
        index: 0,
      })
    );
    if (!coApplicant?.yearlyGrossIncome) {
      if (
        applicant.maritalStatus === MaritalStatusType.Married ||
        applicant.maritalStatus === MaritalStatusType.Cohabitant
      ) {
        dispatch(
          updateLoanApplicationCoApplicant({
            name: InputFieldsCoApplicant.yearlyGrossIncome,
            value: applicant.spouseGrossYearlyIncome,
            index: 0,
          })
        );
      }
    }
  }, [applicant?.maritalStatus, coApplicant?.yearlyGrossIncome]);

  return (
    <>
      <OwnsOrRentsButtons />

      <Stack gap="16px">
        <RentExpenseInput />
        <MortgageSection />
        {/* <RentIncomeSection /> */}
      </Stack>
    </>
  );
};

export default CoApplicantHousing;
