import { Stack, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../../../../../constants/colors";
import { OfferQualityTranslation } from "../../../../../../utils/emunTranslation";
import { OfferQuality } from "../../../../../../services/nswag/clients/loanApplicationsClient";

export default function QualityTagOverlay({
  quality,
}: {
  quality: OfferQuality;
}) {
  if (quality === OfferQuality.None) return false;

  return (
    <Stack position="absolute" top="-22px">
      <Stack
        bgcolor={COLORS.BRIGHT_BLUE[100]}
        borderRadius="40px"
        px="24px"
        py="8px"
      >
        <Typography
          variant="body"
          color="#FFF"
          fontSize={{ xs: "14px", md: "18px" }}
        >
          {OfferQualityTranslation(quality)}
        </Typography>
      </Stack>
    </Stack>
  );
}
