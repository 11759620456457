import {
  EducationType,
  EmploymentType,
  HasCarLoan,
  HousingSituation,
  LoanPurpose,
  MaritalStatusType,
  OfferQuality,
  PartnerProductType,
  PropertyType,
} from "../services/nswag/clients/loanApplicationsClient";

export const PurposeEnumTranslation = (purpose: LoanPurpose) => {
  switch (purpose) {
    case LoanPurpose.BoatCaravan:
      return "Andre kjøretøy";
    case LoanPurpose.Renovation:
      return "Oppussing";
    case LoanPurpose.TravelLeisure:
      return "Reise og fritid";
    case LoanPurpose.Bills:
      return "Regninger";
    case LoanPurpose.Medical:
      return "Helse";
    case LoanPurpose.Reparation:
      return "Reparasjon";
    case LoanPurpose.Car:
      return "Bil og MC";
    case LoanPurpose.Others:
      return "Noe annet";
    default:
      return "";
  }
};

export const EducationTypeTranslation = (education?: EducationType) => {
  switch (education) {
    case EducationType.ElementarySchool:
      return "Grunnskole";
    case EducationType.UpperSecondarySchool:
      return "Videregående";
    case EducationType.Fagskole:
      return "Fagskole";
    case EducationType.UniversitetHøgskole:
      return "Universitets- og høgskolenivå";
    default:
      return "";
  }
};

export const MaritalStatusTypeTranslation = (status?: MaritalStatusType) => {
  switch (status) {
    case MaritalStatusType.Single:
      return "Ugift";
    case MaritalStatusType.Married:
      return "Gift";
    case MaritalStatusType.Cohabitant:
      return "Samboer";
    case MaritalStatusType.Separated:
      return "Separert";
    case MaritalStatusType.Divorced:
      return "Skilt";
    case MaritalStatusType.Widow:
      return "Enke/Enkemann";
    default:
      return "";
  }
};

export const EmploymentTypeTranslation = (employment: EmploymentType) => {
  switch (employment) {
    case EmploymentType.FullTimePrivate:
      return "Fast ansatt";
    case EmploymentType.Student:
      return "Student";
    case EmploymentType.TemporarilyLaidOff:
      return "Permittert";
    case EmploymentType.DisabilityAid:
      return "Uførepensjon";
    case EmploymentType.TemporaryEmployment:
      return "Midlertidig ansatt";
    case EmploymentType.SoleProprietorship:
      return "Selvstendig næringsdrivende";
    case EmploymentType.Rehabilitation:
      return "Rehabilitering";
    case EmploymentType.Retired:
      return "Pensjonist";
    case EmploymentType.Others:
      return "Annet";
    default:
      return "";
  }
};

export const OfferQualityTranslation = (status: OfferQuality) => {
  switch (status) {
    case OfferQuality.LowestCostPerPeriod:
      return "Lavest månedsbeløp";
    case OfferQuality.LowestEffectiveInterestRate:
      return "Lavest rente";
    case OfferQuality.LowestCostAndLowestEffectiveRate:
      return "Lavest rente og månedsbeløp";
    case OfferQuality.None:
      return "";
    default:
      return "";
  }
};

export const PropertyTypeTranslation = (status: PropertyType) => {
  switch (status) {
    case PropertyType.House:
      return "Enebolig";
    case PropertyType.Apartment:
      return "Leilighet";
    case PropertyType.HolidayHome:
      return "Hytte/feriebolig";
    case PropertyType.SemiDetachedHouse:
      return "Tomannsbolig";
    case PropertyType.SerialHouse:
      return "Rekkehus";
    default:
      return "";
  }
};

export const HousingSituationTypeTranslation = (status: HousingSituation) => {
  switch (status) {
    case HousingSituation.OwnsHouse:
      return "Eier";
    case HousingSituation.Rents:
      return "Leier";
    default:
      return "Ukjent";
  }
};

export const HasCarLoanTypeTranslation = (hasCarLoan?: HasCarLoan) => {
  switch (hasCarLoan) {
    case HasCarLoan.Yes:
      return "Ja";
    case HasCarLoan.No:
      return "Nei";
    case HasCarLoan.Leasing:
      return "Leasing";
    default:
      return "Ukjent";
  }
};

export const MaritalStatusTypeTranslationSpouseIncome = (
  status?: MaritalStatusType
) => {
  switch (status) {
    case MaritalStatusType.Cohabitant:
      return "Samboers";
    default:
      return "Ektefelles";
  }
};

export const PartnerProductTypeTranslation = (status?: PartnerProductType) => {
  switch (status) {
    case PartnerProductType.ConsumerLoan:
      return "Forbrukslån";
    case PartnerProductType.RestartLoan:
      return "Lån med sikkerhet i bolig";
    default:
      return "";
  }
};
