import {
  Alert,
  Button,
  ButtonProps,
  Stack,
  StackOwnProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React, { useCallback, useEffect, useId, useRef } from "react";
import { useSelector } from "react-redux";
import COLORS from "../constants/colors";
import { getLoanApplication } from "../redux/reducers/loanApplicationReducer";

interface ToggleButtonProps<T> extends ButtonProps {
  label: string;
  toggleValue: T; // Endret navn her
  isActive?: boolean;
  onToggleClick: (value: T) => void;
}

/**
 * A toggle button that changes appearance when active.
 */
const ToggleButtonComponent = <T,>({
  label,
  toggleValue, // Endret navn her
  isActive = false,
  onToggleClick,
  ...rest
}: ToggleButtonProps<T>) => {
  const handleClick = () => {
    onToggleClick(toggleValue);
  };

  return (
    <Button
      onClick={handleClick}
      variant="outlinedToggle"
      className={isActive ? "isActive" : ""}
      {...rest}
    >
      <Typography>{label}</Typography>
    </Button>
  );
};

const ToggleButton = React.memo(
  ToggleButtonComponent
) as typeof ToggleButtonComponent;

interface ButtonItem<T> {
  label: string;
  value: T;
  newLine?: boolean;
}

interface ButtonToggleGroupProps<T> extends StackOwnProps {
  description?: string;
  descriptionColor?: string;
  label?: string;
  labelVariant?: TypographyProps["variant"];
  labelColor?: string;
  buttons: ButtonItem<T>[];
  onSelectedChange: (value: T) => void;
  value: any;
  error?: boolean;
  errorMessage?: string;
  name?: string;
}

/**
 * A component that displays a group of toggle buttons where only one can be active at a time.
 */
const ButtonToggleGroup = <T,>(props: ButtonToggleGroupProps<T>) => {
  const groupId = useId();
  const { errorHandling } = useSelector(getLoanApplication);
  const {
    description,
    label,
    labelVariant = "inputLabel",
    descriptionColor = COLORS.NORMAL[100],
    labelColor = COLORS.NORMAL[100],
    buttons,
    onSelectedChange,
    value,
    error,
    errorMessage,
    name,
  } = props;

  const hasError = error ?? errorHandling[name];
  // Memoize the handler to prevent unnecessary re-renders
  const handleButtonPress = useCallback(
    (v: T) => {
      onSelectedChange(v);
    },
    [onSelectedChange]
  );
  const alertRef = useRef(null as any);

  const createErrorMessage = () => {
    if (errorMessage) {
      return errorMessage;
    }
    if (name) {
      return "Velg et alternativ";
    }
  };

  useEffect(() => {
    if (hasError && alertRef.current) {
      alertRef.current.focus();
    }
  }, [hasError]);

  return (
    <Stack gap="8px" {...props} className={hasError && "hasValidationError"}>
      <Stack gap="2px">
        {label && (
          <Typography
            id={`${groupId}-label`}
            component="label"
            variant={labelVariant}
            color={labelColor}
          >
            {label}
          </Typography>
        )}
        {description && (
          <Typography
            variant="inputDescription"
            color={descriptionColor}
            maxWidth="337px"
          >
            {description}
          </Typography>
        )}
      </Stack>
      <Stack
        role="group"
        aria-labelledby={label ? `${groupId}-label` : undefined}
        direction="row"
        gap="8px"
        sx={{
          flexWrap: "wrap", // Enable wrapping to a new line
          justifyContent: "flex-start", // Optional: Adjust alignment
        }}
      >
        {buttons.map((button) => {
          return (
            <ToggleButton
              key={String(button.value)}
              label={button.label}
              toggleValue={button.value} // Endret navn her
              isActive={value === button.value}
              onToggleClick={handleButtonPress}
            />
          );
        })}
      </Stack>
      {hasError && (
        <Alert
          severity="error"
          role="alert"
          aria-live="assertive"
          tabIndex={-1}
          ref={alertRef}
        >
          {createErrorMessage()}
        </Alert>
      )}
    </Stack>
  );
};

export default ButtonToggleGroup;
