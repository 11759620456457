import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";
import {
  getLoanApplicationForm,
  refreshErrorHandlingStatus,
  refreshWarningHandlingStatus,
  updateErrorHandling,
  updateLoanApplication,
  updateLoanApplicationCoApplicant,
  UpdateLoanApplicationCoApplicant,
} from "../redux/reducers/loanApplicationReducer";
import { errorPreFixNamingCoApplicant } from "../pages/soknad/forbrukslan/medlantaker";
import {
  InputFieldsCoApplicant,
  InputFieldsNameCivilStatus,
} from "../constants/inputFieldNamesSoknad";
import { MedlantakerValidationError } from "../utils/validationBeforeNextUtils";

import { getCustomer } from "../redux/reducers/customerReducer";
import { actionSaveCachedLoanApplication } from "../redux/actions/loanApplicationAction";
import { goOneStepForward } from "../redux/reducers/stepsReducer";
import useStepper from "./useStepper";
import {
  PartnerProductType,
  SelfReportedPaymentRemarks,
} from "../services/nswag/clients/loanApplicationsClient";

function useCoApplicant() {
  const { getNextPage } = useStepper();
  const { coApplicants, applicant, product } = useSelector(
    getLoanApplicationForm
  );
  const coApplicant = coApplicants[0];
  const dispatch = useDispatch();
  const { ssn } = useSelector(getCustomer);
  const handleUpdate = (event: any) => {
    const update = {
      name: event.target.name.replace(errorPreFixNamingCoApplicant, ""),
      value: event.target.value,
      index: 0,
      errorHandling: event.target.errorHandling ?? [],
    } as UpdateLoanApplicationCoApplicant;

    dispatch(updateLoanApplicationCoApplicant(update));
  };

  const validateNames = Object.keys(InputFieldsCoApplicant).map(
    (item) => `${errorPreFixNamingCoApplicant}${item}`
  );

  const validateBeforeNext = () => {
    if (
      coApplicant.hasPaymentRemarks === SelfReportedPaymentRemarks.Yes &&
      product === PartnerProductType.ConsumerLoan
    ) {
      return true;
    }

    const errors = MedlantakerValidationError(coApplicant, ssn, applicant);

    if (errors.length > 0) {
      dispatch(updateErrorHandling(errors));

      setTimeout(() => {
        const elements = document.getElementsByClassName("hasValidationError");

        if (elements[0]) {
          window.scrollTo({
            top: elements[0].getBoundingClientRect().top + window.scrollY,
            behavior: "smooth",
          });
        }
      }, 200);

      return false;
    }
    return errors.length === 0;
  };

  const continueWithoutCoApplicant = () => {
    dispatch(
      updateLoanApplication({
        name: InputFieldsNameCivilStatus.coApplicantIncluded,
        value: false,
      })
    );
    dispatch(
      actionSaveCachedLoanApplication(() => {
        dispatch(goOneStepForward(true));
        if (validateNames) {
          dispatch(refreshWarningHandlingStatus(validateNames));
          dispatch(refreshErrorHandlingStatus(validateNames));
        }
      }, getNextPage())
    );
  };
  return {
    validateBeforeNext,
    coApplicant,
    handleUpdate,
    validateNames,
    ssn,
    continueWithoutCoApplicant,
  };
}

export default useCoApplicant;
