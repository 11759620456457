import { Box, Modal, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import AssistantBird from "../../../../../assets/svgs/assistantBird.svg";
import COLORS from "../../../../../constants/colors";
import DownloadAppSection from "../../../stepper/DownloadAppSection";
import ExpandableExplanationWithSearchAgainButton from "./ExpandableExplanationWithSearchAgainButton";
import {
  applyAgainForConsumerLoan,
  applyAgainForConsumerLoanWithPrefill,
} from "../../../../../redux/actions/loanApplicationAction";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/reduxHooks";
import { LoanApplicationSubmissionFormDto } from "../../../../../services/nswag/clients/loanApplicationsClient";
import { GetLoanApplicationClient } from "../../../../../services/axiosInstance";
import PrefillApplicationPromptCard from "../../PrefillApplicationPromptCard";
import { selectCurrentlyViewedApplication } from "../../../../../redux/reducers/submittedApplications/submittedApplicationsReducer";

export interface IProps {}
export default function ConsumerLoanRejectedView() {
  const dispatch = useAppDispatch();
  const application = useAppSelector(selectCurrentlyViewedApplication);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [prefilledForm, setPrefilledForm] = useState<
    LoanApplicationSubmissionFormDto | undefined
  >(undefined);

  const appliedWithCoApplicant = application?.appliedWithCoApplicant;

  const onSearchAgain = (includeCoApplicant?: boolean) => {
    if (!prefilledForm) {
      GetLoanApplicationClient()
        .getLastSubmittedForm()
        .then((res) => {
          let prefilled = res;
          if (includeCoApplicant) {
            prefilled = {
              ...prefilled,
              coApplicantIncluded: true,
            };
          }
          setPrefilledForm(prefilled);
          setShowConfirmModal(true);
        })
        .catch(() => {
          dispatch(applyAgainForConsumerLoan(includeCoApplicant));
        });
    } else {
      setShowConfirmModal(true);
    }
  };

  const onConfirmModalClose = () => {
    setShowConfirmModal(false);
  };

  const onConfirmPrefill = () => {
    if (prefilledForm) {
      let prefilled = prefilledForm;
      if (!appliedWithCoApplicant) {
        prefilled = {
          ...prefilled,
          coApplicantIncluded: true,
        };
      }

      dispatch(applyAgainForConsumerLoanWithPrefill(prefilled));
    } else {
      dispatch(applyAgainForConsumerLoan(!appliedWithCoApplicant));
    }
  };

  const onDenyPrefill = () => {
    dispatch(applyAgainForConsumerLoan(!appliedWithCoApplicant));
  };

  return (
    <>
      <Stack gap="32px">
        <Stack
          bgcolor={COLORS.BRIGHT_BLUE[20]}
          borderRadius="12px"
          p={{ md: "88px 96px 88px 96px", xs: "25px" }}
          gap="32px"
          alignItems="center"
        >
          <AssistantBird width="100%" />

          <Typography
            variant="h3"
            color={COLORS.DARK_BLUE[100]}
            textAlign="center"
          >
            Søknaden din ble dessverre avslått av bankene
          </Typography>
          <Typography
            variant="lead"
            color={COLORS.DARK_BLUE[100]}
            textAlign="center"
          >
            Vi vet dessverre ikke grunnen til avslag. Sjekk de vanligste
            grunnene her:
          </Typography>
          <ExpandableExplanationWithSearchAgainButton
            onSearchAgain={() => {
              onSearchAgain(false);
            }}
          />
        </Stack>
        <DownloadAppSection alignItems="flex-start" />
      </Stack>
      <Modal open={showConfirmModal} onClose={onConfirmModalClose}>
        <Box
          position="absolute"
          top="25%"
          left="50%"
          width="100%"
          maxWidth="600px"
          sx={{
            transform: "translate(-50%, -25%)",
          }}
        >
          <Box mx={{ xs: "15px", sm: "0px" }}>
            <PrefillApplicationPromptCard
              onConfirm={onConfirmPrefill}
              onDeny={onDenyPrefill}
              onClose={onConfirmModalClose}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
