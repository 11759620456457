import {
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../constants/colors";
import {
  getLoanApplication,
  updateErrorHandling,
} from "../redux/reducers/loanApplicationReducer";
import { isValidPhoneValidation } from "../utils/validation/phoneValidation";
import { emailValidation } from "../utils/warningValidationUtils";

// Define the props for the custom input component
type CustomProps = TextFieldProps & {
  description?: string;
  descriptionColor?: string;
  label?: string;
  labelColor?: string;
  maxWidth?: string;
  labelVariant?: TypographyProps["variant"];
  warningText?: string;
  isWarning?: boolean;
  name?: string;
  errorText?: string;
  maxWidthInput?: string;
};

// Create a custom input component that uses TextField
const TextInput: React.FC<CustomProps> = ({
  maxWidth,
  description,
  label,
  descriptionColor = COLORS.NORMAL[100],
  labelColor = COLORS.NORMAL[100],
  labelVariant = "inputLabel",
  warningText = "Sjekk at beløpet stemmer",
  errorText = "Feltet er påkrevd",
  maxWidthInput,
  ...rest
}) => {
  const { errorHandling, warningHandling } = useSelector(getLoanApplication);
  const hasError = rest.error ?? errorHandling[rest.name];
  const dispatch = useDispatch();
  const errorTextHandler = () => {
    if (rest.error ?? errorHandling[rest.name]) {
      if (errorText) {
        return errorText;
      }
      if (rest.helperText) {
        return rest.helperText;
      }
    }
    if (!errorHandling[rest.name] && warningHandling[rest.name]) {
      if (warningText) {
        return warningText;
      }
    }
  };

  const showErrorHandling = () => {
    if (rest.error ?? errorHandling[rest.name]) {
      return true;
    }

    if (!errorHandling[rest.name] && warningHandling[rest.name]) {
      return true;
    }

    return false;
  };

  return (
    <Stack
      gap="4px"
      sx={{ maxWidth }}
      className={hasError && "hasValidationError"}
    >
      {/* Render the description between label and input */}
      <Stack gap="2px">
        {label && (
          <Typography
            aria-hidden
            component="label"
            variant={labelVariant}
            color={labelColor}
            htmlFor={rest.id || rest.name}
          >
            {label}
          </Typography>
        )}
        {description && (
          <Typography variant="inputDescription" color={descriptionColor}>
            {description}
          </Typography>
        )}
      </Stack>
      <TextField
        aria-label={label}
        {...rest}
        sx={
          !errorHandling[rest.name] && warningHandling[rest.name]
            ? {
                "& .MuiOutlinedInput-root": {
                  "&.Mui-error": {
                    border: `solid 2px ${COLORS.DARK_BLUE[100]}`,
                    "& .MuiInputAdornment-root p": {
                      color: COLORS.DARK_BLUE[100],
                    },
                  },
                  "&.Mui-focused": {
                    "&.Mui-error": {
                      outline: `1px solid ${COLORS.DARK_BLUE[100]}`,
                    },
                    outline: `1px solid ${COLORS.NORMAL[100]}`,
                  },
                },
                maxWidth: maxWidthInput,
              }
            : {
                maxWidth: maxWidthInput,
              }
        }
        onBlur={() => {
          if (rest?.type === "tel") {
            const validate = isValidPhoneValidation(rest?.value);
            if (!validate) {
              dispatch(
                updateErrorHandling([
                  {
                    name: rest.name,
                    value: true,
                  },
                ])
              );
            }
          }
          if (rest?.type === "email") {
            const validate = emailValidation(rest?.value);
            if (!validate) {
              dispatch(
                updateErrorHandling([
                  {
                    name: rest.name,
                    value: true,
                  },
                ])
              );
            }
          }
          if (rest?.onBlur) {
            try {
              rest.onBlur();
            } catch (error) {
              console.error("Error:", error);
            }
          }
        }}
        error={showErrorHandling()}
        helperText={errorTextHandler()}
      />
    </Stack>
  );
};

export default TextInput;
