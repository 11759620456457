import {
  ThemeOptions,
  alpha,
  createTheme,
  getContrastRatio,
  responsiveFontSizes,
} from "@mui/material/styles";
import { nbNO } from "@mui/material/locale";
import { Variant } from "@mui/material/styles/createTypography";
import COLORS from "../constants/colors";
import { InterFontList } from "./utils";
import {
  customTypographyVariantsNameList,
  customTypographyVariants,
} from "./components/Typography";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    outlinedToggle: true;
    containedCircle: true;
    outlinedLight: true;
  }
}

declare module "@mui/material/styles" {
  // Extend the Palette interface with our custom palette.
  interface Palette {
    light: Palette["primary"];
    dark: Palette["primary"];
    black: Palette["primary"];
    blue: Palette["primary"];
    menu: Palette["primary"];
    lightOnDark: Palette["primary"];
  }

  // Extend the PaletteOptions interface with our custom palette.
  interface PaletteOptions {
    light?: PaletteOptions["primary"];
    dark?: PaletteOptions["primary"];
    black?: PaletteOptions["primary"];
    blue?: PaletteOptions["primary"];
    menu?: PaletteOptions["primary"];
    lightOnDark: PaletteOptions["primary"];
  }
}

/**
 * We are extending the Button component's props to include our custom variants.
 * This tells TypeScript that our custom variants are valid values for the "variant" prop.
 */
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    light: true;
    dark: true;
    black: true;
    blue: true;
    menu: true;
    lightOnDark: true;
  }
}
declare module "@mui/material/Link" {
  interface LinkPropsColorOverrides {
    light: true;
    dark: true;
    black: true;
    blue: true;
    menu: true;
    lightOnDark: true;
  }
}
/**
 * Global theme used for easy access to the palette.
 */
const globalTheme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 910,
        lg: 1235,
        xl: 1536,
      },
    },
    typography: {
      fontFamily: InterFontList,
      htmlFontSize: 16,
      fontSize: 16,
      lineHeight: 24,
      h1: {
        fontFamily: "Erlik",
        fontStyle: "normal",
        fontWeight: 900,
        fontSize: 54,
        lineHeight: 1.1,
      },
      h2: {
        fontFamily: "Erlik",
        fontWeight: 800,
        fontSize: 41,
        lineHeight: 1.25,
      },
      h3: {
        fontFamily: InterFontList,
        fontWeight: 700,
        fontSize: 33,
        lineHeight: 1.25,
      },
      h4: {
        fontFamily: InterFontList,
        fontWeight: 700,
        fontStyle: "normal",
        fontSize: 24,
        lineHeight: 1.35,
      },
      button: {
        fontFamily: InterFontList,
        fontWeight: 600,
        fontSize: 18,
        lineHeight: 1.2,
        fontStyle: "normal",
      },
      body1: {
        fontFamily: InterFontList,
        fontWeight: 400,
        fontSize: 18,
        lineHeight: 1.6,
      },
      ...customTypographyVariants,
      // Go to src/styles/components/Typography.ts to add custom typography variants.
    },
    palette: {
      contrastThreshold: 4.5, // default = 3
      background: {
        default: COLORS.BLACK[100],
      },
      text: {
        primary: COLORS.WHITE[100],
      },
      primary: {
        main: COLORS.DARK_BLUE[100],
      },
      secondary: {
        main: "#F28E86",
        contrastText: COLORS.BLACK[100],
      },
      light: {
        main: COLORS.WHITE[100],
        light: alpha(COLORS.WHITE[100], 0.5),
        dark: alpha(COLORS.WHITE[100], 0.9),
        contrastText:
          getContrastRatio(COLORS.WHITE[100], "#fff") > 4.5 ? "#fff" : "#111",
      },
      dark: {
        main: "#000",
        light: alpha(COLORS.BLACK[100], 0.5),
        dark: alpha(COLORS.BLACK[100], 0.9),
        contrastText: getContrastRatio("#000", "#fff") > 4.5 ? "#fff" : "#111",
      },
      black: {
        main: COLORS.BLACK[100],
        light: alpha(COLORS.BLACK[100], 0.5),
        dark: alpha(COLORS.BLACK[100], 0.9),
        darkBlue: COLORS.DARK_BLUE[100],
        contrastText:
          getContrastRatio(COLORS.BLACK[100], "#fff") > 4.5 ? "#fff" : "#111",
      },
      blue: {
        main: COLORS.BLUE[100],
        light: alpha(COLORS.BLUE[100], 0.5),
        dark: alpha(COLORS.BLUE[100], 0.9),
        contrastText:
          getContrastRatio(COLORS.BLUE[100], "#fff") > 4.5 ? "#fff" : "#111",
      },
      menu: {
        main: "#F28E86",
        contrastText: COLORS.BLACK[100],
      },
      lightOnDark: {
        main: COLORS.WHITE[100],
        light: alpha(COLORS.WHITE[100], 0.5),
        dark: alpha(COLORS.WHITE[100], 0.9),
        contrastText:
          getContrastRatio(COLORS.WHITE[100], "#fff") > 4.5 ? "#fff" : "#111",
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: `
          @font-face {
            font-family: "Inter";
            src: local("Inter"), url(/fonts/Inter/InterVariable.woff2) format("woff2 supports variations");
            font-style: normal;
            font-display: swap;
            speak: none;
            font-style: normal;
            font-weight: 100 900;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
          }
          @font-face {
            font-family: "Inter";
            src: local("Inter"), url(/fonts/Inter/InterVariable.woff2) format("woff2-variations");
            font-style: normal;
            font-display: swap;
            speak: none;
            font-weight: 100 900;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
          }
  
          @font-face {
            font-family: "Erlik";
            src: local("Erlik"),
              url(/fonts/Erlik/Erlik-Display.woff2) format("woff2");
            font-weight: 900;
            font-display: swap;
            speak: none;
            font-style: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
          }
  
          @font-face {
            font-family: "Biro";
            src: local("Biro"),
              url(/fonts/Biro/Biro-Regular.otf) format("opentype");
            font-weight: 400;
            font-display: swap;
            speak: none;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
          }
          `,
          body: {
            minHeight: "100vh",
            textRendering: "optimizeLegibility",
            "-webkit-font-smoothing": "subpixel-antialiased",
            "-moz-osx-font-smoothing": "grayscale",
            "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
              {
                "-webkit-appearance": "none",
                margin: 0,
              },
            "input[type=number]": {
              "-moz-appearance": "textfield",
            },
            "#ot-sdk-btn-floating": {
              display: "none",
            },
          },
          main: {
            backgroundColor: COLORS.DARK_BLUE[100],
          },
          article: {
            backgroundColor: "#FFFFFF",
            color: COLORS.BLACK[100],
          },
          u: {
            textDecoration: "underline",
            textUnderlineOffset: "4px",
            textDecorationThickness: "1px",
          },
          ul: {
            "&.ul-space": {
              "li:not(:last-child)": {
                marginBottom: "12px",
              },
            },
            li: {
              "&::marker": {
                color: COLORS.DARK_BLUE[100],
              },
            },
          },
        },
      },
    },
  } as ThemeOptions,
  nbNO
);

// Create our custom theme.
const hordeTheme = responsiveFontSizes(
  createTheme(
    globalTheme,
    {
      components: {
        MuiCard: {
          styleOverrides: {
            root: {
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxShadow: "none",
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              backgroundColor: globalTheme.palette.primary.main,
              "& nav": {
                alignItems: "center",
                "& p": {
                  "&.MuiLink-underlineAlways:hover, :hover, [aria-current=true]:hover":
                    {
                      cursor: "pointer",
                    },
                },
                "& a, p": {
                  color: globalTheme.palette.text.primary,
                  padding: "3px 8px",
                  fontSize: 16,
                  fontWeight: 500,
                  textAlign: "center",
                  textDecoration: "none",

                  "&.MuiLink-underlineAlways:focus-visible": {
                    color: "inherit",
                    backgroundColor: "transparent",
                    textDecoration: "none",
                    outline: `solid 1px ${globalTheme.palette.primary.contrastText}`,
                  },
                  "&.Mui-focusVisible": {
                    color: globalTheme.palette.secondary.main,
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    borderRadius: 4,
                    textDecoration: "none",
                    outline: "none",
                  },
                  "&[aria-current=true]": {
                    color: globalTheme.palette.secondary.main,
                    textDecoration: "none",
                  },
                  "&.MuiLink-underlineAlways:hover, :hover, [aria-current=true]:hover":
                    {
                      color: globalTheme.palette.primary.contrastText,
                      backgroundColor: "transparent",
                      textDecoration: "underline",
                      textDecorationThickness: "2px",
                      textUnderlineOffset: "6px",
                      outline: "none",
                    },
                },
              },
              "& .MuiToolbar-root": {
                display: "flex",
                alignItems: "center",
              },
              "& svg path": {
                fill: globalTheme.palette.text.primary,
              },
            },
          },
        },
        MuiLink: {
          defaultProps: {
            variant: "body",
            color: "primary",
            style: {
              cursor: "pointer",
            },
          },
          variants: [
            {
              props: { color: "primary" },
              style: {
                color: globalTheme.palette.primary.main,
                "& svg": {
                  fill: globalTheme.palette.primary.main,
                },
                "&:hover": {
                  backgroundColor: globalTheme.palette.primary.main,
                  color: globalTheme.palette.primary.contrastText,
                  "& svg": {
                    fill: globalTheme.palette.primary.contrastText,
                  },
                },
              },
            },
            {
              props: { color: "secondary" },
              style: {
                color: globalTheme.palette.text.primary,
                "& svg": {
                  fill: globalTheme.palette.text.primary,
                },
                "&:hover": {
                  backgroundColor: globalTheme.palette.text.primary,
                  color: globalTheme.palette.primary.main,
                  "& svg": {
                    fill: globalTheme.palette.primary.main,
                  },
                },
              },
            },
            {
              props: { color: "blue.main" },
              style: {
                color: globalTheme.palette.blue.main,
                "& svg": {
                  fill: globalTheme.palette.blue.main,
                },
                "&:hover": {
                  backgroundColor: globalTheme.palette.text.primary,
                  color: globalTheme.palette.primary.main,
                  "& svg": {
                    fill: globalTheme.palette.primary.main,
                  },
                  cursor: "pointer",
                },
              },
            },
          ],
          styleOverrides: {
            root: {
              display: "inline-flex",
              flexShrink: 1,
              padding: "0.25rem 0.38rem",
              borderRadius: "0.25rem",
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              textDecorationThickness: "2px",
              gap: "4px",
              "& svg": {
                transition: "none",
              },
            },
          },
        },
        MuiButton: {
          defaultProps: {
            variant: "contained",
            disableFocusRipple: true,
          },
          variants: [
            {
              props: { variant: "outlinedToggle" },
              style: {
                padding: "8px 18px",
                fontSize: "14px",
                border: `2px solid ${globalTheme.palette.primary.main}`,
                color: globalTheme.palette.primary.main,
                backgroundColor: "#FFFFFF",
                "&.isActive": {
                  backgroundColor: globalTheme.palette.primary.main,
                  color: globalTheme.palette.primary.contrastText,
                },
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.primary.main}`,
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "outlinedLight" },
              style: {
                border: `2px solid ${globalTheme.palette.primary.main}`,
                color: globalTheme.palette.primary.main,
                backgroundColor: "#FFFFFF",
                "&.isActive": {
                  backgroundColor: globalTheme.palette.primary.main,
                  color: globalTheme.palette.primary.contrastText,
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "contained", color: "secondary" },
              style: {
                border: `2px solid ${globalTheme.palette.primary.main}`,
                backgroundColor: globalTheme.palette.secondary.main,
                color: globalTheme.palette.secondary.contrastText,
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.secondary.contrastText}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "outlined", color: "secondary" },
              style: {
                border: `2px solid ${globalTheme.palette.secondary.main}`,
                color: globalTheme.palette.primary.contrastText,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.secondary.main}`,
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.secondary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "outlined", color: "primary" },
              style: {
                border: `2px solid ${globalTheme.palette.primary.main}`,
                color: globalTheme.palette.primary.main,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.primary.main}`,
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "containedCircle", color: "dark" },
              style: {
                borderRadius: "36px",
                border: `2px solid ${globalTheme.palette.primary.main}`,
                color: globalTheme.palette.light.main,
                "& svg": {
                  fill: globalTheme.palette.light.main,
                  marginRight: "8px",
                },
                backgroundColor: globalTheme.palette.primary.main,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.primary.main}`,
                  color: globalTheme.palette.primary.main,
                  backgroundColor: globalTheme.palette.light.main,
                  transition: "transform 1s ease",
                  "& svg": {
                    fill: globalTheme.palette.primary.main,
                  },
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "containedCircle", color: "light" },
              style: {
                borderRadius: "36px",
                border: `2px solid ${globalTheme.palette.primary.main}`,
                color: globalTheme.palette.primary.main,
                backgroundColor: globalTheme.palette.light.main,
                "& svg": {
                  fill: globalTheme.palette.primary.main,
                  marginRight: "8px",
                },
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.primary.main}`,
                  color: globalTheme.palette.light.main,
                  backgroundColor: globalTheme.palette.primary.main,
                  transition: "transform 1s ease",
                  "& svg": {
                    fill: globalTheme.palette.light.main,
                  },
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "contained", color: "primary" },
              style: {
                backgroundColor: globalTheme.palette.primary.contrastText,
                color: globalTheme.palette.primary.main,
                "&:hover": {
                  backgroundColor: globalTheme.palette.primary.contrastText,
                  color: globalTheme.palette.primary.main,
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.contrastText}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "outlined", color: "light" },
              style: {
                border: `2px solid ${globalTheme.palette.light.main}`,
                color: globalTheme.palette.light.main,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.light.main}`,
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.light.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "contained", color: "light" },
              style: {
                border: `2px solid ${globalTheme.palette.primary.main}`,
                color: globalTheme.palette.primary.main,
                backgroundColor: globalTheme.palette.light.main,
                "& svg": {
                  fill: globalTheme.palette.primary.main,
                  marginRight: "8px",
                },
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.primary.main}`,
                  color: globalTheme.palette.light.main,
                  backgroundColor: globalTheme.palette.primary.main,
                  transition: "transform 1s ease",
                  "& svg": {
                    fill: globalTheme.palette.light.main,
                  },
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "contained", color: "menu" },
              style: {
                border: `2px solid ${globalTheme.palette.secondary.main}`,
                color: globalTheme.palette.primary.main,
                backgroundColor: globalTheme.palette.secondary.main,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.secondary.main}`,
                  color: globalTheme.palette.light.main,
                  backgroundColor: globalTheme.palette.primary.main,
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.secondary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "outlined", color: "menu" },
              style: {
                border: `2px solid  ${globalTheme.palette.secondary.main}`,
                color: globalTheme.palette.light.main,
                backgroundColor: globalTheme.palette.primary.main,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.secondary.main}`,
                  color: globalTheme.palette.light.main,
                  backgroundColor: globalTheme.palette.primary.main,
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.secondary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "outlined", color: "dark" },
              style: {
                border: `2px solid  ${globalTheme.palette.primary.main}`,
                color: globalTheme.palette.primary.main,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.primary.main}`,
                  color: globalTheme.palette.light.main,
                  backgroundColor: globalTheme.palette.primary.main,
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "contained", color: "dark" },
              style: {
                border: `2px solid ${globalTheme.palette.primary.main}`,
                color: globalTheme.palette.light.main,
                "& svg": {
                  fill: globalTheme.palette.light.main,
                  marginRight: "8px",
                },
                backgroundColor: globalTheme.palette.primary.main,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.primary.main}`,
                  color: globalTheme.palette.primary.main,
                  backgroundColor: globalTheme.palette.light.main,
                  transition: "transform 1s ease",
                  "& svg": {
                    fill: globalTheme.palette.primary.main,
                  },
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "contained", color: "black" },
              style: {
                border: `2px solid ${globalTheme.palette.black.main}`,
                color: globalTheme.palette.black.contrastText,
                "& svg": {
                  fill: globalTheme.palette.black.contrastText,
                  marginRight: "8px",
                },
                backgroundColor: globalTheme.palette.black.main,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.black.main}`,
                  color: globalTheme.palette.black.main,
                  backgroundColor: globalTheme.palette.light.main,
                  transition: "transform 1s ease",
                  "& svg": {
                    fill: globalTheme.palette.black.main,
                  },
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.black.main}`,
                  outlineOffset: "2px",
                },
              },
            },
            {
              props: { variant: "contained", color: "darkBlue" },
              style: {
                backgroundColor: globalTheme.palette.blue.main,
                color: globalTheme.palette.blue.contrastText,
              },
            },
            {
              props: { variant: "text", color: "primary" },
              style: {
                color: globalTheme.palette.primary.main,
                "& svg": {
                  fill: globalTheme.palette.primary.main,
                },
              },
            },
            {
              props: { variant: "text", color: "light" },
              style: {
                color: globalTheme.palette.text.primary,
                textDecoration: "underline",
                textUnderlineOffset: "4px",
                textDecorationThickness: "2px",
                "& svg": {
                  fill: globalTheme.palette.text.primary,
                },
              },
            },
            {
              props: { variant: "contained", color: "lightOnDark" },
              style: {
                border: `2px solid ${globalTheme.palette.primary.main}`,
                color: globalTheme.palette.primary.main,
                backgroundColor: globalTheme.palette.light.main,
                "&:hover": {
                  border: `2px solid  ${globalTheme.palette.light.main}`,
                  color: globalTheme.palette.light.main,
                  backgroundColor: globalTheme.palette.primary.main,
                },
                "&.Mui-focusVisible": {
                  outline: `2px solid ${globalTheme.palette.primary.main}`,
                  outlineOffset: "2px",
                },
              },
            },
          ],
          styleOverrides: {
            root: {
              "&.menuButton": {
                borderRadius: "4px",
                padding: "10px",
                color: globalTheme.palette.text.primary,
                "& svg path": {
                  fill: globalTheme.palette.text.primary,
                },
              },
              boxShadow: "none",
              "&:hover, &:focus": {
                boxShadow: "none",
              },
              textTransform: "none",
              fontSize: 16,
              borderRadius: 36,
              padding: "14px 28px",
              [globalTheme.breakpoints.down("md")]: {
                // padding: "12px 24px",
              },
              whiteSpace: "nowrap",
              "& .MuiButton-endIcon, .MuiButton-startIcon": {
                transition: "transform 0.3s ease",
              },
              "&:hover .MuiButton-endIcon": {
                transform: "translateX(4px)",
              },
              "&:hover .MuiButton-startIcon": {
                transform: "translateX(-4px)",
              },
            },
            contained: {},
            text: {
              color: globalTheme.palette.primary.contrastText,
              "&.Mui-focusVisible": {
                outline: `2px solid ${globalTheme.palette.primary.contrastText}`,
                outlineOffset: "2px",
              },
            },
            outlined: {},
          },
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              padding: 24,
              backgroundColor: globalTheme.palette.primary.main,
              width: "100vw",
              "& nav": {
                padding: 0,
                "& ul": {
                  padding: 0,
                  margin: 0,
                  "& li": {
                    padding: 0,
                    borderTop: `1px solid ${COLORS.BLUE[40]}`,
                    "&:last-of-type": {
                      borderBottom: `1px solid ${COLORS.BLUE[40]}`,
                    },
                    "& a": {
                      textDecoration: "none",
                      width: "100%",
                      color: globalTheme.palette.text.primary,
                      fontSize: 20,
                      paddingTop: 24,
                      paddingBottom: 24,
                      paddingLeft: 0,
                      "&[aria-current=true]": {
                        color: globalTheme.palette.secondary.main,
                      },
                      "&:hover": {
                        textDecoration: "none",
                        textDecorationColor: globalTheme.palette.text.primary,
                        textDecorationThickness: 1,
                      },
                      "&.MuiLink-underlineAlways:hover, &.MuiLink-underlineAlways:focus-visible":
                        {
                          textDecoration: "none",
                          color: globalTheme.palette.secondary.main,
                          backgroundColor: "transparent",
                          outline: "none",
                        },
                    },
                  },
                },
              },
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            root: {
              backgroundColor: COLORS.WHITE[100],
              color: COLORS.DARK_BLUE[100],
              borderRadius: 12,
              border: `solid 2px ${COLORS.BRIGHT_BLUE[60]}`,
              fontSize: 16,
              lineHeight: 1.6,
            },
            input: {
              padding: "10px 16px!important",
            },
          },
        },
        MuiList: {
          styleOverrides: {
            root: {
              backgroundColor: COLORS.WHITE[100],
              color: COLORS.NORMAL[100],
              fontSize: "16px",
              lineHeight: 1.6,
              padding: 0,
              "& .MuiMenu-paper": {
                backgroundColor: "pink",
                padding: 10,
              },
              "& .MuiButtonBase-root": {
                fontSize: "14px",
                "&.Mui-selected, .Mui-focusVisible": {
                  backgroundColor: COLORS.BLUE[40],
                  ":hover": {
                    backgroundColor: COLORS.BLUE[20],
                  },
                },
                "&.Mui-focusVisible": {
                  backgroundColor: COLORS.BLUE[40],
                },
                ":hover": {
                  backgroundColor: COLORS.BLUE[20],
                },
              },
            },
          },
        },
        MuiFormHelperText: {
          variants: [
            {
              props: { variant: "outlined" },
              style: {
                color: "#000000",
                fontSize: 14,
                fontWeight: 400,
                marginLeft: 0,
                marginRight: 0,
              },
            },
          ],
        },
        MuiSelect: {
          defaultProps: {
            InputLabelProps: { shrink: true },
            InputProps: {
              disableUnderline: true,
            },
          },
          variants: [
            {
              props: { variant: "outlined" },
              style: {
                "&.MuiOutlinedInput-root": {
                  backgroundColor: "#FFF",
                  border: `solid 1px ${COLORS.NORMAL[100]}`,
                  borderRadius: "8px",
                  "& .MuiSelect-icon": {
                    color: COLORS.NORMAL[100],
                    top: "calc(50% - 10px)",
                    right: "12px",
                  },
                  "&.Mui-error": {
                    border: `solid 2px ${COLORS.ERROR[100]}`,
                    "& .MuiInputAdornment-root p": {
                      color: COLORS.ERROR[100],
                    },
                    "& .MuiSelect-icon": {
                      color: COLORS.RED[100],
                    },
                  },
                  "&.Mui-focused": {
                    "&.Mui-error": {
                      outline: `1px solid ${COLORS.RED[100]}`,
                    },
                    outline: `1px solid ${COLORS.NORMAL[100]}`,
                  },
                  "& .MuiOutlinedInput-input": {
                    fontSize: "14px",
                    fontWeight: 400,
                    padding: 0,
                    color: COLORS.NORMAL[100],
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
                "&label": {
                  userSelect: "auto",
                  position: "relative",
                  transform: "none",
                  color: COLORS.NORMAL[100],
                  fontSize: "14px",
                  fontWeight: 500,
                },
              },
            },
          ],
        },
        MuiTextField: {
          styleOverrides: {
            root: {},
          },
          defaultProps: {
            InputLabelProps: { shrink: true },
            InputProps: {
              disableUnderline: true,
            },
          },
          variants: [
            {
              props: { variant: "outlined" },
              style: {
                "& .MuiFormHelperText-root": {
                  "&.Mui-error": {
                    color: "#000000",
                    fontSize: "13px",
                    fontWeight: 400,
                    marginLeft: 0,
                    marginRight: 0,
                  },
                },

                "& .MuiInputAdornment-root": {
                  "& p": {
                    color: COLORS.NORMAL[100],
                  },
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#FFF",
                  border: `solid 1px ${COLORS.NORMAL[100]}`,
                  borderRadius: "12px",
                  "&.Mui-error": {
                    border: `solid 2px ${COLORS.ERROR[100]}`,
                    "& .MuiInputAdornment-root p": {
                      color: COLORS.ERROR[100],
                    },
                  },
                  "& .MuiInputAdornment-root": {
                    "& p": {
                      color: COLORS.NORMAL[100],
                      fontSize: "16px",
                      fontWeight: 400,
                      lineHeight: 1.6,
                    },
                  },
                  "&.Mui-focused": {
                    "&.Mui-error": {
                      outline: `1px solid ${COLORS.RED[100]}`,
                    },
                    outline: `1px solid ${COLORS.NORMAL[100]}`,
                  },
                  "& input": {
                    fontSize: "16px",
                    fontWeight: 400,
                    padding: 0,
                    color: COLORS.NORMAL[100],
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              },
            },
            {
              props: { variant: "outlined", color: "secondary" },
              style: {
                "& .MuiFormHelperText-root": {
                  "&.Mui-error": {
                    color: "#000000",
                    fontSize: "13px",
                    fontWeight: 400,
                    marginLeft: 0,
                    marginRight: 0,
                  },
                },

                "& .MuiInputAdornment-root": {
                  "& p": {
                    color: COLORS.NORMAL[100],
                  },
                },
                "& .MuiOutlinedInput-root": {
                  backgroundColor: "#FFF",
                  border: `solid 1px ${COLORS.NORMAL[100]}`,
                  borderRadius: "6px",
                  "&.Mui-error": {
                    border: `solid 2px ${COLORS.ERROR[100]}`,
                    "& .MuiInputAdornment-root p": {
                      color: COLORS.ERROR[100],
                    },
                  },
                  "& .MuiInputAdornment-root": {
                    "& p": {
                      color: COLORS.NORMAL[100],
                      fontSize: "18px",
                      fontWeight: 400,
                      lineHeight: "18px",
                    },
                  },
                  "&.Mui-focused": {
                    "&.Mui-error": {
                      outline: `1px solid ${COLORS.RED[100]}`,
                    },
                    outline: `1px solid ${COLORS.NORMAL[100]}`,
                  },
                  "& input": {
                    fontSize: "18px",
                    fontWeight: 400,
                    padding: 0,
                    color: COLORS.NORMAL[100],
                  },
                  "& fieldset": {
                    border: "none",
                  },
                },
              },
            },
          ],
        },
        MuiChip: {
          defaultProps: {
            color: "primary",
          },
          variants: [
            {
              props: { color: "primary" },
              style: {
                backgroundColor: globalTheme.palette.primary.contrastText,
                color: globalTheme.palette.primary.main,
                ":hover": {
                  backgroundColor: COLORS.BLUE[40],
                },
              },
            },
            {
              props: { color: "secondary" },
              style: {
                backgroundColor: COLORS.BLUE[40],
                ":hover": {
                  backgroundColor: COLORS.BLUE[20],
                },
              },
            },
            {
              props: { color: "error" },
              style: {
                backgroundColor: COLORS.RED[60],
                color: globalTheme.palette.primary.main,
                ":hover": {
                  backgroundColor: COLORS.RED[60],
                },
              },
            },
            {
              props: { color: "info" },
              style: {
                backgroundColor: globalTheme.palette.primary.main,
                color: globalTheme.palette.primary.contrastText,
              },
            },
          ],
          styleOverrides: {
            root: {
              lineHeight: "30px",
            },
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              backgroundColor: COLORS.WHITE[100],
              borderColor: COLORS.DARK_BLUE[100],
              borderStyle: "solid",
              borderWidth: "2px",
              borderRadius: "20px",
              "&:first-of-type": {
                borderRadius: "20px",
              },
              "&:last-of-type": {
                borderRadius: "20px",
              },
              "&::before": {
                height: "0px",
              },
            },
          },
        },
        MuiAccordionSummary: {
          styleOverrides: {
            root: {
              flexDirection: "row-reverse",
              padding: "0px 36px",
            },
            content: {
              margin: "36px 0px",
            },
          },
        },
        MuiAccordionDetails: {
          styleOverrides: {
            root: {
              padding: "0px",
            },
          },
        },
        MuiRating: {
          styleOverrides: {
            iconFilled: {
              color: COLORS.DARK_BLUE[100],
            },
            iconEmpty: {
              color: "#00000000",
            },
            sizeSmall: {
              fontSize: "16px",
            },
            sizeMedium: {
              fontSize: "20px",
            },
          },
        },
      },
    } as ThemeOptions,
    nbNO
  ),
  {
    // Specify the variants that should be responsive.
    // We need to cast our custom variants to Variant type because TypeScript doesn't know that they are valid variants.
    // This is a workaround to avoid TypeScript errors.
    variants: [
      "h1",
      "h2",
      "h3",
      "h4",
      "body1",
      "body2",
      "caption",
      "button",
      "overline",
      "subtitle1",
      "subtitle2",
      ...customTypographyVariantsNameList.map((variant) => variant as Variant),
    ],
  }
);

export default hordeTheme;
