import { Collapse, InputAdornment, Stack } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { HousingSituation } from "../../../../services/nswag/clients/loanApplicationsClient";
import ButtonToggleGroup from "../../../ButtonToggle";
import { InputFieldsCoApplicant } from "../../../../constants/inputFieldNamesSoknad";
import { errorPreFixNamingCoApplicant } from "../../../../pages/soknad/forbrukslan/medlantaker";
import CustomNumericFormat from "../../../CustomNumericFormat";
import { handleNumberRangeInputValidation } from "../../../../utils/warningValidationUtils";
import TextInput from "../../../TextInput";
import useCoApplicant from "../../../../hooks/useCoApplicant";

const MortgageSection = () => {
  const { coApplicant, handleUpdate } = useCoApplicant();
  const standardButtonOptions = [
    { label: "Ja", value: true },
    { label: "Nei", value: false },
  ];
  const dispatch = useDispatch();

  return (
    <Collapse
      in={
        coApplicant?.housingSituation === HousingSituation.OwnsHouse &&
        !coApplicant?.livesWithMainApplicant
      }
    >
      <Stack gap="16px">
        <ButtonToggleGroup
          label="Har medlåntaker boliglån?"
          labelVariant="inputLabel"
          buttons={standardButtonOptions}
          onSelectedChange={(value) => {
            if (value !== coApplicant.hasMortgage) {
              handleUpdate({
                target: {
                  name: `${InputFieldsCoApplicant.hasMortgage}`,
                  value,
                },
              });
            }
          }}
          name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.hasMortgage}`}
          value={coApplicant?.hasMortgage}
        />
        <Collapse in={coApplicant?.hasMortgage}>
          <Stack gap="16px">
            <CustomNumericFormat
              allowNegative={false}
              value={coApplicant?.remainingMortgageAmount}
              onValueChange={(value) => {
                handleUpdate({
                  target: {
                    name: `${InputFieldsCoApplicant.remainingMortgageAmount}`,
                    value: value.floatValue,
                  },
                });
              }}
              onBlur={() => {
                dispatch(
                  handleNumberRangeInputValidation(
                    500000,
                    15000000,
                    coApplicant?.remainingMortgageAmount ?? 0,
                    `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.remainingMortgageAmount}`
                  )
                );
              }}
              errorText="Oppgi beløp"
              name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.remainingMortgageAmount}`}
              label="Hvor mye gjenstår av medlåntakers boliglån?"
              thousandSeparator=" "
              customInput={TextInput}
              type="text"
              variant="outlined"
              slotProps={{
                htmlInput: {
                  maxLength: 8,
                  inputMode: "numeric",
                },
                input: {
                  sx: {
                    maxWidth: "150px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">kr</InputAdornment>
                  ),
                },
              }}
            />
            <CustomNumericFormat
              allowNegative={false}
              label="Hvor mye betaler medlåntaker i måneden på boliglånet?"
              thousandSeparator=" "
              customInput={TextInput}
              type="text"
              variant="outlined"
              errorText="Oppgi beløp"
              value={coApplicant?.paymentOnMortgageMonthly}
              onValueChange={(value) => {
                handleUpdate({
                  target: {
                    name: `${InputFieldsCoApplicant.paymentOnMortgageMonthly}`,
                    value: value.floatValue,
                  },
                });
              }}
              onBlur={() => {
                dispatch(
                  handleNumberRangeInputValidation(
                    1000,
                    100000,
                    coApplicant?.paymentOnMortgageMonthly ?? 0,
                    `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.paymentOnMortgageMonthly}`
                  )
                );
              }}
              name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.paymentOnMortgageMonthly}`}
              slotProps={{
                htmlInput: {
                  maxLength: 7,
                  inputMode: "numeric",
                },
                input: {
                  sx: {
                    maxWidth: "150px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">kr</InputAdornment>
                  ),
                },
              }}
            />
          </Stack>
        </Collapse>
      </Stack>
    </Collapse>
  );
};

export default MortgageSection;
