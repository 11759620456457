import {
  ApplicationSource,
  EmploymentType,
  IClient,
  ILoanApplicationSubmissionFormDto,
  IPartnerSubmissionClientView,
  LoanPurpose,
  MaritalStatusType,
  PartnerSubmissionState,
} from "../services/nswag/clients/loanApplicationsClient";

export function parseBirthDate(dateString: string) {
  // Ekstraher dag, måned og år fra strengen
  const day = parseInt(dateString.substring(0, 2), 10);
  const month = parseInt(dateString.substring(2, 4), 10);
  let year = parseInt(dateString.substring(4, 6), 10);

  // Håndter to-sifret år. Anta at år 00-99 tilhører 1900-tallet (kan justeres)
  year += year < 50 ? 2000 : 1900;

  // Returner som et objekt
  return {
    day,
    month,
    year,
  };
}

export const showEmploymentName = (selectedEmploymentType?: EmploymentType) => {
  switch (selectedEmploymentType) {
    case EmploymentType.FullTimePrivate:
      return true;
    case EmploymentType.FullTimePublic:
      return true;
    case EmploymentType.SoleProprietorship:
      return true;
    case EmploymentType.TemporaryEmployment:
      return true;
    case EmploymentType.TemporarilyLaidOff:
      return true;
    default:
      return false;
  }
};

export const showMaritalIncome = (
  selectedMaritalStatus?: MaritalStatusType
) => {
  switch (selectedMaritalStatus) {
    case MaritalStatusType.Married:
      return true;
    case MaritalStatusType.Cohabitant:
      return true;
    default:
      return false;
  }
};

export const addMissingRequiredFieldsBeforeSubmit = (
  application: ILoanApplicationSubmissionFormDto
) => {
  let form = application;
  form = JSON.parse(JSON.stringify(application));

  if (!form?.purpose) {
    form.purpose = LoanPurpose.Refinancing;
  }

  if (form.applicant.employmentSince.year === 0) {
    form.applicant.employmentSince.year = 1;
  }

  if (form.applicant.employmentSince.month === 0) {
    form.applicant.employmentSince.month = 1;
  }

  if (form.applicant.employmentSince.day === 0) {
    form.applicant.employmentSince.day = 1;
  }

  if (form.hasOtherDebt === undefined || form.hasOtherDebt === null) {
    form.hasOtherDebt = false;
  }

  if (form.coApplicants.length > 0) {
    form.coApplicants.forEach((coApplicant) => {
      if (coApplicant.employmentSince.year === 0) {
        coApplicant.employmentSince.year = 1;
      }

      if (coApplicant.employmentSince.month === 0) {
        coApplicant.employmentSince.month = 1;
      }

      if (coApplicant.employmentSince.day === 0) {
        coApplicant.employmentSince.day = 1;
      }

      if (!coApplicant.hasNorwegianCitizenship) {
        coApplicant.hasNorwegianCitizenship = true;
      }
    });
  }

  return form;
};

export const setClient = (): IClient => {
  return {
    source: ApplicationSource.Webform,
    appVersion: "",
  };
};

export const allBankAnswered = (partners: IPartnerSubmissionClientView[]) => {
  return partners.every(
    (partner) =>
      partner.state === PartnerSubmissionState.OfferGiven ||
      partner.state === PartnerSubmissionState.RejectedByPartner ||
      partner.state === PartnerSubmissionState.TimedOut ||
      partner.state === PartnerSubmissionState.RejectedByFilters
  );
};
