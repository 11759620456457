import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IPartnerSubmissionClientView } from "../../services/nswag/clients/loanApplicationsClient";
import { GetLoanApplicationClient } from "../../services/axiosInstance";
import { AppThunk, RootState } from "../store";

interface IAcceptedOfferState {
  partner?: IPartnerSubmissionClientView;
  applicationId?: string;
  loading: "loading" | "fullfilled" | "error" | undefined;
}

const initialState = {
  loading: undefined,
  partner: undefined,
  applicationId: undefined,
} as IAcceptedOfferState;

const client = GetLoanApplicationClient();

export const selectAcceptedOffer = (state: RootState) => {
  return state.acceptedApplication.partner;
};

export const selectAcceptedOfferLoading = (state: RootState) => {
  return state.acceptedApplication.loading;
};

export const fetchAcceptedOffer = createAsyncThunk<
  IPartnerSubmissionClientView,
  string
>("acceptedOffer/fetch", async (id) => {
  const result = await client.getAcceptedOfferInApplication(id);
  return result;
});

const acceptedOfferSlice = createSlice({
  name: "acceptedOffer",
  initialState,
  reducers: {
    setPartner: (
      state,
      action: PayloadAction<IPartnerSubmissionClientView>
    ) => {
      state.partner = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchAcceptedOffer.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(fetchAcceptedOffer.rejected, (state) => {
      state.loading = "error";
    });
    builder.addCase(fetchAcceptedOffer.fulfilled, (state, action) => {
      state.partner = action.payload;
      state.applicationId = action.meta.arg;
      state.loading = "fullfilled";
    });
  },
});
export default acceptedOfferSlice.reducer;

export const refreshAcceptedOffer =
  (): AppThunk => async (dispatch, getState) => {
    const currentId = getState().acceptedApplication.applicationId;

    if (currentId) {
      client.getAcceptedOfferInApplication(currentId).then((result) => {
        if (result) dispatch(acceptedOfferSlice.actions.setPartner(result));
      });
    }
  };
