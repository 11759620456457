import { AxiosPromise } from "axios";
import axios from "../axiosInstance";
import {
  FacebookFbclid,
  GoogleGlid,
  ReadPeak,
  SnapChatClickId,
  TikTokClickId,
  TrackingValues,
} from "../../utils/tracking";

export enum TrackingEvent {
  LoanStarted = 0,
  LoanRequestSent = 1,
  LoanPaidOut = 2,
}
export const apiSendClickEvent = (
  eventName: TrackingEvent,
  trackings: TrackingValues
): AxiosPromise => {
  return axios.get(`/s-message-web/tracking/event?eventType=${eventName}`, {
    headers: {
      [ReadPeak]: trackings?.rpcid,
      [TikTokClickId]: trackings?.ttclid,
      [SnapChatClickId]: trackings?.ScCid,
      [GoogleGlid]: trackings?.gclid,
      [FacebookFbclid]: trackings?.fbclid,
    },
  });
};
