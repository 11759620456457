/* eslint-disable import/prefer-default-export */
import { SoknadType } from "./soknadType";

export const NavigationNames = {
  SOKNAD: {
    START_SOKNAD: `/soknad?type=${SoknadType.ConsumerLoan}`,
    START_SOKNAD_REFINANSIER: `/soknad?type=${SoknadType.refinance}`,
    BASE: "/soknad",
    BOLIG: "/soknad/forbrukslan/bolig",
    BOLIG_LEGGTIL: "/soknad/forbrukslan/bolig/leggtil",
    BOLIG_OPPDATER: (i: number) => `/soknad/forbrukslan/bolig/${i}/oppdater`,
    PAYMENT_MARK: "/soknad/betalingsanmerkning",
    FETCHING_LOADING: "/soknad/henting",
    ERROR: "/soknad/error",
    DEBT_AND_HOUSING: "/soknad/forbrukslan/gjeld-og-bolig",
    BELOP: "/soknad/forbrukslan",
    INCOME: "/soknad/forbrukslan/inntekt",
    CIVIL_STATUS: "/soknad/forbrukslan/sivilstatus",
    SUMMARY: "/soknad/forbrukslan/oppsummering",
    KREDITTKORT_POPUP: "/soknad/kredittkort",
    KREDITT_SPERRET: "/soknad/kredittsperret",
    IKKE_KREDITTVERDIG: "/soknad/ikke-kredittverdig",
    PABEGYNT_SOKNAD: "/soknad/pabegynt-soknad",
    CO_APPLICANT: "/soknad/forbrukslan/medlantaker",
    NOT_AGE: "/soknad/ikke-gammel-nok",
    COAPPLICANT_PAYMENTMARK:
      "/soknad/forbrukslan/medlantaker-betalingsanmerking",
    SOKNAD_SENDT: "/soknad/forbrukslan/soknadsendt",
  },
  TILBUD: {
    BASE: "/soknad/tilbud",
    SIGNERING: (id: string) => `/soknad/tilbud/${id}/signering`,
  },
  OMSTARTSLAN: {
    BASE: "/soknad/omstartslan",
    BOLIG: "/soknad/omstartslan/bolig",
    BOLIG_LEGGTIL: "/soknad/omstartslan/bolig/leggtil",
    BOLIG_OPPDATER: (i: number) => `/soknad/omstartslan/bolig/${i}/oppdater`,
    BOLIG_TRENGER_KAUSJONIST: "/soknad/omstartslan/bolig/trenger-kausjonist",
    BOLIG_TRENGER_KAUSJONIST_REFINANS_ALT:
      "/soknad/omstartslan/bolig/trenger-kausjonist/refinance",
    BOLIG_TRENGER_KAUSJONIST_FORBRUK_ALT:
      "/soknad/omstartslan/bolig/trenger-kausjonist/consumer-loan",
    BOLIG_TRENGER_VALG_AV_SIKKERHET: "/soknad/omstartslan/bolig/velg-sikkerhet",
    BOLIG_TRENGER_VALG_AV_SIKKERHET_REFINANS_ALT:
      "/soknad/omstartslan/bolig/velg-sikkerhet/refinans",
    GJELD: "/soknad/omstartslan/gjeld",
    INNTEKT: "/soknad/omstartslan/inntekt",
    SIVILSTATUS: "/soknad/omstartslan/sivilstatus",
    START_SOKNAD: `/soknad?type=${SoknadType.Restartloan}`,
    MEDLANTAKER: "/soknad/omstartslan/medlantaker",
    KAUSJONIST: "/soknad/omstartslan/kausjonist",
    OPPSUMMERING: "/soknad/omstartslan/oppsummering",
    ALTERNATIV_REFINANS: "/soknad/omstartslan/alternativ-uten-bolig",
    ADVARSEL_LAVT_BELOP: "/soknad/omstartslan/lavt-belop",
    SOKNAD_SENDT: "/soknad/omstartslan/soknadsendt",
  },
  KredittKort: "/soknad/kredittkort",
  InActive: "/soknad/inaktiv",
};
