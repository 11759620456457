import React, { ReactElement } from "react";
import { Stack, StackOwnProps } from "@mui/material";
import FormCard from "./FormCard";

/**
 * Card component that puts it's children in a stack layout. Used
 * when composing individual components together as a cohesive unit
 * in our application form pages.
 *
 * @param {IFormCardStackProps} props - Properties for the component, and inherited stack properties
 * @param {ReactNode[] | ReactNode} FormCard.children - ReactNode children
 * @param {string?} FormCard.cardColor - Color of the card background
 * @param {boolean?} FormCard.bordered - The card is bordered by a black border
 * @param {boolean?} [FormCard.shadowed] - The card has a black drop shadow
 * @augments Stack
 * @returns {ReactElement} react TSX component
 */
const FormCardStack = (props: IFormCardStackProps): ReactElement => {
  const { cardColor, bordered, shadowed } = props;
  return (
    <FormCard cardColor={cardColor} bordered={bordered} shadowed={shadowed}>
      <Stack {...props} />
    </FormCard>
  );
};
export default FormCardStack;

export interface IFormCardStackProps extends StackOwnProps {
  cardColor?: string;
  bordered?: boolean;
  shadowed?: boolean;
}
