import React from "react";
import { useDispatch } from "react-redux";
import { InputAdornment, Collapse, Stack } from "@mui/material";
import { updateLoanApplicationCoApplicant } from "../../../redux/reducers/loanApplicationReducer";
import { InputFieldsCoApplicant } from "../../../constants/inputFieldNamesSoknad";
import ButtonToggleGroup from "../../ButtonToggle";
import { errorPreFixNamingCoApplicant } from "../../../pages/soknad/forbrukslan/medlantaker";
import TextInput from "../../TextInput";
import ButtonToggleGroupWrapper from "../ButtonToggleGroupWrapper";
import CustomNumericFormat from "../../CustomNumericFormat";
import useCoApplicant from "../../../hooks/useCoApplicant";

const CoApplicantOtherIncome = () => {
  const dispatch = useDispatch();
  const { coApplicant } = useCoApplicant();

  const standardButtonOptions = [
    { label: "Ja", value: true },
    { label: "Nei", value: false },
  ];

  return (
    <ButtonToggleGroupWrapper>
      <ButtonToggleGroup
        label="Har medlåntaker andre faste inntekter?"
        description="Deltidsjobb, stipender eller liknende som utbetales jevnlig."
        buttons={standardButtonOptions}
        onSelectedChange={(value) => {
          if (value !== coApplicant?.hasOtherIncome) {
            dispatch(
              updateLoanApplicationCoApplicant({
                name: InputFieldsCoApplicant.hasOtherIncome,
                value,
                index: 0,
                errorHandling: [InputFieldsCoApplicant.otherIncome],
              })
            );
          }
        }}
        name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.hasOtherIncome}`}
        value={coApplicant?.hasOtherIncome}
      />
      <Collapse in={coApplicant?.hasOtherIncome}>
        <Stack pt="16px" gap="16px">
          <CustomNumericFormat
            allowNegative={false}
            name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.otherIncome}`}
            id="other-income-amount"
            label="Sum andre faste inntekter?"
            thousandSeparator=" "
            customInput={TextInput}
            type="text"
            variant="outlined"
            value={coApplicant?.otherIncome}
            slotProps={{
              htmlInput: {
                maxLength: 9,
                inputMode: "numeric",
              },
              input: {
                sx: {
                  maxWidth: "150px",
                },
                endAdornment: (
                  <InputAdornment position="end">kr</InputAdornment>
                ),
              },
            }}
            onValueChange={(value) => {
              dispatch(
                updateLoanApplicationCoApplicant({
                  name: InputFieldsCoApplicant.otherIncome,
                  value: value.floatValue,
                  index: 0,
                })
              );
            }}
          />
        </Stack>
      </Collapse>
    </ButtonToggleGroupWrapper>
  );
};

export default CoApplicantOtherIncome;
