import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logout, RootState } from "../store";
import { clearSessionStorage } from "../../services/accessTokenHandler";

export interface Customer {
  id: number;
  name: string;
  ssn: string;
  phoneNumber: string;
  email: string;
}

export interface CustomerState {
  customer: Customer;
  customerLoading: boolean;
  loggedIn: boolean;
  customerFailed: boolean;
}

export const initialState: CustomerState = {
  customer: {
    id: 0,
    name: "",
    ssn: "",
    phoneNumber: "",
    email: "",
  },
  customerLoading: false,
  customerFailed: false,
  loggedIn: false,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    setLogin: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setSsn: (state, action: PayloadAction<string>) => {
      state.customer.ssn = action.payload;
    },
    setCustomer: (state, action: PayloadAction<Customer>) => {
      state.customer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      clearSessionStorage();
      return initialState; // Reset to initial state
    });
  },
});
export const getCustomer = (state: RootState) => state.customerReducer.customer;

export const { setLogin, setSsn, setCustomer } = customerSlice.actions;

export default customerSlice.reducer;
