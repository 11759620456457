import { Alert, Button, Stack } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import React from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { serializeError } from "serialize-error";
import { actionSaveCachedLoanApplication } from "../../redux/actions/loanApplicationAction";
import {
  goBackOneStep,
  goOneStepForward,
} from "../../redux/reducers/stepsReducer";
import { GetLoanApplicationClient } from "../../services/axiosInstance";
import {
  PartnerProductType,
  SubmitLoanApplicationRequest,
} from "../../services/nswag/clients/loanApplicationsClient";
import { NavigationNames } from "../../constants/navigationNames";
import {
  getLoanApplication,
  refreshWarningHandlingStatus,
  updateErrorHandling,
} from "../../redux/reducers/loanApplicationReducer";
import {
  addMissingRequiredFieldsBeforeSubmit,
  setClient,
} from "../../utils/loanApplicationUtils";
import { sendTrackings } from "../../utils/tracking";
import { TrackingEvent } from "../../services/api/tracking";
import { fetchSubmittedApplications } from "../../redux/reducers/submittedApplications/submittedApplicationsReducer";
import { useAppDispatch } from "../../hooks/reduxHooks";
import useStepper from "../../hooks/useStepper";

interface Props {
  nextButton?: () => boolean;
  prevButton?: () => void;
  validateBeforeNext?: () => boolean;
  nextButtonText?: string;
  prevButtonText?: string;
  validateNames?: string[];
  isSendApplication?: boolean;
  alertMessage?: string;
  showMainErrorMessage?: boolean;
  showPrevButton?: boolean;
}
const loanApplicationClient = GetLoanApplicationClient();
export const ProductKey = {
  CONSUMER_LOAN: "consumer-loan",
  RESTART_LOAN: "restart-loan",
};

const NextPrevButtons = ({
  nextButton,
  prevButton,
  validateBeforeNext,
  nextButtonText = "Neste",
  prevButtonText = "Forrige",
  isSendApplication,
  validateNames,
  alertMessage = "Det mangler noen felter",
  showMainErrorMessage,
  showPrevButton = true,
}: Props) => {
  const dispatch = useAppDispatch();
  const {
    application,
    includeCreditCardApplication,
    errorHandling,
    loadingStoreCache,
  } = useSelector(getLoanApplication);
  const [loading, setLoading] = React.useState(false);
  const { getNextPage, currentPage } = useStepper();

  const mainErrorShow =
    showMainErrorMessage || validateNames?.find((name) => errorHandling[name]);

  const handleNextClick = () => {
    if (validateBeforeNext) {
      setTimeout(() => {
        // Scroll to the first element with error
        const elements = document.getElementsByClassName("hasValidationError");

        if (elements[0]) {
          const element = elements[0] as HTMLElement; // Ensure it's a valid HTMLElement
          window.scrollTo({
            top: element.getBoundingClientRect().top + window.scrollY,
            behavior: "smooth",
          });
        }
      }, 200);
      if (!validateBeforeNext()) {
        dispatch(actionSaveCachedLoanApplication(null, currentPage()));
        return;
      }
    }

    if (isSendApplication) {
      // localStorage.removeItem("OriginalSecurityObjectsLength");
      dispatch(
        updateErrorHandling([
          {
            name: "badrequestmissingrequiredfields",
            value: false,
          },
        ])
      );
      setLoading(true);

      const form = addMissingRequiredFieldsBeforeSubmit(application);
      form.client = setClient();

      loanApplicationClient
        .submitLoanApplication({
          form,
          includeCreditCardApplication,
        } as SubmitLoanApplicationRequest)
        .then(() => {
          setLoading(false);
          const loanTypeEvent =
            form.product === PartnerProductType.RestartLoan
              ? ProductKey.RESTART_LOAN
              : ProductKey.CONSUMER_LOAN;

          sendTrackings(
            TrackingEvent.LoanRequestSent,
            loanTypeEvent,
            "loan_request_sent"
          );

          dispatch(fetchSubmittedApplications());

          if (form.product === PartnerProductType.RestartLoan) {
            window.location.replace(NavigationNames.OMSTARTSLAN.SOKNAD_SENDT);
          } else {
            window.location.replace(NavigationNames.SOKNAD.SOKNAD_SENDT);
          }
        })
        .catch((error) => {
          setLoading(false);
          const serialized = serializeError(error);
          if (serialized.status === 400) {
            dispatch(
              updateErrorHandling([
                {
                  name: "badrequestmissingrequiredfields",
                  value: true,
                },
              ])
            );
          } else {
            navigate(NavigationNames.SOKNAD.ERROR);
          }
        });
      return;
    }

    if (nextButton) {
      const navigated = nextButton();
      if (navigated) {
        return;
      }
    }

    dispatch(
      actionSaveCachedLoanApplication(() => {
        dispatch(goOneStepForward());
        if (validateNames) {
          dispatch(refreshWarningHandlingStatus(validateNames));
        }
      }, getNextPage())
    );
  };

  const handleBackClick = () => {
    if (prevButton) {
      prevButton();
      return;
    }
    dispatch(goBackOneStep());
  };

  const showMainError = () => {
    if (mainErrorShow) {
      return <Alert severity="error">{alertMessage}</Alert>;
    }

    return false;
  };

  return (
    <Stack
      gap="15px"
      mt={{ xs: "37px", md: "0px" }}
      mb={{ xs: "100px", md: "0px" }}
      pl={{ xs: "8px" }}
      pr={{ xs: "8px" }}
    >
      {showMainError()}
      <Stack
        direction="row"
        gap="15px"
        justifyContent="flex-end"
        marginTop={{ xs: "0px", md: "25px" }}
        marginBottom={{ xs: "25px", md: "0px" }}
      >
        {showPrevButton && (
          <Button
            variant="containedCircle"
            color="light"
            startIcon={<ArrowBack />}
            onClick={handleBackClick}
          >
            {prevButtonText}
          </Button>
        )}
        {loading ? (
          <LoadingButton
            loading
            variant="contained"
            color="dark"
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Vennligst vent
          </LoadingButton>
        ) : (
          <Button
            disabled={loadingStoreCache}
            variant="containedCircle"
            color="dark"
            endIcon={<ArrowForward />}
            onClick={handleNextClick}
          >
            {nextButtonText}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};

export default NextPrevButtons;
