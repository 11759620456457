import React from "react";
import { Stack, StackProps } from "@mui/material";

interface Props extends StackProps {
  onClick: () => void;
}

const ClickableStack = ({ onClick, children, ...rest }: Props) => {
  return (
    <Stack
      tabIndex={0}
      sx={{
        ":hover": {
          cursor: "pointer",
        },
      }}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          onClick();
        }
      }}
      {...rest}
    >
      {children}
    </Stack>
  );
};

export default ClickableStack;
