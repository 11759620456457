import { CircularProgress, Stack, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../constants/colors";

interface LoadingProps {
  loading: boolean;
  children: React.ReactNode;
  text?: string;
}

const Loading = ({
  loading,
  children,
  text = "Vennligst vent...",
}: LoadingProps) => {
  if (!loading) {
    return children;
  }

  return (
    <Stack
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap="25px"
    >
      <CircularProgress sx={{ color: COLORS.DARK_BLUE[100] }} />
      <Typography variant="lead" color={COLORS.NORMAL[100]} textAlign="center">
        {text}
      </Typography>
    </Stack>
  );
};
export default Loading;
