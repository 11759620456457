import { AxiosPromise } from "axios";
import axios from "../axiosInstance";

import {
  FacebookFbclid,
  getTrackingFromStorage,
  GoogleGlid,
  ReadPeak,
  SnapChatClickId,
  TikTokClickId,
  TrackingValues,
} from "../../utils/tracking";

export interface TokenResponse {
  access_token: string;
  expires_in: number;
  token_type: string;
  refresh_token: string;
  scope: string;
}

export default interface BankIdLoginResponse {
  redirectUri: string;
}

export interface ApplyForLoanWeb {
  callbackUri: string;
  email: string;
  phoneNumber: string;
  type: string;
}

export const apiInitiateBankIdLogin = (
  sessionId: string
): AxiosPromise<BankIdLoginResponse> => {
  let url = "";
  let tracking = {} as TrackingValues;
  if (typeof window !== "undefined") {
    url = window.location.origin;
    tracking = getTrackingFromStorage();
  }

  return axios.get(
    `/s-auth/login/bankid/web?sessionId=${sessionId}&callbackUri=${url}/bankidreturn`,
    {
      headers: {
        [ReadPeak]: tracking?.rpcid,
        [TikTokClickId]: tracking?.ttclid,
        [SnapChatClickId]: tracking?.ScCid,
        [GoogleGlid]: tracking?.gclid,
        [FacebookFbclid]: tracking?.fbclid,
      },
    }
  );
};

export const apiInitiateApplyForLoan = (
  applyForLoan: ApplyForLoanWeb
): AxiosPromise<BankIdLoginResponse> => {
  let tracking = {} as TrackingValues;
  if (typeof window !== "undefined") {
    tracking = getTrackingFromStorage();
  }

  return axios.post(`/s-auth/web/bankid`, applyForLoan, {
    headers: {
      [ReadPeak]: tracking?.rpcid,
      [TikTokClickId]: tracking?.ttclid,
      [SnapChatClickId]: tracking?.ScCid,
      [GoogleGlid]: tracking?.gclid,
      [FacebookFbclid]: tracking?.fbclid,
    },
  });
};

export const apiConnectToken = (
  sessionId: string
): AxiosPromise<TokenResponse> => {
  const formData = new URLSearchParams();

  formData.append("grant_type", "horde_web");
  formData.append("client_id", "hordeWeb");
  formData.append("session_id", sessionId);
  formData.append(
    "scope",
    "control_center.application.control control_center.application.fetch control_center.application.submit control_center.meeting.fetch control_center.meeting.write customer.read debt.read product_application_filter.read creditscore.read residence.read"
  );

  return axios.post(`/s-auth/connect/token`, formData, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};
