import React from "react";
import ButtonToggleGroup from "../../../ButtonToggle";
import { InputFieldsCoApplicant } from "../../../../constants/inputFieldNamesSoknad";
import { HousingSituation } from "../../../../services/nswag/clients/loanApplicationsClient";
import { errorPreFixNamingCoApplicant } from "../../../../pages/soknad/forbrukslan/medlantaker";

import useCoApplicant from "../../../../hooks/useCoApplicant";

const OwnsOrRentsButtons = () => {
  const housingSituationOptions = [
    { label: "Eier", value: HousingSituation.OwnsHouse },
    { label: "Leier", value: HousingSituation.Rents },
    { label: "Bor gratis", value: HousingSituation.None },
  ];
  const { coApplicant, handleUpdate } = useCoApplicant();

  return (
    <ButtonToggleGroup
      label="Eier eller leier medlåntaker bolig?"
      labelVariant="inputLabel"
      buttons={housingSituationOptions}
      onSelectedChange={(value) => {
        handleUpdate({
          target: {
            name: `${InputFieldsCoApplicant.housingSituation}`,
            value,
          },
        });
        handleUpdate({
          target: {
            name: `${InputFieldsCoApplicant.hasMortgage}`,
            value: value === HousingSituation.Rents ? false : undefined,
          },
        });
      }}
      name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.housingSituation}`}
      value={coApplicant?.housingSituation}
    />
  );
};

export default OwnsOrRentsButtons;
