import { Stack } from "@mui/material";
import React from "react";
import COLORS from "../../constants/colors";

interface Props {
  children: React.ReactNode;
}
const InputFieldWrapper = ({ children }: Props) => {
  return (
    <Stack
      bgcolor={{ md: COLORS.BRIGHT_BLUE[20] }}
      borderRadius="12px"
      p={{ md: "24px" }}
      gap="16px"
    >
      {children}
    </Stack>
  );
};

export default InputFieldWrapper;
