import { idnr } from "@navikt/fnrvalidator";

export const validateNorwegianPhoneNumber = (phone: string): boolean => {
  const pattern = /^[2-9]\d{2} ?\d{2} ?\d{3}$/;
  return pattern.test(phone);
};

export const validateNorwegianSSN = (ssn: string): boolean => {
  const result = idnr(ssn);
  return result.status === "valid";
};
