import React from "react";
import { Collapse, InputAdornment, Stack } from "@mui/material";

import ButtonToggleGroup from "../../ButtonToggle";
import { InputFieldsCoApplicant } from "../../../constants/inputFieldNamesSoknad";
import { errorPreFixNamingCoApplicant } from "../../../pages/soknad/forbrukslan/medlantaker";
import { NumberOfChildren } from "../../../services/nswag/clients/loanApplicationsClient";
import TextInput from "../../TextInput";
import CustomNumericFormat from "../../CustomNumericFormat";
import useCoApplicant from "../../../hooks/useCoApplicant";

const CoApplicantChildren = () => {
  const { coApplicant, handleUpdate } = useCoApplicant();

  const standardButtonOptions = [
    { label: "Ja", value: true },
    { label: "Nei", value: false },
  ];
  const childrenCountOptions = [
    { label: "Ingen", value: NumberOfChildren.None },
    { label: "1", value: NumberOfChildren.One },
    { label: "2", value: NumberOfChildren.Two },
    { label: "3", value: NumberOfChildren.Three },
    { label: "4+", value: NumberOfChildren.FourOrAbove },
  ];

  return (
    <>
      <ButtonToggleGroup
        label="Medlåntakers barn"
        description="Hvis du og medlåntaker har felles barn, skal de ikke oppgis her."
        buttons={childrenCountOptions}
        onSelectedChange={(value) => {
          handleUpdate({
            target: {
              name: `${InputFieldsCoApplicant.childrenUnder18}`,
              value,
              errorHandling: [
                `${InputFieldsCoApplicant.receivesChildSupport}`,
                `${InputFieldsCoApplicant.childSupportReceivedMonthly}`,
              ],
            },
          });
        }}
        name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.childrenUnder18}`}
        value={coApplicant?.childrenUnder18}
      />
      <Collapse
        in={
          coApplicant?.childrenUnder18 !== NumberOfChildren.None &&
          coApplicant?.childrenUnder18 !== undefined &&
          coApplicant?.childrenUnder18 !== null
        }
      >
        <Stack borderRadius="12px" mt="32px" gap="16px">
          <ButtonToggleGroup
            label="Mottar du barnebidrag?"
            buttons={standardButtonOptions}
            description="Økonomisk støtte fra den andre forelderen, dersom dere ikke bor sammen."
            onSelectedChange={(value) => {
              handleUpdate({
                target: {
                  name: `${InputFieldsCoApplicant.receivesChildSupport}`,
                  value,
                  errorHandling: [
                    `${InputFieldsCoApplicant.childSupportReceivedMonthly}`,
                  ],
                },
              });
            }}
            name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.receivesChildSupport}`}
            value={coApplicant?.receivesChildSupport}
          />
          <Collapse in={coApplicant?.receivesChildSupport}>
            <CustomNumericFormat
              allowNegative={false}
              value={coApplicant?.childSupportReceivedMonthly}
              id="child-support"
              label="Utbetalt i måneden"
              errorText="Oppgi beløp"
              thousandSeparator=" "
              customInput={TextInput}
              type="text"
              variant="outlined"
              name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.childSupportReceivedMonthly}`}
              slotProps={{
                htmlInput: {
                  maxLength: 7,
                  inputMode: "numeric",
                },
                input: {
                  sx: {
                    maxWidth: "150px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">kr</InputAdornment>
                  ),
                },
              }}
              onValueChange={(value) => {
                handleUpdate({
                  target: {
                    name: `${InputFieldsCoApplicant.childSupportReceivedMonthly}`,
                    value: value.floatValue,
                  },
                });
              }}
            />
          </Collapse>
        </Stack>
      </Collapse>
    </>
  );
};

export default CoApplicantChildren;
