import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetLoanApplicationClient } from "../../../services/axiosInstance";
import { IPartnerSubmissionClientView } from "../../../services/nswag/clients/loanApplicationsClient";
import { RootState, logout } from "../../store";
import { initialSubmittedApplicationsState } from "./ISubmittedApplicationsState";

const client = GetLoanApplicationClient();

export const selectCurrentlyViewedApplication = (state: RootState) => {
  return state.submittedApplications.consumerLoan;
};

export const selectAnyOffersExist = (state: RootState) => {
  if (state.submittedApplications.consumerLoan)
    return state.submittedApplications.consumerLoan.partners.some(
      (p) => p.hasOffer
    );

  return false;
};

export const fetchSubmittedApplications = createAsyncThunk(
  "submittedApplications/fetchSubmittedApplications",
  async () => {
    const result = await client.getLastSubmittedApplications();
    return result;
  }
);

export const refreshSubmittedApplications = createAsyncThunk(
  "submittedApplications/refresh",
  async () => {
    const result = await client.getLastSubmittedApplications();
    return result;
  }
);

export const acceptApplicationOffer = createAsyncThunk<
  void,
  { id: string; pid: string; dual: boolean }
>("submittedApplications/acceptOffer", async ({ id, pid, dual }) => {
  const result = await client.acceptOffer(id, pid, dual);
  return result;
});

export const fetchAcceptedOffer = createAsyncThunk<
  IPartnerSubmissionClientView,
  string
>("submittedApplications/fetchAcceptedOffer", async (id) => {
  const result = await client.getAcceptedOfferInApplication(id);
  return result;
});

const submittedApplicationsSlice = createSlice({
  name: "submittedApplications",
  initialState: initialSubmittedApplicationsState,
  reducers: {
    setActiveTabState: (state, action: PayloadAction<0 | 1>) => {
      state.activeTabState = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchSubmittedApplications.pending, (state) => {
      state.fetchState = "loading";
    });
    builder.addCase(fetchSubmittedApplications.fulfilled, (state, action) => {
      const { consumerLoan, restartLoan } = action.payload;
      if (consumerLoan) state.consumerLoan = consumerLoan;
      if (restartLoan && !restartLoan.closed) state.openRestartExists = true;
      state.fetchState = "fulfilled";
    });

    builder.addCase(fetchSubmittedApplications.rejected, (state) => {
      state.fetchState = "error";
    });

    builder.addCase(refreshSubmittedApplications.fulfilled, (state, action) => {
      const { consumerLoan, restartLoan } = action.payload;
      if (consumerLoan) state.consumerLoan = consumerLoan;
      if (restartLoan && !restartLoan.closed) state.openRestartExists = true;
      state.fetchState = "fulfilled";
    });

    builder.addCase(logout, () => {
      return initialSubmittedApplicationsState;
    });
  },
});

export const { setActiveTabState } = submittedApplicationsSlice.actions;

export default submittedApplicationsSlice.reducer;
