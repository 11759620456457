import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/system";

/**
 * Custom hook to determine if the current device is a mobile device.
 * @returns {boolean} Returns `true` if the current device is a mobile device, `false` otherwise.
 */
const useIsMobile = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return isMobile;
};
export default useIsMobile;
