/* eslint-disable no-console */
import {
  HubConnectionState,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { getToken } from "../../../services/accessTokenHandler";

const webSocketUrl = `${process.env.API_URL}/loan-app-negotiate/loanApplicationHub/`;

export default function createLoanApplicationHub() {
  const connection = new HubConnectionBuilder()
    .withUrl(webSocketUrl, {
      accessTokenFactory: () => getToken(),
    })
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Error)
    .build();

  // re-establish the connection if connection dropped
  connection.onclose((error) => {
    console.assert(connection.state === HubConnectionState.Disconnected);
    console.log(
      "Connection closed due to error. Try refreshing this page to restart the connection",
      error
    );
  });

  connection.onreconnecting((error) => {
    console.assert(connection.state === HubConnectionState.Reconnecting);
    console.log("Connection lost due to error. Reconnecting.", error);
  });

  connection.onreconnected((connectionId) => {
    console.assert(connection.state === HubConnectionState.Connected);
    console.log(
      "Connection reestablished. Connected with connectionId",
      connectionId
    );
  });

  return connection;
}
