import { Stack } from "@mui/material";
import React from "react";
import StepperLabel from "./StepperLabel";

interface StepProps {
  label: string;
}

const UpcommingStep = ({ label }: StepProps) => {
  return (
    <Stack alignItems="center" gap="6px">
      <StepperLabel label={label} />
      <Stack
        width={{ xs: "16px", md: "24px" }}
        height={{ xs: "16px", md: "24px" }}
        justifyContent="center"
        alignItems="center"
        zIndex={1}
      >
        <Stack
          width={{ xs: "16px", md: "24px" }}
          height={{ xs: "16px", md: "24px" }}
          border="2px solid #AEB4D7"
          borderRadius="50%"
          bgcolor="#FFF"
        />
      </Stack>
    </Stack>
  );
};

export default UpcommingStep;
