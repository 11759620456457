import React, { useEffect } from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../../constants/colors";
import CustomStep from "./CustomStep";
import {
  addCoApplicant,
  addGuarantor,
  getStepper,
  navigateToStep,
  removeCoApplicant,
  removeGuarantor,
} from "../../../redux/reducers/stepsReducer";
import {
  getLoanApplicationForm,
  isAnyValidationError,
} from "../../../redux/reducers/loanApplicationReducer";
import { actionSaveCachedLoanApplication } from "../../../redux/actions/loanApplicationAction";
import InfoModalStepper from "./InfoModalStepper";

interface Props {
  validateBeforeNext?: () => boolean;
}

const CustomStepper = ({ validateBeforeNext }: Props) => {
  const steps = useSelector(getStepper);

  let progressStep = 0;
  if (typeof window !== "undefined") {
    progressStep = steps.findIndex((l) => l.url === window.location.pathname);
  }

  const [infoModalOpen, setInfoModalOpen] = React.useState(false);

  const dispatch = useDispatch();
  const { hasGuarantor, coApplicantIncluded } = useSelector(
    getLoanApplicationForm
  );

  const isValidationError = useSelector(isAnyValidationError);

  useEffect(() => {
    if (coApplicantIncluded) {
      dispatch(addCoApplicant());
    } else {
      dispatch(removeCoApplicant());
    }
    if (hasGuarantor) {
      dispatch(addGuarantor());
    } else {
      dispatch(removeGuarantor());
    }
  }, [coApplicantIncluded, hasGuarantor]);

  const currentPath = (url: string) => {
    if (typeof window !== "undefined") {
      return window.location.pathname === url;
    }
    return false;
  };

  const nextStepClickable = (index: number): boolean => {
    if (index <= 0 || index >= steps.length) {
      return false; // Guard clause for invalid indices
    }

    const currentStep = steps[index];
    const previousStep = steps[index - 1];
    const stepBeforePrevious = steps[index - 2];

    if (!currentStep.completed) {
      if (previousStep?.completed) {
        return true;
      }
      if (previousStep?.notInStepper && stepBeforePrevious?.completed) {
        return true;
      }
    }

    return false;
  };
  return (
    <Stack direction="row" borderRadius="12px" mb={{ xs: "6px" }}>
      {steps.map((step, index) => {
        if (step.notInStepper) {
          return;
        }
        // Dette betyr at vi har fullført stegene før og kan klikke på denne
        return (
          <Stack
            key={index}
            alignItems={{ xs: "flex-start", md: "center" }}
            flex={{ xs: index === steps.length - 1 ? 0 : 1, md: "1" }}
            width={{
              xs: index === steps.length - 1 ? "auto" : "10px",
              md: "auto",
            }}
            gap="12px"
            position="relative"
          >
            {/* Ikon og koblinger */}
            <Stack alignItems="center">
              {/* Steg-ikon */}

              <CustomStep
                label={step.label}
                isActive={currentPath(step.url)}
                onClick={() => {
                  if (validateBeforeNext) {
                    const validated = validateBeforeNext();
                    if (!validated || isValidationError) {
                      setInfoModalOpen(true);
                      return;
                    }
                  }
                  dispatch(
                    actionSaveCachedLoanApplication(() => {
                      dispatch(navigateToStep(index));
                    }, step.url)
                  );
                }}
                completed={step.completed}
                isCurrentStep={progressStep === index}
                nextStepClickable={nextStepClickable(index)}
              />

              {/* Koblingslinje til neste steg */}
              {index !== steps.length - 1 && (
                <Stack
                  position="absolute"
                  left={{ xs: 0, md: "50%" }}
                  top={{ xs: "40%", md: "73%" }}
                  width="100%"
                  height={{ xs: "2px", md: "4px" }}
                  bgcolor={
                    progressStep > index ? COLORS.BRIGHT_BLUE[100] : "#AEB4D7"
                  }
                  zIndex={0}
                />
              )}
            </Stack>
          </Stack>
        );
      })}
      <InfoModalStepper show={infoModalOpen} handleClose={setInfoModalOpen} />
    </Stack>
  );
};

export default CustomStepper;
