import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import COLORS from "../../../../../constants/colors";
import ClickableStack from "../../../../ClickableStack";
import UpCircle from "../../../../../assets/svgs/up-circle.svg";

export interface IProps {
  onSearchAgain: () => void;
}
export default function ExpandableExplanationWithSearchAgainButton({
  onSearchAgain,
}: IProps) {
  const [show, setShow] = useState(false);

  return (
    <Stack alignItems="center">
      <ClickableStack
        onClick={() => {
          setShow(!show);
        }}
        direction="row"
        gap="8px"
        alignItems="center"
        display="flex"
        borderRadius="6px 6px 0px 0px"
        width="100%"
        bgcolor={COLORS.BRIGHT_BLUE[40]}
        px="80px"
        py="16px"
        justifyContent="center"
      >
        <Typography
          variant="label"
          component="u"
          color={COLORS.NORMAL[100]}
          display="flex"
        >
          Hvorfor fikk jeg ikke tilbud?
        </Typography>
        <Stack display="flex">
          <UpCircle
            height="20px"
            width="20px"
            style={{
              transition: "transform 0.5s",
              transform: show ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </Stack>
      </ClickableStack>
      <Collapse in={show}>
        <Stack bgcolor={COLORS.WHITE[100]} p="16px 32px 32px 32px" gap="32px">
          <Stack gap="16px">
            <Typography variant="bodyBold" color={COLORS.NORMAL[100]}>
              Bankene deler ikke nøyaktig grunn til avslag med oss, men det kan
              være en av de under:
            </Typography>
            <Typography color={COLORS.DARK_BLUE[100]} variant="body">
              <ul>
                <li>
                  Summen du søkte gjør at totalgjelden din blir for høy. Du kan
                  prøve med en lavere sum.
                </li>
                <li>Du tjener under 150 000 kroner i året.</li>
                <li>
                  Tilleggslånet du søkte gjør at bankene vurderer risiko
                  annerledes enn om du kun søker refinansiering. Søk kun
                  refinansiering.
                </li>
                <li>
                  Opplysningene i søknaden stemmer ikke med det bankene har
                  funnet når de sjekket dokumentasjon. Dobbeltsjekk at alt
                  stemmer i søknaden din.
                </li>
                <li>
                  Hvis medlåntaker har betalingsanmerkning, gir det automatisk
                  avslag. Prøv på nytt uten medlåntaker.
                </li>
              </ul>
            </Typography>
            <Typography color={COLORS.DARK_BLUE[100]} variant="body">
              Ta kontakt med bankene dersom du har spørsmål til avslag fra den
              enkelte bank.
            </Typography>
          </Stack>
          <Box justifyContent="center" display="flex">
            <Button variant="contained" color="dark" onClick={onSearchAgain}>
              Endre søknad og søk på nytt
            </Button>
          </Box>
        </Stack>
      </Collapse>
    </Stack>
  );
}
