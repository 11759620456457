import { NavigationNames } from "../constants/navigationNames";
import { PartnerProductType } from "../services/nswag/clients/loanApplicationsClient";

export const PageType = {
  PersonalInfo: "PersonalInfo",
  Amount: "Amount",
  Properties: "Properties",
  Debt: "Debt",
  Income: "Income",
  MaritalStatus: "MaritalStatus",
  CoApplicant: "CoApplicant",
  Summary: "Summary",
};

export const ConvertPagePathToType = (
  page: string,
  productType?: PartnerProductType
) => {
  if (productType === PartnerProductType.ConsumerLoan) {
    switch (page) {
      case NavigationNames.SOKNAD.BELOP:
        return PageType.Amount;
      case NavigationNames.SOKNAD.BOLIG:
        return PageType.Properties;
      case NavigationNames.SOKNAD.DEBT_AND_HOUSING:
        return PageType.Debt;
      case NavigationNames.SOKNAD.INCOME:
        return PageType.Income;
      case NavigationNames.SOKNAD.CIVIL_STATUS:
        return PageType.MaritalStatus;
      case NavigationNames.SOKNAD.CO_APPLICANT:
        return PageType.CoApplicant;
      case NavigationNames.SOKNAD.SUMMARY:
        return PageType.Summary;
      default:
        return NavigationNames.SOKNAD.BELOP;
    }
  } else {
    switch (page) {
      case NavigationNames.OMSTARTSLAN.BASE:
        return PageType.Amount;
      case NavigationNames.OMSTARTSLAN.BOLIG:
        return PageType.Properties;
      case NavigationNames.OMSTARTSLAN.GJELD:
        return PageType.Debt;
      case NavigationNames.OMSTARTSLAN.INNTEKT:
        return PageType.Income;
      case NavigationNames.OMSTARTSLAN.SIVILSTATUS:
        return PageType.MaritalStatus;
      case NavigationNames.OMSTARTSLAN.MEDLANTAKER:
        return PageType.CoApplicant;
      case NavigationNames.OMSTARTSLAN.OPPSUMMERING:
        return PageType.Summary;
      default:
        return PageType.Amount;
    }
  }
};

export const ConvertTypeToPagePath = (
  type: string,
  productType?: PartnerProductType
) => {
  if (productType === PartnerProductType.ConsumerLoan) {
    switch (type) {
      case PageType.PersonalInfo:
        return NavigationNames.SOKNAD.BELOP;
      case PageType.Amount:
        return NavigationNames.SOKNAD.BELOP;
      case PageType.Properties:
        return NavigationNames.SOKNAD.BOLIG;
      case PageType.Debt:
        return NavigationNames.SOKNAD.DEBT_AND_HOUSING;
      case PageType.Income:
        return NavigationNames.SOKNAD.INCOME;
      case PageType.MaritalStatus:
        return NavigationNames.SOKNAD.CIVIL_STATUS;
      case PageType.CoApplicant:
        return NavigationNames.SOKNAD.CO_APPLICANT;
      case PageType.Summary:
        return NavigationNames.SOKNAD.SUMMARY;
      default:
        return NavigationNames.SOKNAD.BELOP;
    }
  } else {
    switch (type) {
      case PageType.PersonalInfo:
        return NavigationNames.OMSTARTSLAN.BASE;
      case PageType.Amount:
        return NavigationNames.OMSTARTSLAN.BASE;
      case PageType.Properties:
        return NavigationNames.OMSTARTSLAN.BOLIG;
      case PageType.Debt:
        return NavigationNames.OMSTARTSLAN.GJELD;
      case PageType.Income:
        return NavigationNames.OMSTARTSLAN.INNTEKT;
      case PageType.MaritalStatus:
        return NavigationNames.OMSTARTSLAN.SIVILSTATUS;
      case PageType.CoApplicant:
        return NavigationNames.OMSTARTSLAN.MEDLANTAKER;
      case PageType.Summary:
        return NavigationNames.OMSTARTSLAN.OPPSUMMERING;
      default:
        return NavigationNames.OMSTARTSLAN.BASE;
    }
  }
};
