import { Stack, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../../../../../constants/colors";

interface InfoMessageCardProps {
  message: string;
}

const InfoMessageCard: React.FC<InfoMessageCardProps> = ({ message }) => {
  return (
    <Stack
      bgcolor={COLORS.BRIGHT_BLUE[0]}
      borderRadius="12px"
      border={`1px solid ${COLORS.BLACK[100]}`}
      px="40px"
      py="32px"
      mb={{ xs: "16px", md: "24px" }}
      alignItems="flex-start"
      textAlign="center"
    >
      <Typography
        width="100%"
        variant="body"
        color={COLORS.BLACK[100]}
        textAlign="center"
      >
        {message}
      </Typography>
    </Stack>
  );
};

export default InfoMessageCard;
