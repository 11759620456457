import { Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import COLORS from "../../constants/colors";

interface Props {
  title: string;
}

const SoknadTitle = ({ title }: Props) => {
  const startRef = useRef(null as any);

  useEffect(() => {
    if (startRef.current) {
      startRef.current.focus(); // Set focus to the target element
    }
  }, []);

  return (
    <Typography
      tabIndex={-1}
      ref={startRef}
      aria-live="assertive"
      role="main"
      variant="h2Small"
      color={COLORS.DARK_BLUE[100]}
    >
      {title}
    </Typography>
  );
};

export default SoknadTitle;
