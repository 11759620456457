import { Stack, Typography } from "@mui/material";
import React from "react";
import {
  IPartnerSubmissionClientView,
  PartnerSubmissionState,
} from "../../../../../services/nswag/clients/loanApplicationsClient";
import COLORS from "../../../../../constants/colors";
import PartnerBankWithOffer from "./PartnerBankWithOffer";
import PartnerBankSmallCard from "./PartnerBankSmallCard";
import { comparePartnersByOfferQuality } from "../../../../../utils/sorting";

/**
 * Render a list of offers in the form of cards with info
 * about each offer from partners. Also lists the partners
 * that have yet to respond, and those that rejected the application.
 *
 * @param {IPartnerSubmissionClientView} partners - [List of partners involved]
 */
export default function ConsumerLoanWithOffersView({
  applicationId,
  partners,
}: IProps) {
  const offers = partners
    .filter((p) => p.state === PartnerSubmissionState.OfferGiven)
    .sort(comparePartnersByOfferQuality);

  const rejected = partners.filter(
    (p) => p.state === PartnerSubmissionState.RejectedByPartner
  );

  const pending = partners.filter(
    (p) =>
      p.state !== PartnerSubmissionState.OfferGiven &&
      p.state !== PartnerSubmissionState.RejectedByPartner &&
      p.state !== PartnerSubmissionState.SubmissionFailed
  );

  const renderOffers = () => {
    return offers.map((partner) => (
      <PartnerBankWithOffer
        applicationId={applicationId}
        key={partner.partnerId}
        partner={partner}
      />
    ));
  };

  const renderPending = () => {
    if (pending.length === 0) return null;
    return (
      <Stack gap="16px">
        <Typography variant="h5" color={COLORS.DARK_BLUE[100]}>
          Behandler søknad
        </Typography>
        {pending.map((partner) => (
          <PartnerBankSmallCard
            key={partner.partnerId}
            name={partner.partnerName}
            partnerInfoMessage={partner.partnerInfoMessage}
          />
        ))}
      </Stack>
    );
  };

  const renderRejected = () => {
    if (rejected.length === 0) return null;
    return (
      <Stack gap="16px">
        <Typography variant="h5" color={COLORS.DARK_BLUE[100]}>
          Avslo søknaden din
        </Typography>
        {rejected.map((partner) => (
          <PartnerBankSmallCard
            key={partner.partnerId}
            name={partner.partnerName}
          />
        ))}
      </Stack>
    );
  };

  return (
    <Stack gap="32px">
      {offers?.length > 0 && (
        <Typography variant="lead" color={COLORS.DARK_BLUE[100]}>
          Husk at du alltid kan betale tilbake lånet raskere enn oppgitt
          nedbetalingstid.
        </Typography>
      )}
      {renderOffers()}
      {renderPending()}
      {renderRejected()}
    </Stack>
  );
}

export interface IProps {
  applicationId: string;
  partners: IPartnerSubmissionClientView[];
}
