import { SoknadType } from "../constants/soknadType";

export const LoanType = "loanType";

export interface LoanTypeState {
  loanType: string;
}

export const saveLoanTypeFlow = (type: LoanTypeState) => {
  if (typeof window !== "undefined") {
    window.sessionStorage.setItem(LoanType, JSON.stringify(type));
  }
};

export const getLoanType = () => {
  if (typeof window !== "undefined") {
    const loanType = window.sessionStorage.getItem(LoanType);
    if (!loanType) {
      return {
        loanType: SoknadType.ConsumerLoan,
      };
    }

    return JSON.parse(loanType);
  }
};
export const clearLoanType = () => {
  if (typeof window !== "undefined") {
    window.sessionStorage.removeItem(LoanType);
  }
};

export const switchLoanType = (type: string) => {
  const loanType = getLoanType();
  if (loanType) {
    saveLoanTypeFlow({ loanType: type });
  }
};
