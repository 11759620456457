import { Stack, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../../../constants/colors";
import BirdChilling from "../../../../assets/svgs/birdChilling.svg";
import DownloadAppSection from "../../stepper/DownloadAppSection";
import FormCardStack from "../../FormCardStack";

export default function ConsumerLoanPendingView() {
  return (
    <Stack gap="32px">
      <FormCardStack
        bgcolor={{ md: COLORS.BRIGHT_BLUE[20], xs: COLORS.BRIGHT_BLUE[20] }}
        cardColor={COLORS.BRIGHT_BLUE[20]}
        p={{ md: "48px 72px 96px 72px", xs: "15px 15px 45px 15px" }}
        alignItems="center"
        gap="32px"
        borderRadius="12px"
      >
        <Stack maxWidth="200px" maxHeight="200px" alignItems="center">
          <BirdChilling width="100%" />
        </Stack>
        <Typography variant="h2Small" color={COLORS.DARK_BLUE[100]}>
          Venter på lånetilbud
        </Typography>
      </FormCardStack>
      <Stack
        gap="24px"
        px={{ md: "0px", xs: "0px" }}
        py={{ md: "0px", xs: "0px" }}
      >
        <Typography variant="h4" color={COLORS.DARK_BLUE[100]}>
          Bli sjef i egen økonomi
        </Typography>
        <Typography variant="body" color={COLORS.DARK_BLUE[100]}>
          Alle som har Horde-appen, og lån gjennom oss, er automatisk med i
          trekninger der vi nedbetaler gjeld.
        </Typography>
        <Stack>
          <DownloadAppSection />
        </Stack>
      </Stack>
    </Stack>
  );
}
