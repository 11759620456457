export const getYears = Array.from(
  { length: 71 },
  (_, i) => new Date().getFullYear() - i
);
export const getMonths = Array.from({ length: 12 }, (_, i) => {
  const monthName = new Date(0, i).toLocaleString("no", { month: "long" });
  return {
    name: monthName.charAt(0).toUpperCase() + monthName.slice(1),
    value: i + 1,
  };
});

export const getAvailableMonths = (
  year: number
): { name: string; value: number }[] => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // 1-based index

  return Array.from({ length: 12 }, (_, i) => {
    const monthName = new Date(0, i).toLocaleString("no", { month: "long" });
    return {
      name: monthName.charAt(0).toUpperCase() + monthName.slice(1),
      value: i + 1,
    };
  }).filter((month) => year !== currentYear || month.value <= currentMonth);
};

export const isIncludedMonth = (value: number) => {
  const years = getYears;
  if (value === years[0] || value === years[1]) {
    return true;
  }
  return false;
};
