import React from "react";
import { IMaskInput } from "react-imask";

// Define the props for the custom mask component
interface CustomProps {
  name: string;
  mask: string; // Add mask as a prop to make it dynamic
  inputRef: React.Ref<HTMLInputElement | HTMLTextAreaElement>;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

// Create a custom input component that uses IMaskInput
const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, mask, inputRef, ...other } = props;

    return (
      <IMaskInput
        {...other}
        mask={mask} // Use the dynamic mask prop
        definitions={{
          "0": /[0-9]/, // Define allowed characters
        }}
        // Combine refs from MUI and IMask
        inputRef={(el: HTMLInputElement) => {
          if (typeof ref === "function") {
            ref(el);
          } else if (ref) {
            (ref as React.MutableRefObject<HTMLInputElement | null>).current =
              el;
          }

          if (typeof inputRef === "function") {
            inputRef(el);
          } else if (inputRef) {
            (
              inputRef as React.MutableRefObject<HTMLInputElement | null>
            ).current = el;
          }
        }}
        onAccept={(value: string) =>
          onChange({ target: { name: props.name, value } })
        }
        overwrite
      />
    );
  }
);

export default TextMaskCustom;
