import { Stack, Typography } from "@mui/material";
import React from "react";
import COLORS from "../../../constants/colors";

interface LabelProps {
  label: string;
  isActive?: boolean;
  onClick?: () => void;
  underline?: boolean;
  disableClick?: boolean;
}

const StepperLabel = ({
  label,
  isActive,
  onClick,
  underline,
  disableClick,
}: LabelProps) => {
  return (
    <Stack
      px="6px"
      borderRadius="4px"
      bgcolor={isActive ? COLORS.BRIGHT_BLUE[100] : "inherit"}
      className="stepperLabel"
      onClick={disableClick ? undefined : onClick}
      tabIndex={!underline ? undefined : 0}
      aria-label={isActive ? `Steg ${label} er valgt` : `Velg steg ${label}`}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick && !disableClick && onClick();
        }
      }}
      sx={{ display: { xs: "none", md: "flex" } }}
    >
      <Typography
        variant="label"
        color={isActive ? COLORS.WHITE[100] : COLORS.DARK_BLUE[100]}
        component={underline && !isActive ? "u" : "span"}
        sx={{
          textDecorationThickness: "2px",
        }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

export default StepperLabel;
