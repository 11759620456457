import {
  combineReducers,
  configureStore,
  createAction,
  ThunkAction,
  UnknownAction,
} from "@reduxjs/toolkit";
import customerReducer from "./reducers/customerReducer";
import debtReducer from "./reducers/debtReducer";
import loanApplicationReducer from "./reducers/loanApplicationReducer";
import stepsReducer from "./reducers/stepsReducer";
import webSocketListener from "./middlewares/websockets/webSocketListener";
import submittedApplicationsReducer from "./reducers/submittedApplications/submittedApplicationsReducer";
import acceptedOfferReducer from "./reducers/acceptedOfferReducer";

export const logout = createAction("logout");

const rootReducer = combineReducers({
  customerReducer,
  debtReducer,
  loanApplicationReducer,
  stepsReducer,
  submittedApplications: submittedApplicationsReducer,
  acceptedApplication: acceptedOfferReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      webSocketListener
    ),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, UnknownAction>;
export default store;
