import { Stack } from "@mui/material";
import React from "react";
import COLORS from "../../../constants/colors";
import RoundedCheck from "../../../assets/svgs/RoundedCheck.svg";
import StepperLabel from "./StepperLabel";
import UpcommingStep from "./UpcommingStep";

interface ClickableStepProps {
  onClick: () => void;
  isActive: boolean;
  isCurrentStep: boolean;
  completed: boolean;
  disableClick?: boolean;
  nextStepClickable?: boolean;
}

interface StepProps {
  label: string;
}

const CustomStep = ({
  label,
  onClick,
  isActive,
  isCurrentStep,
  completed,
  nextStepClickable,
  disableClick = false,
}: StepProps & ClickableStepProps) => {
  if (!completed && !isCurrentStep && !nextStepClickable) {
    return <UpcommingStep label={label} />;
  }

  return (
    <Stack
      alignItems="center"
      gap="6px"
      sx={{
        ".stepperLabel:hover": !disableClick
          ? {
              bgcolor: COLORS.DARK_BLUE[100],
              cursor: "pointer",
              "& + .stepperIconContainer": {
                bgcolor: COLORS.DARK_BLUE[100],
                "& .stepperIcon": {
                  borderColor: COLORS.DARK_BLUE[100],
                },
                "& .stepperIcon>div": {
                  backgroundColor: COLORS.DARK_BLUE[100],
                },
              },
              "& .MuiTypography-root": {
                color: COLORS.WHITE[100],
                textDecoration: "none",
              },
            }
          : {},
      }}
    >
      <StepperLabel
        label={label}
        isActive={isActive}
        onClick={onClick}
        disableClick={disableClick}
        underline
      />
      {isCurrentStep || nextStepClickable ? (
        <Stack
          width={{ xs: "16px", md: "24px" }}
          height={{ xs: "16px", md: "24px" }}
          borderRadius="50%"
          justifyContent="center"
          alignItems="center"
          zIndex={1}
          className="stepperIconContainer"
        >
          <Stack
            width={{ xs: "16px", md: "24px" }}
            height={{ xs: "16px", md: "24px" }}
            borderRadius="50%"
            border={`2px solid ${COLORS.BRIGHT_BLUE[100]}`}
            alignItems="center"
            justifyContent="center"
            bgcolor="#FFF"
            className="stepperIcon"
          >
            <Stack
              bgcolor={COLORS.BRIGHT_BLUE[100]}
              width={{ xs: "8px", md: "10px" }}
              height={{ xs: "8px", md: "10px" }}
              borderRadius="50%"
            />
          </Stack>
        </Stack>
      ) : (
        <Stack
          width={{ xs: "16px", md: "24px" }}
          height={{ xs: "16px", md: "24px" }}
          borderRadius="50%"
          bgcolor={COLORS.BRIGHT_BLUE[100]}
          justifyContent="center"
          alignItems="center"
          zIndex={1}
          p={{ xs: "3px", md: "0px" }}
          className="stepperIconContainer"
        >
          <RoundedCheck />
        </Stack>
      )}
    </Stack>
  );
};
export default CustomStep;
