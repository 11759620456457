import { useSelector } from "react-redux";
import { goForwardUntilInStepper } from "../utils/navigationLoanTypeUtils";
import { getStepper } from "../redux/reducers/stepsReducer";

function useStepper() {
  const steps = useSelector(getStepper);

  const getNextPage = () => {
    if (typeof window !== "undefined") {
      const currentPage = window.location.pathname;
      const index = steps.findIndex((step) => step.url === currentPage);
      if (index === -1) {
        const notCompletedStep = steps.find((step) => !step.completed);
        return notCompletedStep?.url ?? "";
      }

      if (index + 1 < steps.length) {
        return goForwardUntilInStepper(steps, index);
      }
    }
    return "";
  };

  const currentStep = () => {
    if (typeof window !== "undefined") {
      const currentPage = window.location.pathname;
      return steps.find((step) => step.url === currentPage);
    }
    return "";
  };

  const currentPage = () => {
    if (typeof window !== "undefined") {
      return window.location.pathname;
    }
    return "";
  };

  return {
    currentPage,
    getNextPage,
    steps,
    currentStep,
  };
}
export default useStepper;
