import React, { useState } from "react";
import { Stack } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import ButtonToggleGroup from "../../ButtonToggle";
import COLORS from "../../../constants/colors";

const DownloadAppSection = ({
  alignItems = "center",
}: {
  alignItems?: "center" | "flex-start";
}) => {
  const [osVersion, setOsVersion] = useState(
    "https://apps.apple.com/us/app/horde/id1471960734?ls=1"
  );

  return (
    <Stack gap="32px" alignItems={alignItems}>
      <ButtonToggleGroup
        buttons={[
          {
            label: "iPhone",
            value: "https://apps.apple.com/us/app/horde/id1471960734?ls=1",
          },
          {
            label: "Android",
            value: "https://play.google.com/store/apps/details?id=com.horde",
          },
          {
            label: "Huawei",
            value: "https://appgallery.huawei.com/#/app/C102218503",
          },
        ]}
        onSelectedChange={setOsVersion}
        value={osVersion}
      />
      <QRCodeSVG
        value={osVersion}
        size={200}
        bgColor="#FFF"
        fgColor={COLORS.DARK_BLUE[100]}
      />
    </Stack>
  );
};
export default DownloadAppSection;
