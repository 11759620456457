import { Link, Stack } from "@mui/material";
import React, { useState } from "react";
import Trash from "../../assets/svgs/trash.svg";
import DeleteApplicationModal from "../layout/DeleteApplicationModal";
import { useAppSelector } from "../../hooks/reduxHooks";
import { TabState } from "../../pages/soknad/tilbud";

const CancelApplicationButton = () => {
  const [open, setOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const activeTabState = useAppSelector(
    (state) => state.submittedApplications.activeTabState
  );

  if (activeTabState === TabState.RestartLoan) {
    return null;
  }

  const handleClose = () => setOpen(false);

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Trash />
        <Link
          onClick={() => {
            setOpen(true);
          }}
          color="primary"
          variant="label"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              setInfoModalOpen(true);
            }
          }}
        >
          Slett søknad
        </Link>
      </Stack>
      <DeleteApplicationModal open={open} handleClose={handleClose} />
    </>
  );
};

export default CancelApplicationButton;
