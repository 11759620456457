import { format, isFuture } from "date-fns";
import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import sanity from "../constants/sanity";

export function cn(...args: any[]) {
  return args.filter(Boolean).join(" ");
}

export function mapEdgesToNodes(data: { edges: any[] }) {
  if (!data.edges) return [];
  return data.edges.map((edge) => edge.node);
}

export function filterOutDocsWithoutSlugs({ slug }) {
  return (slug || {}).current;
}

export function filterOutDocsPublishedInTheFuture({ publishedAt }) {
  return !isFuture(publishedAt);
}

export function getBlogUrl(publishedAt: number | Date, slug: { current: any }) {
  return `/artikler/${format(publishedAt, "yyyy/MM")}/${slug.current || slug}/`;
}

export function buildImageObj(source) {
  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}

const builder = imageUrlBuilder({
  projectId: sanity.PROJECT_ID,
  dataset: "blogg",
});

export function imageUrlFor(source: SanityImageSource) {
  return builder.image(source);
}

// Function to get three random items from the array
export function getRandomItems(arr: object[], numItems: number) {
  if (!arr) {
    return [];
  }
  // Randomize the array order
  arr.sort(() => Math.random() - 0.5);

  // Return the first numItems elements
  return arr.slice(0, numItems);
}

export const truncateText = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return `${text.substring(0, maxLength)}...`;
};

/**
 * Formaterer et tall eller en `bigint` til en valutaformatert streng i henhold til norsk lokalitet ("nb-NO").
 * Funksjonen avgjør automatisk om desimaler skal vises basert på om tallet har desimaler og om brukeren har spesifisert antall desimaler.
 */
export const formatCurrency = (
  value: number | bigint,
  decimals?: number
): string => {
  const hasDecimals = typeof value === "number" && !Number.isInteger(value);

  const userSpecifiedDecimals = decimals !== undefined;

  const fractionDigits =
    hasDecimals || userSpecifiedDecimals ? decimals ?? 2 : 0;

  return new Intl.NumberFormat("nb-NO", {
    style: "decimal",
    currency: "NOK",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(value);
};

export const calculateEMI = (
  principal: number,
  rate: number,
  years: number
) => {
  // Calculation stuff
  const monthlyRate = rate / 12;
  const numberOfPayments = years * 12;
  const numerator =
    principal * monthlyRate * (1 + monthlyRate) ** numberOfPayments;
  const denominator = (1 + monthlyRate) ** numberOfPayments - 1;

  return numerator / denominator;
};

export const formatYearsAndMonths = (months: number) => {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  let result = "";

  if (years > 0) {
    result += `${years} år`;
  }

  if (remainingMonths > 0) {
    if (years > 0) {
      result += " og ";
    }
    result += `${remainingMonths} ${
      remainingMonths === 1 ? "måned" : "måneder"
    }`;
  }

  if (result === "") {
    result = "0 måneder";
  }

  return result;
};
