import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { logout, RootState } from "../store";
import { DebtState } from "../classes/debt";
import { FetchDebtResponse } from "../../services/api/debt";
import { PersonScoreResp } from "../../services/api/creditscore";

export const initialState: DebtState = {
  loans: [],
  personScore: {} as PersonScoreResp,
  personScoreIsFetched: false,
  debtIsFetched: false,
};

const debtSlice = createSlice({
  name: "debt",
  initialState,
  reducers: {
    setDebt: (state, action: PayloadAction<FetchDebtResponse>) => {
      const { payload } = action;
      state.debtIsFetched = true;
      state.loans = payload.loans;
    },
    setPersonScore: (state, action: PayloadAction<PersonScoreResp>) => {
      const { payload } = action;
      state.personScore = payload;
      state.personScoreIsFetched = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, () => {
      return initialState; // Reset to initial state
    });
  },
});

export const getPersonScore = (state: RootState) =>
  state.debtReducer.personScore as PersonScoreResp;

export const { setDebt, setPersonScore } = debtSlice.actions;

export default debtSlice.reducer;
