import React from "react";
import { NumericFormat } from "react-number-format";

const CustomNumericFormat = (props: any) => {
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = event.clipboardData.getData("Text");

    // Remove decimals and truncate at the decimal point
    const sanitizedValue = pastedValue.split(/[.,]/)[0].replace(/[^\d]/g, "");
    // Prevent default paste behavior
    event.preventDefault();

    const newValue = sanitizedValue;

    if (!Number.isNaN(Number(newValue))) {
      props?.onValueChange({
        value: newValue,
        floatValue: Number(newValue),
      });
    }
  };

  return <NumericFormat onPaste={handlePaste} {...props} />;
};

export default CustomNumericFormat;
