import {
  Button,
  Checkbox,
  Collapse,
  Modal,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Send } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import COLORS from "../../constants/colors";
import AssistantBird from "../../assets/svgs/assistantBird.svg";
import ExclamationMark from "../../assets/svgs/loan/exclamationMark.svg";
import ThumbUp from "../../assets/svgs/thumbUp.svg";
import ClickableStack from "../ClickableStack";
import TextInput from "../TextInput";
import { GetLoanApplicationClient } from "../../services/axiosInstance";
import {
  CancellationReasonType,
  CancelLoanApplicationRequest,
  Reason,
} from "../../services/nswag/clients/loanApplicationsClient";
import { logout } from "../../redux/store";
import { useAppSelector } from "../../hooks/reduxHooks";
import { selectCurrentlyViewedApplication } from "../../redux/reducers/submittedApplications/submittedApplicationsReducer";
import { allBankAnswered } from "../../utils/loanApplicationUtils";

interface DeleteApplicationModalProps {
  open: boolean;
  handleClose: () => void;
}

const DeleteApplicationModal = ({
  open,
  handleClose,
}: DeleteApplicationModalProps) => {
  const dispatch = useDispatch();
  const application = useAppSelector(selectCurrentlyViewedApplication);
  const [pageIndex, setPageIndex] = useState(0);
  const [selectedReasons, setSelectedReasons] = useState<
    CancellationReasonType[]
  >([]);
  const loanApplicationClient = GetLoanApplicationClient();
  const [reasonString, setReasonString] = useState("");
  const allBankAnswer = allBankAnswered(application?.partners || []);

  const apiCancelLoanApplication = (
    selected: CancellationReasonType[] = []
  ) => {
    if (!application) return;

    loanApplicationClient
      .cancelLoanApplication(
        application.applicationId,
        new CancelLoanApplicationRequest({
          reason: new Reason({
            cancellationReasons:
              selected.length > 0 ? selected : selectedReasons,
            userDefinedReason: reasonString,
          }),
        })
      )
      .then(() => {}); // TODO catch/do something
  };

  const availableReasons = [
    {
      id: 0,
      value: CancellationReasonType.InterestRate,
      text: "Renten var dårligere enn den jeg hadde fra før",
    },
    {
      id: 1,
      value: CancellationReasonType.RepaymentPeriod,
      text: "Jeg vil ha en annen nedbetalingstid",
    },
    {
      id: 2,
      value: CancellationReasonType.LoanAmount,
      text: "Tilbudt lånebeløp er ikke som forventet",
    },
    {
      id: 3,
      value: CancellationReasonType.ReceivedBetterOfferElsewhere,
      text: "Jeg fikk et bedre tilbud et annet sted",
    },
    {
      id: 4,
      value: CancellationReasonType.NoneOfTheAbove,
      text: "Ingen av de over",
    },
  ];

  const handleCloseModal = () => {
    handleClose();
    setPageIndex(0);
    setSelectedReasons([]);
  };

  const toggleReason = (reason: CancellationReasonType) => {
    if (reason === CancellationReasonType.NoneOfTheAbove) {
      if (selectedReasons.includes(reason)) {
        setSelectedReasons([]);
      } else {
        setSelectedReasons([CancellationReasonType.NoneOfTheAbove]);
        // setReason("");
      }
      return;
    }
    if (selectedReasons.includes(CancellationReasonType.NoneOfTheAbove)) {
      setSelectedReasons([reason]);
      return;
    }
    if (selectedReasons.includes(reason)) {
      setSelectedReasons(selectedReasons.filter((r) => r !== reason));
    } else {
      setSelectedReasons([...selectedReasons, reason]);
    }
  };

  const handleReasonDeleteClick = () => {
    if (
      selectedReasons.includes(CancellationReasonType.RepaymentPeriod) ||
      selectedReasons.includes(CancellationReasonType.LoanAmount)
    ) {
      setPageIndex(2);
    } else {
      apiCancelLoanApplication();
      setPageIndex(3);
    }
  };

  const handleSkipClick = () => {
    apiCancelLoanApplication([CancellationReasonType.NotSelected]);
    setPageIndex(3);
  };

  const pages = [
    <Stack
      key={0}
      py={{ xs: "12px", md: "24px" }}
      px={{ xs: "12px", md: "24px" }}
    >
      <Stack alignItems="flex-end">
        <ClickableStack onClick={handleCloseModal}>
          <CancelIcon
            color="primary"
            style={{ height: "64px", width: "64px" }}
          />
        </ClickableStack>
      </Stack>
      <Stack gap={{ xs: "16px", md: "32px" }}>
        <Stack alignItems="center" justifyContent="center" flexGrow={1}>
          <Stack maxWidth="200px" maxHeight="200px">
            <AssistantBird width="100%" />
          </Stack>
        </Stack>

        {!allBankAnswer ? (
          <Typography
            variant="h4"
            color={COLORS.NORMAL[100]}
            textAlign="center"
          >
            Du kan ikke slette søknaden enda. Når alle bankene har gitt deg
            tilbakemelding vil du kunne slette din søknad.
          </Typography>
        ) : (
          <Typography
            variant="h3"
            color={COLORS.NORMAL[100]}
            textAlign="center"
          >
            Er du helt sikker?
          </Typography>
        )}
        {allBankAnswer && (
          <Stack alignItems="center">
            <Button
              variant="contained"
              color="dark"
              onClick={() => setPageIndex(1)}
            >
              Ja, slett søknad
            </Button>
          </Stack>
        )}
        <Stack alignItems="center">
          <ClickableStack onClick={handleCloseModal} p="8px">
            <Typography
              variant="bodySmall"
              component="u"
              color={COLORS.NORMAL[100]}
            >
              Avbryt
            </Typography>
          </ClickableStack>
        </Stack>
      </Stack>
    </Stack>,
    <Stack
      key={1}
      py={{ xs: "12px", md: "24px" }}
      px={{ xs: "12px", md: "24px" }}
    >
      <Stack alignItems="flex-end">
        <ClickableStack onClick={handleCloseModal}>
          <CancelIcon
            color="primary"
            style={{ height: "64px", width: "64px" }}
          />
        </ClickableStack>
      </Stack>
      <Stack gap={{ xs: "16px", md: "32px" }}>
        <Typography variant="h3" color={COLORS.NORMAL[100]} textAlign="center">
          Slett søknad
        </Typography>
        <Typography
          variant="lead"
          color={COLORS.NORMAL[100]}
          textAlign="center"
        >
          Hvorfor vil du slette den?
        </Typography>
        <Stack gap="16px">
          {availableReasons
            .filter((x) => x.value !== CancellationReasonType.NoneOfTheAbove)
            .map((r) => (
              <ClickableStack
                key={r.id}
                direction="row"
                borderRadius="12px"
                border="1px solid #000"
                gap="8px"
                p="16px"
                bgcolor="#FFF"
                alignItems="center"
                onClick={() => {
                  toggleReason(r.value);
                }}
              >
                <Checkbox
                  checked={selectedReasons.includes(r.value)}
                  sx={{
                    padding: 0,
                  }}
                />
                <Typography variant="bodySmall" color={COLORS.NORMAL[100]}>
                  {r.text}
                </Typography>
              </ClickableStack>
            ))}
          <ClickableStack
            key={4}
            direction="row"
            borderRadius="12px"
            border="1px solid #000"
            gap="8px"
            p="16px"
            bgcolor="#FFF"
            alignItems="center"
            onClick={() => {
              setSelectedReasons([]);
              toggleReason(CancellationReasonType.NoneOfTheAbove);
            }}
          >
            <Radio
              checked={selectedReasons.includes(
                CancellationReasonType.NoneOfTheAbove
              )}
              sx={{
                padding: 0,
              }}
            />
            <Typography variant="bodySmall" color={COLORS.NORMAL[100]}>
              {availableReasons.find(
                (x) => x.value === CancellationReasonType.NoneOfTheAbove
              )?.text ?? ""}
            </Typography>
          </ClickableStack>
          <Collapse
            in={selectedReasons.includes(CancellationReasonType.NoneOfTheAbove)}
          >
            <TextInput
              id="ssn"
              label="Oppgi grunn (valgfritt)"
              variant="outlined"
              type="text"
              slotProps={{
                htmlInput: {
                  maxLength: 500,
                },
              }}
              value={reasonString}
              onChange={(e) => setReasonString(e.target.value)}
            />
          </Collapse>
        </Stack>
        <Stack paddingBottom={{ xs: "12px", md: "24px" }}>
          {selectedReasons.length === 0 ? (
            <Stack alignItems="center">
              <Button
                variant="contained"
                color="dark"
                onClick={handleSkipClick}
              >
                Hopp over
              </Button>
            </Stack>
          ) : (
            <Stack alignItems="center">
              <Button
                variant="contained"
                color="dark"
                onClick={handleReasonDeleteClick}
              >
                Slett søknaden
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>,
    <Stack
      key={2}
      py={{ xs: "12px", md: "24px" }}
      px={{ xs: "12px", md: "24px" }}
    >
      <Stack alignItems="flex-end">
        <ClickableStack onClick={handleCloseModal}>
          <CancelIcon
            color="primary"
            style={{ height: "64px", width: "64px" }}
          />
        </ClickableStack>
      </Stack>
      <Stack gap={{ xs: "16px", md: "32px" }}>
        <Stack alignItems="center" justifyContent="center" flexGrow={1}>
          <Stack maxWidth="200px" maxHeight="200px">
            <ExclamationMark width="100%" />
          </Stack>
        </Stack>
        <Typography variant="h3" color={COLORS.NORMAL[100]} textAlign="center">
          Nedbetalingstid og lånebeløp kan vi gjøre noe med!
        </Typography>
        <Typography
          variant="lead"
          color={COLORS.NORMAL[100]}
          textAlign="center"
        >
          Send en e-post til Horde om hva du ønsker endret, så kan vi forhandle
          med banken for deg.
        </Typography>
        <Stack alignItems="center">
          <Button
            variant="contained"
            color="dark"
            onClick={(e) => {
              window.location.href = `mailto:${process.env.CONTACT_EMAIL}`;
              e.preventDefault();
              handleCloseModal();
            }}
            endIcon={<Send />}
          >
            Send oss en e-post
          </Button>
          <Typography
            sx={{ marginTop: "10px" }}
            variant="bodySmall"
            color={COLORS.NORMAL[100]}
            textAlign="center"
          >
            {process.env.CONTACT_EMAIL}
          </Typography>
        </Stack>
        <Stack paddingBottom={{ xs: "12px", md: "24px" }} alignItems="center">
          <ClickableStack
            onClick={() => {
              apiCancelLoanApplication();
              setPageIndex(3);
            }}
          >
            <Typography
              variant="bodySmall"
              component="u"
              color={COLORS.NORMAL[100]}
            >
              Jeg vil slette søknaden
            </Typography>
          </ClickableStack>
        </Stack>
      </Stack>
    </Stack>,
    <Stack
      key={4}
      py={{ xs: "24px", md: "48px" }}
      px={{ xs: "12px", md: "24px" }}
    >
      <Stack gap={{ xs: "16px", md: "32px" }}>
        <Stack alignItems="center" justifyContent="center" flexGrow={1}>
          <Stack maxWidth="200px" maxHeight="200px">
            <ThumbUp width="100%" />
          </Stack>
        </Stack>
        <Typography variant="h3" color={COLORS.NORMAL[100]} textAlign="center">
          Søknaden er nå slettet
        </Typography>
        <Stack alignItems="center">
          <Button
            variant="contained"
            color="dark"
            onClick={() => {
              dispatch(logout());
            }}
          >
            Logg ut
          </Button>
        </Stack>
      </Stack>
    </Stack>,
  ];

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        position="absolute"
        top="50%"
        left="50%"
        width="100%"
        maxWidth="600px"
        bgcolor="#FFF"
        p={{ xs: "24px", md: "48px" }}
        borderRadius="12px"
        sx={{
          transform: "translate(-50%, -50%)",
        }}
      >
        <Stack bgcolor={COLORS.BRIGHT_BLUE[10]} borderRadius="12px">
          {pages[pageIndex]}
        </Stack>
      </Stack>
    </Modal>
  );
};

export default DeleteApplicationModal;
