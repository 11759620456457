import React from "react";
import ConsumerLoanPendingView from "./ConsumerLoanPendingView";
import ConsumerLoanWithOffersView from "./ConsumerLoanWithOffersView";
import { IApplicationDetails } from "../../../../redux/reducers/submittedApplications/ISubmittedApplicationsState";
import { PartnerSubmissionState } from "../../../../services/nswag/clients/loanApplicationsClient";

export default function ActiveConsumerLoanView({ application }: IProps) {
  if (!application) return null;
  const { partners } = application;

  const anyOffers = partners.some((a) => a.hasOffer);

  const rejected = partners.filter(
    (p) => p.state === PartnerSubmissionState.RejectedByPartner
  );

  if (!anyOffers && rejected.length === 0) return <ConsumerLoanPendingView />;

  return (
    <ConsumerLoanWithOffersView
      applicationId={application.applicationId}
      partners={application.partners}
    />
  );
}

export interface IProps {
  application: IApplicationDetails;
}
