import { add } from "date-fns";
import { setAuthorizationHeader } from "./axiosInstance";
import { TokenResponse } from "./api/auth";
import { saveLoanTypeFlow } from "../utils/loanTypeHandler";

export const AccessToken = "horde_accessToken";
export interface Credentials {
  accessToken: string;
  expireTime: string;
}
export const saveToken = (token: TokenResponse, loanType: string): void => {
  setAuthorizationHeader(token.access_token);
  try {
    const expired = token.expires_in;
    const credentials: Credentials = {
      accessToken: token.access_token,
      expireTime: add(new Date(), { seconds: expired }).toISOString(),
    };
    localStorage.setItem(AccessToken, JSON.stringify(credentials));
    if (loanType) {
      saveLoanTypeFlow({ loanType });
    }
  } catch (error) {
    console.log(error);
  }
};

export const clearSessionStorage = (): any => {
  if (typeof window !== "undefined") {
    window.localStorage.removeItem(AccessToken);
  }
};

export const getToken = (): string | undefined => {
  if (typeof window !== "undefined") {
    try {
      const stringified = window.localStorage.getItem(AccessToken);
      if (!stringified) return undefined;

      const credentials: Credentials = JSON.parse(stringified);
      if (new Date(credentials.expireTime) > new Date()) {
        setAuthorizationHeader(credentials.accessToken);
        return credentials.accessToken;
      }
      clearSessionStorage();
      return undefined;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
};

export const isExpiredToken = (): boolean | undefined => {
  try {
    const stringified = localStorage.getItem(AccessToken);
    if (!stringified) return undefined;

    const credentials: Credentials = JSON.parse(stringified);
    return new Date(credentials.expireTime) <= new Date();
  } catch {
    return undefined;
  }
};

export function parseJwt(token: string) {
  // Split token into its parts
  const base64Url = token.split(".")[1];
  // Base64 URL decode
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join("")
  );

  // Parse JSON string into a JavaScript object
  return JSON.parse(jsonPayload);
}
