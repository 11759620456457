import { Collapse, InputAdornment } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { HousingSituation } from "../../../../services/nswag/clients/loanApplicationsClient";
import CustomNumericFormat from "../../../CustomNumericFormat";
import TextInput from "../../../TextInput";
import { errorPreFixNamingCoApplicant } from "../../../../pages/soknad/forbrukslan/medlantaker";
import { InputFieldsCoApplicant } from "../../../../constants/inputFieldNamesSoknad";
import { handleNumberRangeInputValidation } from "../../../../utils/warningValidationUtils";
import useCoApplicant from "../../../../hooks/useCoApplicant";

const RentExpenseInput = () => {
  const { coApplicant, handleUpdate } = useCoApplicant();
  const dispatch = useDispatch();

  return (
    <Collapse in={coApplicant?.housingSituation === HousingSituation.Rents}>
      <CustomNumericFormat
        allowNegative={false}
        label="Hva betaler medlåntaker i husleie i måneden?"
        thousandSeparator=" "
        customInput={TextInput}
        errorText="Oppgi beløp"
        type="text"
        variant="outlined"
        onValueChange={(value) => {
          handleUpdate({
            target: {
              name: `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.rentExpense}`,
              value: value.floatValue,
            },
          });
        }}
        onBlur={() => {
          dispatch(
            handleNumberRangeInputValidation(
              1000,
              50000,
              coApplicant.rentExpense ?? 0,
              `${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.rentExpense}`
            )
          );
        }}
        name={`${errorPreFixNamingCoApplicant}${InputFieldsCoApplicant.rentExpense}`}
        value={coApplicant?.rentExpense}
        slotProps={{
          htmlInput: {
            maxLength: 7,
            inputMode: "numeric",
          },
          input: {
            sx: {
              maxWidth: "150px",
            },
            endAdornment: <InputAdornment position="end">kr</InputAdornment>,
          },
        }}
      />
    </Collapse>
  );
};

export default RentExpenseInput;
