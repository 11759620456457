import { PartnerProductType } from "../services/nswag/clients/loanApplicationsClient";

export const SoknadType = {
  Restartloan: "omstartslan",
  ConsumerLoan: "forbrukslan",
  refinance: "refinansiering",
  CreditCard: "kredittkort",
};

export const SoknadTypeToProductType = (type: string) => {
  if (SoknadType.Restartloan === type) {
    return PartnerProductType.RestartLoan;
  }
  if (SoknadType.ConsumerLoan === type) {
    return PartnerProductType.ConsumerLoan;
  }
  if (SoknadType.CreditCard === type) {
    return PartnerProductType.CreditCard;
  }
  return "";
};
