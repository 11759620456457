import { Stack } from "@mui/material";
import React from "react";
import COLORS from "../../../constants/colors";

/**
 * Basic white card that is used as the main container for the application
 * status view
 */
export default function StatusViewContainerCardStack({
  children,
}: {
  children: React.JSX.Element;
}) {
  return (
    <Stack
      gap="24px"
      bgcolor={COLORS.TRUE_WHITE[100]}
      boxShadow={{ md: "0px 2px 8px rgba(0, 0, 0, 0.2)" }}
      borderRadius="12px"
      py={{ xs: "8px", md: "48px" }}
      px={{ xs: "8px", md: "40px" }}
    >
      {children}
    </Stack>
  );
}
