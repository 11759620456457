import React from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { getToken, isExpiredToken } from "../../services/accessTokenHandler";
import { NavigationNames } from "../../constants/navigationNames";
import InactivityTimer from "../InactivityTimer";

interface Props {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: Props) => {
  const isLoggedIn = useSelector(
    (state: any) => state.customerReducer.loggedIn
  );

  if (!isLoggedIn) {
    const token = getToken();
    if (typeof window !== "undefined") {
      if (token) {
        navigate(NavigationNames.SOKNAD.FETCHING_LOADING);
        return null;
      }

      navigate(NavigationNames.SOKNAD.BASE);
      return null;
    }
  }

  const handleInactive = () => {
    if (isLoggedIn) {
      if (isExpiredToken()) {
        navigate(NavigationNames.InActive);
      }
    }
  };

  return (
    <>
      <InactivityTimer onInactive={handleInactive} />
      {children}
    </>
  );
};

export default PrivateRoute;
