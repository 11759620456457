import { Box } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import COLORS from "../../constants/colors";

/**
 * Card component to use when composing individual components together
 * as a cohesive unit in our application form pages.
 *
 * @param {IFormCardProps} Component Properties - Properties on this component
 * @param {ReactNode[] | ReactNode} FormCard.children - ReactNode children
 * @param {string?} FormCard.cardColor - Color of the card background
 * @param {boolean?} FormCard.bordered - The card is bordered by a black border
 * @param {boolean?} [FormCard.shadowed] - The card has a black drop shadow
 * @returns {ReactElement} react TSX component
 */
const FormCard = ({
  children,
  cardColor = COLORS.TRUE_WHITE[100],
  bordered = false,
  shadowed = false,
}: IFormCardProps): ReactElement => {
  return (
    <Box
      bgcolor={{ md: cardColor }}
      border={{ md: bordered ? "1px solid" : "initial" }}
      borderColor={COLORS.TRUE_BLACK[100]}
      boxShadow={{
        md: shadowed ? "0px 2px 8px rgba(0, 0, 0, 0.2)" : "initial",
      }}
      borderRadius="12px"
    >
      {children}
    </Box>
  );
};
export default FormCard;

export interface IFormCardProps {
  children: ReactNode[] | ReactNode;
  cardColor?: string;
  bordered?: boolean;
  shadowed?: boolean;
}
