import React from "react";
import { Stack, Typography } from "@mui/material";
import COLORS from "../../../../../constants/colors";
import PartnerLogoCircle from "../../../PartnerLogoCircle";
import InfoMessageCard from "./PartnerBankWithOffer/InfoMessageCard";

export default function PartnerBankSmallCard({
  name,
  partnerInfoMessage,
}: {
  name: string;
  partnerInfoMessage?: string;
}) {
  return (
    <Stack bgcolor={COLORS.BRIGHT_BLUE[20]} borderRadius="12px">
      <Stack direction="row" alignItems="center" py="16px" px="34px" gap="16px">
        <PartnerLogoCircle partnerName={name} />
        <Typography variant="body" color={COLORS.DARK_BLUE[100]}>
          {name}
        </Typography>
      </Stack>
      {partnerInfoMessage && (
        <Stack py="8px" px="34px">
          <InfoMessageCard message={partnerInfoMessage} />
        </Stack>
      )}
    </Stack>
  );
}
