import {
  Stack,
  Select,
  SelectProps,
  Typography,
  FormControl,
  MenuItem,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import COLORS from "../constants/colors";
import { getLoanApplication } from "../redux/reducers/loanApplicationReducer";

// Define the props for the custom select component
type CustomSelectProps = SelectProps & {
  description?: string;
  descriptionColor?: string;
  label?: string;
  labelColor?: string;
  maxWidth?: string;
  width?: string;
  variant?: "standard" | "outlined" | "filled";
};

// Create a custom select component that uses Select
const SelectInput: React.FC<CustomSelectProps> = ({
  maxWidth,
  width,
  description,
  label,
  descriptionColor = COLORS.NORMAL[100],
  labelColor = COLORS.NORMAL[100],
  variant = "outlined",
  children,
  ...rest
}) => {
  const { errorHandling } = useSelector(getLoanApplication);

  const hasError = rest.error ?? errorHandling[rest.name];

  const createDefaultText = () => {
    if (label) {
      return `Velg ${label?.toLowerCase()}`;
    }
    return `Velg`;
  };

  const defaultValue = () => {
    if (rest?.value === undefined) {
      return (
        <MenuItem value={undefined} disabled>
          {createDefaultText()}
        </MenuItem>
      );
    }
    if (rest?.value === null) {
      return (
        <MenuItem value={null} disabled>
          {createDefaultText()}
        </MenuItem>
      );
    }
    if (rest?.value === 0) {
      return (
        <MenuItem value={0} disabled>
          {createDefaultText()}
        </MenuItem>
      );
    }
  };

  return (
    <FormControl
      sx={{ gap: "4px", maxWidth, width }}
      className={hasError && "hasValidationError"}
    >
      <Stack gap="2px">
        {label && (
          <Typography
            component="label"
            aria-hidden
            variant="inputLabel"
            color={labelColor}
            htmlFor={rest.id || rest.name}
          >
            {label}
          </Typography>
        )}
        {description && (
          <Typography variant="inputDescription" color={descriptionColor}>
            {description}
          </Typography>
        )}
      </Stack>
      {/* Render the Select component */}
      <Select
        aria-label={label}
        error={errorHandling[rest.name]}
        fullWidth
        variant={variant}
        {...rest}
        // Ensure the select is associated with the label for accessibility
        inputProps={{
          id: rest.id || rest.name,
          "aria-labelledby": rest.id || rest.name,
          ...rest.inputProps,
        }}
        displayEmpty
        MenuProps={{
          PaperProps: {
            sx: {},
          },
        }}
      >
        {defaultValue()}
        {children}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
