import React from "react";
import { Stack, Typography } from "@mui/material";
import COLORS from "../../constants/colors";
import ClickableStack from "../ClickableStack";

interface LoanToggleButtonProps {
  active: 0 | 1;
  onClick: (active: 0 | 1) => void;
}

const LoanToggleButton = ({ active, onClick }: LoanToggleButtonProps) => {
  return (
    <Stack direction="row">
      <ClickableStack
        onClick={() => {
          onClick(0);
        }}
        justifyContent="center"
        alignItems="center"
        border={`solid 2px ${COLORS.DARK_BLUE[100]}`}
        borderRadius="12px 0px 0px 12px"
        py="10px"
        px="20px"
        bgcolor={active === 0 ? COLORS.DARK_BLUE[100] : COLORS.TRANSPARENT[100]}
      >
        <Typography
          fontSize="18px"
          fontWeight="600"
          color={active === 0 ? COLORS.WHITE[100] : COLORS.DARK_BLUE[100]}
        >
          Forbrukslån
        </Typography>
      </ClickableStack>
      <ClickableStack
        onClick={() => {
          onClick(1);
        }}
        justifyContent="center"
        alignItems="center"
        border={`solid 2px ${COLORS.DARK_BLUE[100]}`}
        borderRadius="0px 12px 12px 0px"
        py="10px"
        px="20px"
        bgcolor={active === 1 ? COLORS.DARK_BLUE[100] : COLORS.TRANSPARENT[100]}
      >
        <Typography
          fontSize="18px"
          fontWeight="600"
          color={active === 1 ? COLORS.WHITE[100] : COLORS.DARK_BLUE[100]}
        >
          Lån med sikkerhet i bolig
        </Typography>
      </ClickableStack>
    </Stack>
  );
};

export default LoanToggleButton;
