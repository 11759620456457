import {
  IApplicationOfferClientView,
  IPartnerSubmissionClientView,
  OfferQuality,
} from "../services/nswag/clients/loanApplicationsClient";

const qualityOrder: { [id: string]: number } = {
  [OfferQuality.None]: 0,
  [OfferQuality.LowestEffectiveInterestRate]: 1,
  [OfferQuality.LowestCostPerPeriod]: 2,
  [OfferQuality.LowestCostAndLowestEffectiveRate]: 3,
};

export const compareOffersByQuality = (
  a?: IApplicationOfferClientView,
  b?: IApplicationOfferClientView
) => {
  const qa = a?.quality ?? OfferQuality.None;
  const qb = b?.quality ?? OfferQuality.None;

  return qualityOrder[qa] - qualityOrder[qb];
};

export const comparePartnersByOfferQuality = (
  a: IPartnerSubmissionClientView,
  b: IPartnerSubmissionClientView
) => {
  const qa = Math.max(
    qualityOrder[a.primaryOffer?.quality ?? OfferQuality.None],
    qualityOrder[a.dualOffer?.quality ?? OfferQuality.None]
  );

  const qb = Math.max(
    qualityOrder[b.primaryOffer?.quality ?? OfferQuality.None],
    qualityOrder[b.dualOffer?.quality ?? OfferQuality.None]
  );

  return qb - qa;
};
