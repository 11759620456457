import { ILoanApplicationClientView } from "../../../services/nswag/clients/loanApplicationsClient";

export const initialSubmittedApplicationsState = {
  fetchState: undefined,
  currentlyViewedApplication: undefined,
  consumerLoan: undefined,
  openRestartExists: false,
  activeTabState: 0,
} as ISubmittedApplicationsState;

export interface ISubmittedApplicationsState {
  fetchState: undefined | "loading" | "fulfilled" | "error";
  currentlyViewedApplication?: string;
  consumerLoan?: IApplicationDetails;
  openRestartExists: boolean;
  activeTabState: 0 | 1;
}

export interface IApplicationDetails extends ILoanApplicationClientView {}
